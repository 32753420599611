import React from "react";
import { Link } from "react-router-dom";
import logo from "assets/images/f-logo.svg";
import {
  FooterTag,
  FooterContainer,
  FooterLogo,
  FooterTermsList,
  CopyRightText,
  FooterNavLink,
} from "common/components/Footer/styles";
import {
  HOME_PAGE_ROUTE_URL,
  HOW_IT_WORKS_ROUTE_URL,
  PRIVACY_POLICY_ROUTE_URL,
  PRIVACY_POLICY_CALIFORNIA_ROUTE_URL,
  TERMS_OF_USE_ROUTE_URL,
  FAQ_ROUTE_URL,
} from "common/constants/urls";

function Footer() {
  return (
    <FooterTag>
      <FooterContainer>
        <div className="footer-top">
          <FooterLogo>
            <Link to={HOME_PAGE_ROUTE_URL}>
              <img src={logo} alt="logo" />
            </Link>
          </FooterLogo>
          <FooterTermsList>
          <li>
              <a style ={{textDecoration: 'none', color: '#bdccc1', fontSize: '0.9914em'}} href="https://agents.unlistedinc.com" target="_blank" rel="noreferrer">Agents</a>
            </li>
            <li>
              <FooterNavLink to={HOW_IT_WORKS_ROUTE_URL}>
                How It Works
              </FooterNavLink>
            </li>
            <li>
              <FooterNavLink to={TERMS_OF_USE_ROUTE_URL}>
                Terms &amp; Conditions
              </FooterNavLink>
            </li>
            <li>
              <FooterNavLink to={PRIVACY_POLICY_ROUTE_URL}>
                Privacy Policy
              </FooterNavLink>
            </li>
            <li>
              <FooterNavLink to={PRIVACY_POLICY_CALIFORNIA_ROUTE_URL}>
                California Privacy Notice
              </FooterNavLink>
            </li>
            <li>
              <FooterNavLink to={FAQ_ROUTE_URL}>FAQs</FooterNavLink>
            </li>
          </FooterTermsList>
          <CopyRightText>
            &copy; Copyright {new Date().getFullYear()} patent pending
          </CopyRightText>
        </div>
        <div className="footer-bottom">
          <p>
            Are you in the real estate business?
            <a
              target="_blank"
              href="https://forms.gle/H3F36BPbmwvmnWqM7"
              rel="noreferrer"
            >
              Get in touch
            </a>{" "}
            to learn how UNLISTED can help your clients find homes they love.
          </p>
          <p>
            Hey! You are some of our earliest users. We’d love to know more
            about your experience – feel free to email us at
            <a href="mailto:hello@unlistedinc.com" style={{ color: "#bdccc1" }}>
              hello@unlistedinc.com.
            </a>
          </p>
          <p>
            THE INFORMATION DISPLAYED ON THIS WEBSITE IS NOT AN OFFER TO BUY OR
            SELL REAL ESTATE. An agreement for the purchase or sale of real
            estate should only be made in a written document to be signed by all
            parties to be bound thereto. We do business in accordance with the federal 
            <a
              target="_blank"
              href="https://www.hud.gov/sites/documents/DOC_7802.PDF"
              rel="noreferrer"
            >
              Fair Housing Law
            </a>.
          </p>
        </div>
      </FooterContainer>
    </FooterTag>
  );
}

export default Footer;
