import {
  LOGIN_USER,
  LOGOUT_USER,
  SET_IS_HOMEOWNER,
  SET_LOGGED_IN_USER_INFO_SUCCESS,
  SET_LOGGED_IN_USER_INFO_FAILURE,
  SET_USER_PROFILE_PIC_SUCCESS,
  SET_USER_PROFILE_PIC_FAILURE,
} from "user/actions/userActions";

const userReducer = (state, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loggedIn: true };
    case LOGOUT_USER:
      return {
        ...state,
        loggedInUserInfo: [],
        loggedInUserInfoLoaded: false,
        loggedIn: false,
        isHomeOwner: false,
      };
    case SET_IS_HOMEOWNER:
      return { ...state, isHomeOwner: true };
    case SET_LOGGED_IN_USER_INFO_SUCCESS:
      return {
        ...state,
        loggedInUserInfo: action.payload,
        loggedInUserInfoLoaded: true,
      };
    case SET_LOGGED_IN_USER_INFO_FAILURE:
      return { ...state, loggedInUserInfo: [], loggedInUserInfoLoaded: false };
    case SET_USER_PROFILE_PIC_SUCCESS:
      return {
        ...state,
        loggedInUserInfo: {
          ...state.loggedInUserInfo,
          profile: { ...state.loggedInUserInfo.profile, photo: action.payload },
        },
      };
    case SET_USER_PROFILE_PIC_FAILURE:
      return state;
    default:
      return state;
  }
};

export default userReducer;
