import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";

import { UserContext } from "user/context/UserContext";

import { LOGIN_ROUTE_URL } from "common/constants/urls";

function ProtectedRoute({ children }) {
  const { userValue } = useContext(UserContext);

  if (!userValue.loggedIn) {
    return <Navigate to={LOGIN_ROUTE_URL} state={{prevPage: window.location.href}} />;
  }
  return children;
}

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
