import styled from "styled-components";

export const SearchInputContainer = styled.div`
  max-width: 550px;
  margin: 0 0 10px;
  position: relative;

  &:before {
    position: absolute;
    font-family: "fontello";
    top: 18px;
    left: 10px;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 14px 13px 14px 35px;
  border: 2px solid #fff;
  font-size: 14px;
  line-height: 22px;
  outline: none;
`;

export const PoweredText = styled.span`
  display: block;
  text-align: right;
  color: #000;
  opacity: 0.44;
  font-size: 10px;
  line-height: 16px;
  margin: 0 0 80px;
  @media (min-width: 768px) {
    margin: 0 26px 32px 0;
  }
`;

export const SuggestionItemContainer = styled.div`
  position: absolute;
  top: 54px;
  left: 0;
  right: 50px;
  background: #fff;
  opacity: 1;
  z-index: 11;
  max-width: 550px;
  width: 100%;
`;

export const SuggestionItemHolder = styled.div`
  position: relative;
`;

export const SuggestionItem = styled.div`
  border-bottom: 1px solid;
  padding: 5px;
  opacity: 0.5;
  max-width: 550px;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;
