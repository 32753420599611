import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AddressContext } from "address/context/AddressContext";

import {
  Header2,
  Header3,
  Header4,
} from "common/globalStyles";

import { UserContext } from "user/context/UserContext";

import axios from "axios";

import { SubText } from "common/globalStyles";

import { TwoCols } from "address/pages/styles";

import './property.css'

import { Map, ZoomControl, Overlay } from "pigeon-maps"
import { osm } from "pigeon-maps/providers"

import { getRequest, postRequest } from "common/utils/axiosClient";

import { GET_PROPERTY_API_URL, GET_PROPERTY_LIKED_API_URL, GET_PROPERTY_LIKES_API_URL, UNLIKE_PROPERTY_API_URL, LIKE_PROPERTY_API_URL } from "address/constants/urls";

import { REGISTER_USER_ROUTE_URL } from "common/constants/urls";

import { toast } from "react-toastify";

import Heart from "react-heart";

import NumberMarker from "./NumberMarker.tsx"


export default function SubProperty({unlistedScores, propertyId, results, active, setActive, setActiveLarge}) {
  const [property, setProperty] = useState({})
  const [realtorLink, setRealtorLink] = useState(null)
  const [zillowLink, setZillowLink] = useState(null)
  const [greatschoolsLink, setGreatschoolsLink] = useState(null)
  const [sliceOne, setSliceOne] = useState(1)
  const [likes, setLikes] = useState(0)
  const [liked, setLiked] = useState(false)
  const navigate = useNavigate()
  const [specialFeatures, setSpecialFeatures] = useState("")
  const { populateExistingPropertyData } = useContext(AddressContext)

  const [showStreetView, setShowStreetView] = useState(true)
  const [streetViewError, setStreetViewError] = useState(false)
  const [isForSale, setIsForSale] = useState(false)
  const [backupLat, setBackupLat] = useState(null)
  const [backupLng, setBackupLng] = useState(null)

  const PROPERTY_FEATURES = "balcony breezeway boat_access cabin elevator game_room garage guest_house handicap_accessible office pond sauna tennis_court wine_cellar pool"

  const { userValue } = useContext(UserContext);
  const userInfo = userValue.loggedInUserInfo;

  const getPropertyDetail = async (propertyId) => {
    const response = await(getRequest(GET_PROPERTY_API_URL(propertyId)))
    if(response.status === 200) {
      setProperty(response.data)
      populateExistingPropertyData(response.data)
      setActive(propertyId)
    } else {
      toast.error("We couldn't load that property.")
    }
  }

  const getPropertyLikeInformation = async (propertyId) => {
    if(userInfo.id) {
      const response = await getRequest(GET_PROPERTY_LIKED_API_URL(propertyId))
      if(response.status === 200) {
        setLiked(response.data.liked)
      }

      const likedResponse = await getRequest(GET_PROPERTY_LIKES_API_URL(propertyId))
      if(likedResponse.status === 200) {
        setLikes(likedResponse.data.count)
      }
    }
  }

  const forwardProperty = async () => {
    if(sliceOne >= results.length - 1) {
      getPropertyDetail(results[0].id, false)
      setSliceOne(0)
      return
    }

    getPropertyDetail(results[sliceOne+1].id, false)
    setSliceOne(prevSliceOne => prevSliceOne + 1)
  }

  const reverseProperty = async () => {
    if(sliceOne === 0) {
      getPropertyDetail(results[results.length-1].id)
      setSliceOne(results.length-1)
      return
    }

    getPropertyDetail(results[sliceOne-1].id, false)
    setSliceOne(prevSliceOne => prevSliceOne - 1)
  }

  const likeProperty = async (propertyId) => {
    if(!userInfo || !userInfo.id) {
      toast.error("You need to be logged in to like a property. Why not log in or create an account?")
      return
    }
    const response = await postRequest(LIKE_PROPERTY_API_URL(propertyId))
    if(response.status === 200) {
      setLiked(true)
    } else {
      toast.error("We couldn't like that property.")
    }
  }

  const unlikeProperty = async (propertyId) => {
    if(!userInfo || !userInfo.id) {
      toast.error("You need to be logged in to unlike a property. Why not log in or create an account?")
      return
    }
    const response = await postRequest(UNLIKE_PROPERTY_API_URL(propertyId))
    if(response.status === 200) {
      setLiked(false)
    } else {
      toast.error("We couldn't remove that like.")
    }
  }
  
  const getZillowLink = async () => {
    const addressLineOne = property.address_line_1.split(" ")
    const addressZip = property.zip
    var zillowBuiltLink = ''
    if(property.unit_value !== '' && property.unit_value !== null) {
      zillowBuiltLink = `https://zillowstatic.com/autocomplete/v3/suggestions?q=${addressLineOne[0]}%20${addressLineOne[1]}%20${addressLineOne[2]}%20UNIT%20${property.unit_value}%20${addressZip}`
    } else {
     zillowBuiltLink = `https://zillowstatic.com/autocomplete/v3/suggestions?q=${addressLineOne[0]}%20${addressLineOne[1]}%20${addressLineOne[2]}%20${addressZip}`
    }
    const response = await axios.get(zillowBuiltLink)
    if(response.status === 200 && response.data.results[0]) {
      let zillowId = response.data.results[0].metaData.zpid
      setBackupLat(response.data.results[0].metaData.lat)
      setBackupLng(response.data.results[0].metaData.lng)
      setIsForSale(response.data.results[0].metaData.addressType === 'forsale_address')
      setZillowLink(`https://zillow.com/homes/${zillowId}_zpid`)
    }
  }

  const getRealtorLink = async () => {
    const addressLineOne = property.address_line_1.split(" ")
    var realtorBuiltLink = ''
    if(property.unit_value !== '' && property.unit_value !== null) {
      realtorBuiltLink = `https://parser-external.geo.moveaws.com/suggest?input=${addressLineOne[0]}%20${addressLineOne[1]}%20${addressLineOne[2]}%20%UNIT%20${property.unit_value}%20${property.zip}&client_id=rdc-home&limit=1`
    } else {
      realtorBuiltLink = `https://parser-external.geo.moveaws.com/suggest?input=${addressLineOne[0]}%20${addressLineOne[1]}%20${addressLineOne[2]}%20${property.zip}&client_id=rdc-home&limit=1`
    }
    const response = await axios.get(realtorBuiltLink)
    if(response.status === 200 && response.data.autocomplete[0]) {
      let realtorId = response.data.autocomplete[0].mpr_id
      setRealtorLink(`https://realtor.com/realestateandhomes-detail/M${realtorId}`)
    }
  }

  const getGreatschoolsLink = async () => {
    setGreatschoolsLink(`https://www.greatschools.org/search/search.page?lat=${property.latitude}&locationType=coordinate&lon=${property.longitude}&st=public_charter&st=public&st=charter&state=${property.state}`)
  }

  const [width, setWidth] = useState(window.innerWidth)

  const google = window.google;
  const geocodeAddress = async () => {

      const fullAddress = `${property.address_line_1} ${property.city} ${property.state} ${property.zip}`;
      const attom_location = { lat: parseFloat(property.latitude), lng: parseFloat(property.longitude) };
      const geocoder = new google.maps.Geocoder();
      const directionsService = new google.maps.DirectionsService();

      //use geocoder to get propertyLatLng, which will hopefully be the center of the structure
      geocoder.geocode({
          'address': fullAddress,
      }, async function(results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
              let propertyLatLng = results[0].geometry.location;

              // find a Streetview location on the road to be used a our myLatLng (panorama POV)
              //we need to use this directionsService so we don't end up with a view from an alley or side street
              var request = {
                  origin: fullAddress,
                  destination: fullAddress,
                  travelMode: google.maps.DirectionsTravelMode.DRIVING
              };
              directionsService.route(request, async function (response, status) {
                  if (status === google.maps.DirectionsStatus.OK) {
                      var myLatLng = response.routes[0].legs[0].start_location;
                      await generatePanorama(myLatLng, propertyLatLng, google)
                  } else {
                      console.error("Directions service not successful for the following reason:" + status);
                      await generatePanorama(null, propertyLatLng, google)
                  }
              });
          } else {
              console.error("Geocode was not successful for the following reason:", status);
              if (backupLat && backupLng) {
                  const backup_location = { lat: backupLat, lng: backupLng };
                  await generatePanorama(backup_location, backup_location);
              } else {
                  await generatePanorama(attom_location, attom_location);
              }
          }
      });
  };

  const generatePanorama = async (myLatLng, propertyLatLng) => {
      const streetViewService = new google.maps.StreetViewService();
      const panorama = new google.maps.StreetViewPanorama(document.getElementById("streetview"));

      streetViewService.getPanorama({
          location: myLatLng || propertyLatLng,
          radius: 50,
          source: google.maps.StreetViewSource.OUTDOOR
      }, async function(data, status) {
          if (status === google.maps.StreetViewStatus.OK) {
              panorama.setPano(data.location.pano);
              var heading = google.maps.geometry.spherical.computeHeading(myLatLng ||  data.location.latLng, propertyLatLng);
              panorama.setPov({
                  heading: heading,
                  pitch: 0,
                  zoom: 1
              });
              panorama.setVisible(true);
          } else {
              console.error("Street View was not successful for the following reason:", status);
              // This is where we should fall back to aerial view
              displayAerialView(propertyLatLng);
          }
      });
  };

  const displayAerialView = (location) => {
      const mapOptions = {
          center: location,
          zoom: 20,
          mapTypeId: google.maps.MapTypeId.SATELLITE
      };
      const map = new google.maps.Map(document.getElementById("streetview"), mapOptions);
  };

  window.addEventListener('resize', function(event){
      setWidth(window.innerWidth)
  })

  useEffect(() => {
    getPropertyDetail(propertyId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyId])

  useEffect(() => {
    if(property.address_line_1) {
    getZillowLink()
    getRealtorLink()
    getGreatschoolsLink()
    setStreetViewError(false)
    geocodeAddress()
    getPropertyLikeInformation(property.id)

    let features = ""

    Object.entries(property).map(attrib => {
      if(PROPERTY_FEATURES.includes(attrib[0]) && attrib[1] === true) {
        features = features + attrib[0][0].toUpperCase() + attrib[0].slice(1) + ", "
      }
    })

    features = features.slice(0, -2)

    setSpecialFeatures(features)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property])
  
  const handleContinueBtnClick = () => {
    navigate(REGISTER_USER_ROUTE_URL, {state: {isFromSearch: true, propertyId: property.id, propertyAddress: property.address_line_1}});
  };

  return (
            <>
            { property !== null ? <> 
            <TwoCols>
            <div className="propertymapbox">
            <Header4>
                
            </Header4>
              {property && property.latitude && property.longitude && <Map provider={osm} height={width > 768 ? 750 : 300} width={width > 1200 ? 580 : width / 4.2 + 200} center={[parseFloat(property.latitude), parseFloat(property.longitude)]} defaultCenter={[parseFloat(property.latitude), parseFloat(property.longitude)]} defaultZoom={14}>
              {
                results.map(result => (
                  <NumberMarker score={unlistedScores[result.id]} scoreColor='#bdccc1' onMouseOver={() => setActive(result.id)} onMouseOut={() => setActive(-1)} key={result.id} width={60} anchor={[result.latitude, result.longitude]} color='#bdccc1'
                  onClick={() => setActiveLarge(result.id)}/>
                ))
              }
              {
                results.map(result => (
                  <Overlay style={result.id === active ? {display: 'block', backgroundColor: 'white', borderRadius: '5px', padding: '5px'} : {display: 'none'}} key={result.id} width={100} anchor={[result.latitude, result.longitude]}>
                    <Header3 style={{color: 'black', fontSize: '0.9em', fontWeight: 'bold'}}>{unlistedScores[result.id]}% RECOMMENDED</Header3>
                    <Header3 style={{color: 'black', fontSize: '1.2em', marginTop: '-20px'}}>{result.address_line_1}</Header3> 
                    {
                      
                      result.estimated_value_one ? <Header3 style={{fontSize: '0.9em', color: 'black', marginTop: '-20px', marginBottom: '-5px'}}>{result.bedrooms} bed  {result.bathrooms} bath  ${result.estimated_value_one.toLocaleString()}</Header3>
                      : <Header3 style={{fontSize: '0.9em', color: 'black', marginTop: '-20px', marginBottom: '-5px'}}>{result.bedrooms} bed  {result.bathrooms} bath </Header3>
                    }                
                    </Overlay>
                ))
              }
            <ZoomControl buttonStyle={{height: 50, width: 80, borderRadius: 3}}/>
            </Map>}


            <br/>
           
            </div>
            <div className="moreproperties">
              <Header3>{unlistedScores[property.id]}% Recommended</Header3>
              <Header2 style={{display: 'inline-block', marginTop: '-15px', fontSize: '1.7em'}}>{property.address_line_1}</Header2>
              {isForSale ? <p style={{display: 'inline-block', color: 'green'}}>&nbsp; For Sale</p> : <></>}
              <p className="noselect" style={{float: 'right', marginRight: '15%', display: 'inline-block', color: 'black', cursor: 'pointer'}} onClick={forwardProperty}>Next</p>
              <p className="noselect" style={{float: 'right', marginRight: '1%', display: 'inline-block', color: 'black', cursor: 'pointer'}} onClick={reverseProperty}>Last</p>
              <Header2 style={{fontSize: '1em', marginTop: '-1.5em'}}>{property.city}, {property.state} {property.zip}</Header2>

              <button style={width > 400 ? {width: '18em', height: '2.8em', cursor: 'pointer', marginTop: '-3em', marginRight: '15px'} : {width: '15em', height: '2.8em', cursor: 'pointer', marginTop: '-3em', marginRight: '5px'}} onClick={handleContinueBtnClick}>Contact the Homeowner</button>

              <div style={width > 400 ? {marginLeft: '19em', marginTop: '-40px'} : {marginLeft: '16em', marginTop: '-40px'}}>
              {!liked? <Heart style={{width: '2rem', cursor: 'pointer'}} isActive={false} onClick={() => likeProperty(property.id)}/> : <Heart style={{width: '2rem', cursor: 'pointer'}} isActive={true} onClick={() => unlikeProperty(property.id)}/>}
              </div>
              {!showStreetView && <img style={{marginTop: '15px', maxWidth: '90%', maxHeight: '22em'}} src={property.cover_photo_url} alt="the front of the house you searched for"/>}
              
              {!!!streetViewError && <div id="streetview" className="streetview" style={showStreetView ? {marginTop: '15px', marginBottom: '2px', maxWidth: '90%', height: '22em'} : {visibility: 'hidden', marginTop: '15px', marginBottom: '2px', maxWidth: '90%', height: '0em'}}></div>}
              
              {streetViewError && <div style={{backgroundColor: '#F5F5F5', marginTop: '15px', marginBottom: '2px', maxWidth: '90%', height: '22em'}}>
                <h1 style={{display: 'inline-block', textAlign: 'center', padding: '10px', marginTop: '10%'}}>Google Street View isn't available for this home.</h1>
                </div>}

              {zillowLink && <SubText style={{display: 'inline-block', marginRight: '1em', cursor: 'pointer', fontSize: '90%'}}><a key={property.id} href={zillowLink} target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: 'black', cursor: 'pointer'}}>View on Zillow</a></SubText>}
              {realtorLink && <SubText style={{display: 'inline-block', cursor: 'pointer', marginRight: '1em', fontSize: '90%'}}><a key={property.id} href={realtorLink} target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: 'black'}}>View on Realtor.com</a></SubText>}
              {greatschoolsLink && <SubText style={{display: 'inline-block', cursor: 'pointer', fontSize: '90%'}}><a key={propertyId} href={greatschoolsLink} target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: 'black'}}>View on GreatSchools.org</a></SubText>}
              {property.cover_photo_url ? showStreetView ? <SubText onClick={() => setShowStreetView(false)} style={{marginLeft: '1em', display: 'inline-block', cursor: 'pointer', fontSize: '90%', color: 'black'}}>User Photo</SubText> : <SubText onClick={() => setShowStreetView(true)} style={{marginLeft: '1em', color: '#bdccc1', display: 'inline-block', cursor: 'pointer', fontSize: '90%'}}>Street View</SubText> : <></>}

              <div className="subgrid">
                  {property.bedrooms && <div>
                    <Header2 style={{fontSize: '1.3em'}}>{property.bedrooms}</Header2>
                    <p style={{marginTop: '-25px'}}>Bedrooms</p>
                  </div>}
                  {property.bathrooms && <div>
                  <Header2 style={{fontSize: '1.3em'}}>{property.bathrooms}</Header2>
                  <p style={{marginTop: '-25px'}}>Bathrooms</p>
                  </div>}
                  {property.build_year && <div>
                  <Header2 style={{fontSize: '1.3em'}}>{property.build_year}</Header2>
                  <p style={{marginTop: '-25px'}}>Built in</p>
                  </div>}
                  {property.last_sale_date && <div>
                  <Header2 style={{fontSize: '1.3em'}}>{property.last_sale_date.toString().substring(0, 4)}</Header2>
                  <p style={{marginTop: '-25px'}}>Last sold in</p>
                  </div>}
                  {property.estimated_value_one ? <div>
                  <Header2 style={{fontSize: '1.3em'}}>${property.estimated_value_one.toLocaleString()}</Header2>
                  <p style={{marginTop: '-25px'}}>Unlisted estimate</p>
                  </div> : <></>}
                  {property.last_sale_amount ? <div>
                  <Header2 style={{fontSize: '1.3em'}}>${property.last_sale_amount.toLocaleString()}</Header2>
                  <p style={{marginTop: '-25px'}}>Last sold for</p>
                  </div> : <></>}
                  {property.building_area ? <div>
                  <Header2 style={{fontSize: '1.3em'}}>{property.building_area.toLocaleString()}</Header2>
                  <p style={{marginTop: '-25px'}}>Square Feet</p>
                  </div> : <></>}
              </div>      

              </div>
              </TwoCols>
            </> : <></>}
              </>
  );
}
