import {
  SET_ADDRESS_DATA_SUCCESS,
  SET_ADDRESS_DATA_FAILURE,
  SET_PROPERTY_DATA_SUCCESS,
  SET_PROPERTY_DATA_FAILURE,
  REQUIRE_UNIT,
  NOT_REQUIRE_UNIT,
  SET_HAS_ERROR,
} from "address/actions/addressActions";

const addressReducer = (state, action) => {
  switch (action.type) {
    case SET_ADDRESS_DATA_SUCCESS:
      return { ...state, addressData: action.payload, addressDataLoaded: true };
    case SET_ADDRESS_DATA_FAILURE:
      return { ...state, addressData: {}, addressDataLoaded: false };
    case SET_PROPERTY_DATA_SUCCESS:
      return {
        ...state,
        propertyData: action.payload,
        propertyDataLoaded: true,
      };
    case SET_PROPERTY_DATA_FAILURE:
      return { ...state, propertyData: {}, propertyDataLoaded: false };
    case REQUIRE_UNIT:
      return { ...state, isUnitRequired: true };
    case NOT_REQUIRE_UNIT:
      return { ...state, isUnitRequired: false };
    case SET_HAS_ERROR:
      return { ...state, hasError: action.payload };
    default:
      return state;
  }
};

export default addressReducer;
