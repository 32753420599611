// ROUTE URLS

// HOME PAGE and MAP PAGE
export const HOME_PAGE_ROUTE_URL = "/";
export const SEARCH_ROUTE_URL = "/search";
export const LOVE_PROPERTY_URL = "/properties/love";
export const REGISTER_HOME_ROUTE_URL = "/register-home";
export const PIN_ADDRESS_MAP_ROUTE_URL = "/verify-address";
export const PIN_ADDRESS_SEARCH_RESULT_URL = '/property-result/:propertyIdFromParams'

// PROPERTY QUIZ PAGE
export const PROPERTY_QUIZ_URL = "/property-quiz/";
export const PROPERTY_QUIZ_ROUTE_URL = `${PROPERTY_QUIZ_URL}:addressId`;
export const PROPERTY_QUIZ_LINK_ROUTE_URL = (addressId) =>
  `${PROPERTY_QUIZ_URL}${addressId}`;

// HOMEOWNER PROPERTY PAGE
export const HOMEOWNER_PROPERTY_ROUTE_URL = "/properties";

export const SHARE_CAMPAIGN_URL = "/shared/:sharecode";

// USER REGISTRATION FORM PAGE
export const REGISTER_USER_ROUTE_URL = "/register";
export const RETURN_FROM_EMAIL_ROUTE_URL = "/conversations/resume/:propertyId";
export const USER_VERIFICATION_ROUTE_URL = "/users/verify/";

// PROPOSAL PAGES
export const PROPOSAL_LIST_ROUTE_URL = "/conversations";
export const PROPOSAL_CREATE_ROUTE = "/conversations/create/";
export const PROPOSAL_SENT_ROUTE = "/conversations/sent/";
export const PROPOSAL_RECEIVED_ROUTE = "/conversations/received/";

export const CREATE_PROPOSAL_ROUTE_URL = `${PROPOSAL_CREATE_ROUTE}:addressId`;
export const CREATE_PROPOSAL_LINK_ROUTE_URL = (proposalId) =>
  `${PROPOSAL_CREATE_ROUTE}${proposalId}`;
export const SENT_PROPOSAL_LINK_ROUTE_URL = (proposalId) =>
  `${PROPOSAL_SENT_ROUTE}${proposalId}`;
export const SENT_PROPOSAL_DETAIL_ROUTE_URL = `${PROPOSAL_SENT_ROUTE}:proposalId`;
export const RECEIVED_PROPOSAL_LINK_ROUTE_URL = (proposalId) =>
  `${PROPOSAL_RECEIVED_ROUTE}${proposalId}`;
export const RECEIVED_PROPOSAL_DETAIL_ROUTE_URL = `${PROPOSAL_RECEIVED_ROUTE}:proposalId`;
export const PROPOSAL_SUCCESS_ROUTE_URL = "/conversation-success";

export const MY_LIST_URL = "/list"

// LOGIN PAGE
export const LOGIN_ROUTE_URL = "/login";

// REFER A FRIEND PAGE
export const REFER_FRIEND_ROUTE_URL = "/refer-a-friend";

// USER PROFILE PAGES
export const VIEW_PROFILE_ROUTE_URL = "/view-profile";
export const EDIT_PROFILE_ROUTE_URL = "/edit-profile";
export const INVITE_HOME_OWNER_ROUTE_URL = "/invitations";

// HOW IT WORKS PAGE
export const HOW_IT_WORKS_ROUTE_URL = "/how-it-works";

// PRIVACY POLICY PAGE
export const PRIVACY_POLICY_ROUTE_URL = "/privacy-policy";

// PRIVACY POLICY PAGE
export const PRIVACY_POLICY_CALIFORNIA_ROUTE_URL = "/privacy-policy-california";

// TERMS OF USE PAGE
export const TERMS_OF_USE_ROUTE_URL = "/terms-of-use";

// FAQ PAGE
export const FAQ_ROUTE_URL = "/faq";

// ERROR PAGES
export const NOT_FOUND_ROUTE_URL = "/404";
export const ERROR_ROUTE_URL = "/error";
export const CLAIMED_ERROR_ROUTE_URL = "/claimederror"
