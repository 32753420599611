import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import {
  USER_VERIFICATION_ROUTE_URL,
  PROPOSAL_CREATE_ROUTE,
  PROPOSAL_SENT_ROUTE,
  PROPOSAL_RECEIVED_ROUTE,
  PROPERTY_QUIZ_URL,
} from "common/constants/urls";

const formatPageTitle = (pageTitle) => {
  const words = pageTitle.slice(1).split("-");
  let formattedPagetitle = "";
  for (let i = 0; i < words.length; i += 1) {
    formattedPagetitle = `${formattedPagetitle} ${words[
      i
    ][0].toUpperCase()}${words[i].substr(1)}`;
  }
  return formattedPagetitle;
};

const buildPageTitleName = (pathName) => {
  if (pathName === "/") {
    return "Unlisted";
  }
  if (pathName.includes(USER_VERIFICATION_ROUTE_URL)) {
    return "Verify User";
  }
  if (pathName.includes(PROPOSAL_CREATE_ROUTE)) {
    return "Create Conversation";
  }
  if (pathName.includes(PROPOSAL_SENT_ROUTE)) {
    return "Sent Conversation";
  }
  if (pathName.includes(PROPOSAL_RECEIVED_ROUTE)) {
    return "Received Conversation";
  }
  if (pathName.includes(PROPERTY_QUIZ_URL)) {
    return "Property Quiz";
  }
  return formatPageTitle(pathName);
};

export default function RouteConfig() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = buildPageTitleName(pathname);
  }, [pathname]);

  return null;
}
