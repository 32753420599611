export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";

export const SET_IS_HOMEOWNER = "SET_IS_HOMEOWNER";

export const SET_LOGGED_IN_USER_INFO_SUCCESS =
  "SET_LOGGED_IN_USER_INFO_SUCCESS";
export const SET_LOGGED_IN_USER_INFO_FAILURE =
  "SET_LOGGED_IN_USER_INFO_FAILURE";

export const SET_USER_PROFILE_PIC_SUCCESS = "SET_USER_PROFILE_PIC_SUCCESS";
export const SET_USER_PROFILE_PIC_FAILURE = "SET_USER_PROFILE_PIC_FAILURE";
