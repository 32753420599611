/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const DefaultInput = styled.input`
  border: 2px solid rgba(0, 0, 0, 0.5);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.96),
      rgba(255, 255, 255, 0.96)
    ),
    #000000;
  width: 100%;
  padding: 15px 10px 14px;
  margin-bottom: 25px;
  outline: none;
  border-radius: 0;
`;

const NumberFieldContainer = styled.div`
  display: flex;
  margin: 0 auto;
  border: 2px solid rgba(0, 0, 0, 0.5);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.96),
      rgba(255, 255, 255, 0.96)
    ),
    #000000;

  &:hover span {
    opacity: 1;
  }
`;

const NumberFieldWrapper = styled(DefaultInput)`
  margin: 0;
  border: 0;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

const SpinnerArrowsContainer = styled.span`
  display: flex;
  flex-wrap: wrap;
  opacity: 0;
`;

const SpinnerArrow = styled.button`
  border: 0;
  background: transparent;
  cursor: pointer;
  max-width: 30px;
  padding: 0;
`;

const decrementFieldValue = (eventStep, value, setValue) => {
  if (value && value - eventStep > 0) {
    setValue(value - eventStep);
  } else {
    setValue(0);
  }
};

const incrementFieldValue = (eventStep, value, setValue) => {
  if (value !== null && value >= 0) {
    setValue(value + eventStep);
  } else {
    setValue(0);
  }
};

const NumberField = React.forwardRef((props, ref) => {
  return (
    <NumberFieldContainer className={props.className}>
      <NumberFieldWrapper
        ref={ref}
        {...props}
        type="number"
        min="0"
        onKeyDown={(e) => {
          if (e.key === "ArrowDown") {
            decrementFieldValue(
              props.eventStep,
              props.currentValue,
              props.setValue
            );
            e.preventDefault();
          } else if (e.key === "ArrowUp") {
            incrementFieldValue(
              props.eventStep,
              props.currentValue,
              props.setValue
            );
            e.preventDefault();
          }
        }}
      />
      <SpinnerArrowsContainer>
        <SpinnerArrow
          type="button"
          onClick={() =>
            incrementFieldValue(
              props.eventStep,
              props.currentValue,
              props.setValue
            )
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10px"
            viewBox="0 0 320 185"
          >
            <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224z" />
          </svg>
        </SpinnerArrow>
        <SpinnerArrow
          type="button"
          onClick={() =>
            decrementFieldValue(
              props.eventStep,
              props.currentValue,
              props.setValue
            )
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10px"
            viewBox="0 0 320 400"
          >
            <path d="M329.155,100.036c-2.108-6.011-7.784-10.035-14.154-10.035h-300c-6.371,0-12.046,4.024-14.154,10.035  c-2.109,6.011-0.19,12.699,4.784,16.678l150.004,120c2.739,2.191,6.055,3.287,9.37,3.287c3.316,0,6.631-1.096,9.371-3.287  l149.996-120C329.346,112.734,331.264,106.047,329.155,100.036z" />
          </svg>
        </SpinnerArrow>
      </SpinnerArrowsContainer>
    </NumberFieldContainer>
  );
});

NumberField.propTypes = {
  className: PropTypes.string,
  eventStep: PropTypes.number.isRequired,
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setValue: PropTypes.func.isRequired,
};

NumberField.defaultProps = {
  className: "",
  currentValue: null,
};

export { NumberField };

export default DefaultInput;
