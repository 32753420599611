import { UPDATE_PROPERTY_QUIZ_API_URL } from "address/constants/urls";
import { UPDATE_USER_PROFILE_API_URL } from "user/constants/urls";
import CryptoJS from "crypto-js";
import {
  patchRequest,
  postRequest,
} from "common/utils/axiosClient";

const PRESIGNED_URL_ENDPOINT = '/photo_presigned_url'

const getmd5 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (fileEvent) => {
        let binary = CryptoJS.lib.WordArray.create(fileEvent.target.result)
        const md5 = CryptoJS.MD5(binary)
        resolve(md5)
      }
      reader.onerror = () => {
        reject('file reader error')
      }
    reader.readAsArrayBuffer(file)
    })
  }

const getFileChecksum = async(file) => {
  const md5 = await getmd5(file)
  const checksum = md5.toString(CryptoJS.enc.Base64)
  return checksum
}

const createPresignedUrl = async(fileName, fileType, byteSize, checksum, type, propertyId) => {
  // Note: we don't send all of these options at the moment because the AWS Ruby SDK doesn't take in some of them
  const content_type = fileType
  let options = {
        filename: fileName,
        byte_size: byteSize,
        checksum: checksum,
        content_type: content_type,
        metadata: {
          'type': type
        },
        directory: type,
        propertyId: propertyId
      }
  let response = postRequest(PRESIGNED_URL_ENDPOINT, options)
  if(response.status !== 200) return response
  return response
}

export const uploadPhoto = async(file, type, propertyId = 0) => {
  const checksum = await(getFileChecksum(file));
  if(file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg" && file.type !== "image/gif") {
    return "invalid file type"
  }
  const presignedUrlParams = await createPresignedUrl(file.name, file.type, file.size, checksum, type, propertyId)

  
  const s3options = {
    method: 'PUT',
    body: file
  }


  let s3response = await fetch(presignedUrlParams.data.url, s3options)

  if(s3response.status !== 200) return false


  if(type === 'userprofilephotos') {
    const response = await patchRequest(UPDATE_USER_PROFILE_API_URL, {user: {profile_picture_url: presignedUrlParams.data.get_url}})
    if(response.status !== 200) {
      return false
    } else {
      return response.data
    }

  } else if(type ==='propertycoverphotos') {
    const response = await patchRequest(UPDATE_PROPERTY_QUIZ_API_URL(propertyId), {photo: presignedUrlParams.data.get_url})
    if(response.status !== 200) {
      return false
    } else {
      return presignedUrlParams.data
    }
  }
}