import styled from "styled-components";
import { Container } from "common/globalStyles";
import { Link } from "react-router-dom";

export const FooterTag = styled.div`
  padding: 20px 0 70px;
  border-top: 1px solid #000;
  text-align: center;
  position: relative;
  @media (min-width: 1024px) {
    text-align: left;
    padding: 33px 0;
  }
`;

export const FooterContainer = styled(Container)`
  .footer-top {
    margin-bottom: 10px;
    @media (min-width: 1024px) {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
    }
  }
  .footer-bottom {
    text-align: center;
    @media (min-width: 1024px) {
      font-size: 14px;
      line-height: 20px;
    }

    p {
      margin: 0;
    }

    a {
      text-decoration: none;
      color: #bdccc1;
      display: inline-block;
      margin-left: 4px;
    }
  }
`;

export const FooterLogo = styled.div`
  width: 207px;
  margin: 0 auto 10px;

  @media (min-width: 1024px) {
    flex-shrink: 0;
    margin: 0;
    width: 150px;
  }

  img {
    max-width: 100%;
  }

  a {
    opacity: 0.3;
  }
`;

export const FooterTermsList = styled.ul`
  margin: 0 0 10px;
  padding: 0;
  list-style: none;
  font-size: 18px;
  line-height: 24px;
  flex-grow: 1;
  text-align: center;
  @media (min-width: 1024px) {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 18px;
  }
  li {
    display: block;
    padding: 0 0 10px;
    @media (min-width: 1024px) {
      display: inline-block;
      vertical-align: top;
      padding: 0 7px;
    }
    @media (min-width: 1200px) {
      padding: 0 20px;
    }
  }
`;

export const FooterNavLink = styled(Link)`
  text-decoration: none;
  color: #bdccc1;
  font-size: 0.9914em;
`;

export const CopyRightText = styled.span`
  display: block;
  color: rgba(0, 0, 0, 0.4);
  font-size: 18px;
  line-height: 24px;
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 30px;

  @media (min-width: 1024px) {
    flex-shrink: 0;
    text-align: right;
    position: static;
    font-size: 14px;
    line-height: 18px;
  }
`;
