import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import "index.css";

import GlobalContextProvider from "common/context/GlobalContext";
import AddressContextProvider from "address/context/AddressContext";
import UserContextProvider from "user/context/UserContext";
import AppRouter from "route";
import ToastProvider from "common/components/Toast";

ReactDOM.render(
  <StrictMode>
    <GlobalContextProvider>
      <UserContextProvider>
        <AddressContextProvider>
          <AppRouter />
          <ToastProvider />
        </AddressContextProvider>
      </UserContextProvider>
    </GlobalContextProvider>
  </StrictMode>,
  document.getElementById("root")
);
