import React from "react";

import { Main, Container, Header1 } from "common/globalStyles";
import { ErrorPageBlock } from "common/pages/styles";
import { SecondaryButton } from "common/components/Buttons";
import image4 from "assets/images/img-4.svg";
import { HOME_PAGE_ROUTE_URL } from "common/constants/urls";
import { useNavigate } from "react-router-dom";

function NotFound() {

  const navigate = useNavigate()

  const goHome = () => {
    navigate(HOME_PAGE_ROUTE_URL)
  }

  return (
    <Main>
      <ErrorPageBlock>
        <Container className="error-container">
          <div className="img-holder">
            <img src={image4} alt="img" />
          </div>
          <div className="text-block">
            <Header1>404 Error</Header1>
            <p>Oops. We couldn't find that page. Why not look elsewhere?</p>
            <SecondaryButton onClick={goHome}>Go home</SecondaryButton>
          </div>
        </Container>
      </ErrorPageBlock>
    </Main>
  );
}

export default NotFound;
