import React, { useEffect, useState } from "react";

import {
  Main,
  Container,
  MainBlock,
  MainContent,
  Header1,
  Header2,
  Header3
} from "common/globalStyles";
import { getRequest, postRequest } from "common/utils/axiosClient";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";
import { PrimaryButton } from "common/components/Buttons";
import { GET_PROPERTY_API_URL } from "address/constants/urls";

import { ProposalHolder, ProposalText } from "./Search/SearchItem/style";
// import { Map, Overlay, ZoomControl } from "pigeon-maps"; // if we wish to add maps later
// import { osm } from "pigeon-maps/providers"
// import NumberMarker from "./Search/NumberMarker.tsx";

import axios from "axios";
import { SubText } from "common/globalStyles";
import "./Search/property.css"

function Shared() {

  const [width, setWidth] = useState(window.innerWidth)

  const [loaded, setLoaded] = useState(false)
  
  window.addEventListener('resize', function(event){
    setWidth(window.innerWidth)
  })

  const { sharecode } = useParams()

  const [approved, setApproved] = useState(false)
  const [cart, setCart] = useState(null)
  // const [active, setActive] = useState(-1) if we wish to add maps later
  const [realtorLink, setRealtorLink] = useState(null)
  const [zillowLink, setZillowLink] = useState(null)
  const [greatschoolsLink, setGreatschoolsLink] = useState(null)

  const [propertyInfo, setPropertyInfo] = useState(null)
  const [streetViewError, setStreetViewError] = useState(false)

  const [backupLat, setBackupLat] = useState(null)
  const [backupLng, setBackupLng] = useState(null)


  const getShared = async () => {
    const response = await getRequest(`/campaigns/shared/${sharecode}`)
    setLoaded(true)
    if(response.status === 200) {
        setCart(response.data.cart.sort((a, b) => a[0] > b[0] ? 1 : -1))
    }
  }

  const approve = async () => {
    const response = await postRequest("/campaigns/approve", { sharecode: sharecode })
    if(response.status === 200) {
        setApproved(true)
    }
  }

  const remove = async (id) => {
    const response = await postRequest("/campaigns/alter_shared", { property_id: id, sharecode: sharecode })
    if(response.status === 200) {
        toast.success("You removed this home.")
        getShared()
    }
  }

  // const handleSorting = (event) => { // commented out for if we wish to sort these in the future pending bug fix
  //   const sortType = event.target.value
  //   const reverse = sortType % 2 === 0
  //   const oldCart = [...cart]

  //   if(sortType === 1 || sortType === 2) {
  //     oldCart.sort(function(a, b) {
  //       if(reverse) return b[8] - a[8]
  //       return a[8] - b[8]
  //     })
  //   } else if(sortType === 3 || sortType === 4) {
  //     oldCart.sort(function(a, b) {
  //       if(reverse) return b[4] - a[4]
  //       return a[4] - b[4]
  //     })
  //   } else if(sortType === 5 || sortType === 6) {
  //     oldCart.sort(function(a, b) {
  //       if(reverse) return b[5] - a[5]
  //       return a[5] - b[5]
  //     })
  //   } else if(sortType === 7 || sortType === 8) {
  //     oldCart.sort(function(a, b) {
  //       if(reverse) return b[11] - a[11]
  //       return a[11] - b[11]
  //     })
  //   } else if(sortType === 9 || sortType === 10) {
  //     oldCart.sort(function(a, b) {
  //       if(reverse) return new Date(b[7]) - new Date(a[7])
  //       return new Date(a[7]) - new Date(b[7])
  //     })
  //   } else if(sortType === 11 || sortType === 12) {
  //     oldCart.sort(function(a, b) {
  //       if(reverse) return b[0].localeCompare(a[0], undefined, {
  //         numeric: true, sensitivity: 'base'
  //       })

  //       return a[0].localeCompare(b[0], undefined, {
  //         numeric: true, sensitivity: 'base'
  //       })
  //     })
  //   } else {
  //     return
  //   }

  //   setCart(oldCart)
  // }

  const getZillowLink = async () => {
    const addressLineOne = propertyInfo.address_line_1.split(" ")
    const addressZip = propertyInfo.zip
    const zillowBuiltLink = `https://zillowstatic.com/autocomplete/v3/suggestions?q=${addressLineOne[0]}%20${addressLineOne[1]}%20${addressLineOne[2]}%20${addressZip}`
    const response = await axios.get(zillowBuiltLink)
    if(response.status === 200 && response.data.results[0]) {
      setBackupLat(response.data.results[0].metaData.lat)
      setBackupLng(response.data.results[0].metaData.lng)
      let zillowId = response.data.results[0].metaData.zpid
      setZillowLink(`https://zillow.com/homes/${zillowId}_zpid`)
    }
  }

  const getRealtorLink = async () => {
    const addressLineOne = propertyInfo.address_line_1.split(" ")
    const realtorBuiltLink = `https://parser-external.geo.moveaws.com/suggest?input=${addressLineOne[0]}%20${addressLineOne[1]}%20${addressLineOne[2]}%20${propertyInfo.zip}&client_id=rdc-home&limit=1`
    const response = await axios.get(realtorBuiltLink)
    if(response.status === 200 && response.data.autocomplete[0]) {
      let realtorId = response.data.autocomplete[0].mpr_id
      setRealtorLink(`https://realtor.com/realestateandhomes-detail/M${realtorId}`)
    }
  }

  const getGreatschoolsLink = async () => {
    setGreatschoolsLink(`https://www.greatschools.org/search/search.page?lat=${propertyInfo.latitude}&locationType=coordinate&lon=${propertyInfo.longitude}&st=public_charter&st=public&st=charter&state=${propertyInfo.state}`)
  }


  const google = window.google;

  const geocodeAddress = async () => {

      const fullAddress = `${propertyInfo.address_line_1} ${propertyInfo.city} ${propertyInfo.state} ${propertyInfo.zip}`;
      const attom_location = { lat: parseFloat(propertyInfo.latitude), lng: parseFloat(propertyInfo.longitude) };
      const geocoder = new google.maps.Geocoder();
      const directionsService = new google.maps.DirectionsService();

      //use geocoder to get propertyLatLng, which will hopefully be the center of the structure
      geocoder.geocode({
          'address': fullAddress,
      }, async function(results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
              let propertyLatLng = results[0].geometry.location;

              // find a Streetview location on the road to be used a our myLatLng (panorama POV)
              //we need to use this directionsService so we don't end up with a view from an alley or side street
              var request = {
                  origin: fullAddress,
                  destination: fullAddress,
                  travelMode: google.maps.DirectionsTravelMode.DRIVING
              };
              directionsService.route(request, async function (response, status) {
                  if (status === google.maps.DirectionsStatus.OK) {
                      var myLatLng = response.routes[0].legs[0].start_location;
                      await generatePanorama(myLatLng, propertyLatLng, google)
                  } else {
                      console.error("Directions service not successful for the following reason:" + status);
                      await generatePanorama(null, propertyLatLng, google)
                  }
              });
          } else {
              console.error("Geocode was not successful for the following reason:", status);
              if (backupLat && backupLng) {
                  const backup_location = { lat: backupLat, lng: backupLng };
                  await generatePanorama(backup_location, backup_location);
              } else {
                  await generatePanorama(attom_location, attom_location);
              }
          }
      });
  };

  const generatePanorama = async (myLatLng, propertyLatLng) => {
      const streetViewService = new google.maps.StreetViewService();
      const panorama = new google.maps.StreetViewPanorama(document.getElementById("streetview"));

      streetViewService.getPanorama({
          location: myLatLng || propertyLatLng,
          radius: 50,
          source: google.maps.StreetViewSource.OUTDOOR
      }, async function(data, status) {
          if (status === google.maps.StreetViewStatus.OK) {
              panorama.setPano(data.location.pano);
              var heading = google.maps.geometry.spherical.computeHeading(myLatLng ||  data.location.latLng, propertyLatLng);
              panorama.setPov({
                  heading: heading,
                  pitch: 0,
                  zoom: 1
              });
              panorama.setVisible(true);
          } else {
              console.error("Street View was not successful for the following reason:", status);
              // This is where we should fall back to aerial view
              displayAerialView(propertyLatLng);
          }
      });
  };

  const displayAerialView = (location) => {
      const mapOptions = {
          center: location,
          zoom: 20,
          mapTypeId: google.maps.MapTypeId.SATELLITE
      };
      const map = new google.maps.Map(document.getElementById("streetview"), mapOptions);
  };

  const getPropertyInfo = async (id) => {
    const response = await(getRequest(GET_PROPERTY_API_URL(id)))
    if(response.status === 200) {
        setPropertyInfo(response.data)
    }
  }

  useEffect(() => {
    getShared()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(propertyInfo) {
    setStreetViewError(false)
    getZillowLink()
    getRealtorLink()
    getGreatschoolsLink()
    geocodeAddress()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyInfo])

  return (
    <Main>
      <MainBlock>
        <Container>
          <MainContent>
            <Header1 style={width >= 1200 ? {fontSize: '4em', lineHeight: '5rem', letterSpacing: '0.1rem', marginRight: '8em'} : {fontSize: '3em', letterSpacing: '0.1rem'}}>Shared with you</Header1>
            
            {loaded === true && cart === null && <Header2>Hmm, that code didn't seem to work.</Header2>}
            {loaded === true && cart === null && <Header3 style={{color: 'black'}}>Your code should be in the format xxxx-xxxx.</Header3>}

            {loaded === true && cart !== null && <Header2>These homes were recommended by your agent</Header2>}
{/* 
            {cart && cart.length !== 0 && <select style={{width: '35%'}} onChange={handleSorting}>
                  <option value={11}>Sort by Address Number (Default, Low to High)</option>
                  <option value={12}>Sort by Address Number (High to Low)</option>
                  <option value={1}>Sort by Estimated Value (Low to High)</option>
                  <option value={2}>Sort by Estimated Value (High to Low)</option>
                  <option value={3}>Sort by Beds (Low to High)</option>
                  <option value={4}>Sort by Beds (High to Low)</option>
                  <option value={5}>Sort by Baths (Low to High)</option>
                  <option value={6}>Sort by Baths (High to Low)</option>
                  <option value={7}>Sort by Building Area (Low to High)</option>
                  <option value={8}>Sort by Building Area (High to Low)</option>
                  <option value={9}>Sort by Sale Date (Low to High)</option>
                  <option value={10}>Sort by Sale Date (High to Low)</option>
              </select>}  */}

            {cart && cart.map((cartItem, index) => ( 
                <div key={cartItem[10]}>
                    <ProposalHolder style={{borderLeft: '8px solid #bdccc1'}}>
                    <h2 style={{marginTop: '-1%', marginRight: '10px'}}>{index+1}</h2>
                        <ProposalText className="addressScore">
                            {propertyInfo === null && <span style={{fontWeight: 'bold'}} className="address" onClick={() => getPropertyInfo(cartItem[10])}>{cartItem[0]}</span>}
                            {propertyInfo && <span className="address" onClick={propertyInfo.id === cartItem[10] ? () => setPropertyInfo(null) : () => {setPropertyInfo(null); getPropertyInfo(cartItem[10])}}>{cartItem[0]}</span>}
                            <span className="address" style={{fontSize: '95%', marginTop: '-10px'}}>{cartItem[1]}, {cartItem[2]} {cartItem[3]}</span>
                        </ProposalText>

                        <ProposalText className="addressScore" style={width > 800 ? {marginLeft: '-20%'} : {}}>
                        {cartItem[12] ? <p>Click on this property to view a note from your agent.</p> : <></>}
                        </ProposalText>

                        <ProposalText className="addressOverview">
                            <span>
                            {(cartItem[4] && cartItem[5]) ? <span>{cartItem[4]} bed | {cartItem[5]} bath</span> : (cartItem[4] ? <p>{cartItem[4]} bed</p> : cartItem[5] ? <p>{cartItem[5]} bath</p> : <p></p>)}
                            </span> {cartItem[6] ? <span>| Built in {cartItem[6]}</span> : <></>} <br/>
                            <div style={{marginTop: '10px'}}></div>
                            {cartItem[11] ? <span>{cartItem[11].toLocaleString()} square feet | </span> : <></>}
                            {cartItem[7] && cartItem[8] ? <span>Last sold in {cartItem[7].toString().slice(0, 4)} for ${cartItem[8].toLocaleString()}</span> : 
                            
                            cartItem[7] ? <span>Last sold in {cartItem[7].toString().slice(0, 4)}</span> : <></>

                            }
                        </ProposalText>
                        <PrimaryButton style={{marginBottom: '10px', lineHeight: '15px', fontSize: '14px', paddingBottom: '15px', border: '2px solid white'}} onClick={() => remove(cartItem[10])}>Remove this home</PrimaryButton>
                    </ProposalHolder>

                    {propertyInfo && propertyInfo.id === cartItem[10] && <>
{/* 
                        {propertyInfo.latitude && propertyInfo.longitude && 
                
                            <Map provider={osm} height={width > 768 ? 400 : 300} width={width > 600 ? 580 : width / 4.2 + 200} center={[parseFloat(propertyInfo.latitude), parseFloat(propertyInfo.longitude)]} defaultCenter={[parseFloat(propertyInfo.latitude), parseFloat(propertyInfo.longitude)]} defaultZoom={14}>
                                
                                <NumberMarker score={80} scoreColor='#bdccc1' onMouseOver={() => setActive(propertyInfo.id)} onMouseOut={() => setActive(-1)} key={propertyInfo.id} width={60} anchor={[propertyInfo.latitude, propertyInfo.longitude]} color='#bdccc1'/>
                                    
                                <Overlay style={propertyInfo.id === active ? {display: 'block', backgroundColor: 'white', borderRadius: '5px', padding: '5px'} : {display: 'none'}} key={propertyInfo.id} width={100} anchor={[propertyInfo.latitude, propertyInfo.longitude]}>
                                    <Header3 style={{color: 'black', fontSize: '1.2em', marginTop: '-20px'}}>{propertyInfo.address_line_1}</Header3> 
                                        {
                                            propertyInfo.estimated_value_one ? <Header3 style={{fontSize: '0.9em', color: 'black', marginTop: '-20px', marginBottom: '-5px'}}>{propertyInfo.bedrooms} bed  {propertyInfo.bathrooms} bath  ${propertyInfo.estimated_value_one.toLocaleString()}</Header3>
                                            : <Header3 style={{fontSize: '0.9em', color: 'black', marginTop: '-20px', marginBottom: '-5px'}}>{propertyInfo.bedrooms} bed  {propertyInfo.bathrooms} bath </Header3>
                                        }                
                                </Overlay>
                                <ZoomControl buttonStyle={{height: 50, width: 80, borderRadius: 3}}/>
                            </Map>
                            
                        } */}
                        {streetViewError && <div style={{marginTop: '15px', marginBottom: '2px', maxWidth: '90%', height: '22em'}}>
                          <h1 style={{display: 'inline-block', textAlign: 'center', padding: '10px', marginTop: '10%'}}>Google Street View isn't available for this home.</h1> 
                          </div>}
                        {!!!streetViewError && <div id="streetview" className="streetview" style={{marginTop: '15px', marginBottom: '2px', maxWidth: '90%', height: '22em'}}></div>}
                    {zillowLink && <SubText style={{display: 'inline-block', marginRight: '1em', cursor: 'pointer', fontSize: '90%'}}><a key={propertyInfo.id} href={zillowLink} target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: 'black', cursor: 'pointer'}}>View on Zillow</a></SubText>}
                    {realtorLink && <SubText style={{display: 'inline-block', cursor: 'pointer', marginRight: '1em', fontSize: '90%'}}><a key={propertyInfo.id} href={realtorLink} target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: 'black'}}>View on Realtor.com</a></SubText>}
                    {greatschoolsLink && <SubText style={{display: 'inline-block', cursor: 'pointer', fontSize: '90%'}}><a key={propertyInfo.id} href={greatschoolsLink} target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: 'black'}}>View on GreatSchools.org</a></SubText>}
                    {cartItem[12] && <p>Agent says: <i>{cartItem[12]}</i></p>}
                    </>}
                </div>
            ))}
          
            <br/>
            
            {approved && <Header3>Woohoo! It's approved.</Header3>}
            <br/>
            {cart && <PrimaryButton style={{borderRadius: '15px'}} onClick={approve}>Approve this list</PrimaryButton>}
          </MainContent>
        </Container>
        </MainBlock>
    </Main>
  );
}

export default Shared;
