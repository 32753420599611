import {
  getRequest,
  patchRequest,
  postRequest,
} from "common/utils/axiosClient";
import {
  SEARCH_ADDRESS_API_URL,
  ADD_PROPERTY_OWNER_API_URL,
  GET_PROPERTIES_API_URL,
  GET_PROPERTY_API_URL
} from "address/constants/urls";

export async function searchAddress(address) {
  try {
    const response = await postRequest(SEARCH_ADDRESS_API_URL, {
      postal_code: address.postal_code,
      latitude: address.addressLat,
      longitude: address.addressLng,
      address: address.addressName,
      state: address.state,
      street_name: address.route, 
      street_number: address.street, 
      unit_value: address.unit_value,
      premise: address.premise, // can be nil
      city: address.city, // this is incorrect at least sometimes
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function getPropertyById(addressId) {
  try {
    const response = await getRequest(GET_PROPERTY_API_URL(addressId));
    return response;
  } catch (error) {
    return error;
  }
}

export async function addPropertyOwner(addressId) {
  try {
    const response = await patchRequest(ADD_PROPERTY_OWNER_API_URL(addressId));
    return response;
  } catch (error) {
    return error;
  }
}

export async function getHomeownerProperties(params) {
  try {
    const response = await getRequest(GET_PROPERTIES_API_URL, {
      params,
    });
    return response;
  } catch (error) {
    return error;
  }
}
