import React from "react";

import { Main, Container, Header1 } from "common/globalStyles";
import { ErrorPageBlock } from "common/pages/styles";
import image5 from "assets/images/img-5.svg";

function ErrorPage() {
  return (
    <Main>
      <ErrorPageBlock>
        <Container className="error-container">
          <div className="img-holder">
            <img src={image5} alt="img" />
          </div>
          <div className="text-block">
            <Header1>Something went wrong.</Header1>
            <p>Error 500. It's not you, we promise. You can always contact our customer support team at <br/> hello@unlistedinc.com.</p>
          </div>
        </Container>
      </ErrorPageBlock>
    </Main>
  );
}

export default ErrorPage;
