import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { OverlayLoader } from "common/components/DottedLoader";
import EmailForm from "user/components/EmailForm";
import EmailOTPForm from "user/components/EmailForm/EmailOTPForm";

import { GlobalContext } from "common/context/GlobalContext";
import { UserContext } from "user/context/UserContext";

import { sendUserOTPEmail, verifyUserOTP } from "user/api/UserAPI";

import {
  RECEIVED_PROPOSAL_LINK_ROUTE_URL,
  CREATE_PROPOSAL_LINK_ROUTE_URL,
  REFER_FRIEND_ROUTE_URL,
  HOME_PAGE_ROUTE_URL,
  PROPERTY_QUIZ_LINK_ROUTE_URL,
  CLAIMED_ERROR_ROUTE_URL,
} from "common/constants/urls";

function RegistrationForm({
  propertyId,
  redirectId,
  redirectTo,
  errorHandler,
  updateFormStep,
  registerProperty = false,
  prevPage
}) {
  const navigate = useNavigate();

  const { state, showLoader, hideLoader } = useContext(GlobalContext);
  const { isLoading } = state;

  const { userValue, loginUser, setPropertyOwnership } =
    useContext(UserContext);
  const { loggedIn } = userValue;

  const [userFormData, setUserFormData] = useState({
    email: "",
    token: "",
  });
  const [isFirstStep, setIsFirstStep] = useState(true);

  const handleFormRedirection = async () => {
    switch (redirectTo) {
      case "received-proposal-details":
        const successful = await(setPropertyOwnership(propertyId));
        if(successful) {
          navigate(RECEIVED_PROPOSAL_LINK_ROUTE_URL(redirectId));
        } else {
          errorHandler();
          navigate(CLAIMED_ERROR_ROUTE_URL)
        }
        break;
      case "proposal-create":
        navigate(CREATE_PROPOSAL_LINK_ROUTE_URL(redirectId));
        break;
      case "refer-friend-form":
        if (await setPropertyOwnership(redirectId)) {
          navigate(REFER_FRIEND_ROUTE_URL);
        } else {
          errorHandler();
          navigate(CLAIMED_ERROR_ROUTE_URL)
        }
        break;
      case "property-quiz":
        if (await setPropertyOwnership(propertyId)) {
          navigate(PROPERTY_QUIZ_LINK_ROUTE_URL(redirectId));
        } else {
          errorHandler();
          navigate(CLAIMED_ERROR_ROUTE_URL)
        }
        break;
      case "login":
        if(prevPage && prevPage !== 'none') { 
          window.location.href = prevPage
        } else {
          navigate(HOME_PAGE_ROUTE_URL);
        }
        break;
      default:
        if(prevPage && prevPage !== 'none') { 
          window.location.href = prevPage
        } else {
          navigate(HOME_PAGE_ROUTE_URL);
        }        
        break;
    }
    hideLoader();
  };

  const handleEmailStep = async (values) => {
    showLoader();
    setUserFormData({ ...userFormData, email: values.email });
    try {
      const response = await sendUserOTPEmail(
        { email: values.email },
        {
          redirect_id: redirectId,
          redirect_to: redirectTo,
          prev_page: prevPage
        }
      );
      if (response.status === 200) {
        hideLoader();
        setIsFirstStep(false);
      } else {
        throw new Error();
      }
    } catch {
      hideLoader();
      toast.error(
        "We couldn't send the login code. Try again?."
      );
    }
  };

  const handleOTPStep = async (values) => {
    showLoader();
    setUserFormData({ ...userFormData, token: values.token });
    try {
      const response = await verifyUserOTP({
        email: userFormData.email,
        token: values.token,
      });
      if (response.status === 200) {
        loginUser({
          userEmail: userFormData.email,
          userAuthToken: response.data.token,
        });
        handleFormRedirection();
      } else if (response.status === 400) {
        hideLoader();
        toast.error(
          Object.keys(response.data)
            .map((k) => `${k}: ${response.data[k].join(" | ")}`)
            .join("\n")
        );
      } else {
        throw new Error();
      }
    } catch {
      hideLoader();
      toast.error(
        "We couldn't verify your account. Try again?"
      );
    }
  };

  useEffect(() => {
    if (loggedIn) {
      handleFormRedirection();
    }
    return () => hideLoader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateFormStep(isFirstStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstStep]);

  return (
    <div>
      {!loggedIn && (
        <>
          {isFirstStep ? (
            <EmailForm 
              isLoading={isLoading}
              handleEmailStep={handleEmailStep}
            />
          ) : (
            <EmailOTPForm
              email={userFormData.email}
              isLoading={isLoading}
              handleOTPStep={handleOTPStep}
            />
          )}
          {redirectTo === "property-quiz" && (
            <p style={{ marginTop: "85px", width: '100%', fontSize: '0.9em' }}>
               T&C: Your property and account will be registered with us, and future interest in the property will be texted or emailed to you.
            </p>
          )}
        </>
      )}
      {isLoading && <OverlayLoader />}
    </div>
  );
}

RegistrationForm.propTypes = {
  propertyId: PropTypes.number,
  redirectId: PropTypes.number,
  redirectTo: PropTypes.string.isRequired,
  errorHandler: PropTypes.func,
  updateFormStep: PropTypes.func,
};

RegistrationForm.defaultProps = {
  propertyId: 0,
  redirectId: 0,
  errorHandler: () => {},
  updateFormStep: () => {},
};

export default RegistrationForm;
