/* eslint-disable react/no-danger */
import React from "react";
import PropTypes from "prop-types";
import {
  ProposalHolder,
  ProposalText,
} from "home/pages/Search/SearchItem/style";

import "./searchitem.css"

function SearchItem({
  property,
  score,
  setActive,
  setActiveLarge,
  scrollUp
}) {

  // const [liked, setLiked] = useState(false)
  
  // const likeProperty = () => {

  // }

  // const unlikeProperty = () => {

  // }

  const focusOnProperty = () => {
    setActiveLarge()
    scrollUp()
  }

  return (
    <ProposalHolder style={{borderLeft: '8px solid #bdccc1'}}>
  {/* {!liked? <Heart style={{width: '2.5rem', cursor: 'pointer', marginTop: '-10px'}} isActive={false} onClick={() => likeProperty(property.id)}/> : <Heart style={{width: '2.5rem', marginTop: '-10px'}} isActive={true} onClick={() => unlikeProperty(property.id)}/>} */}
        {/* <span className="photo" style={{marginLeft: '3em'}}> */}
        <span className="photo" style={{width: '10%', marginRight: '5px'}}>
        {property.cover_photo_url && <img alt="the front of the property you searched for" src={property.cover_photo_url}/>}
        </span>
      <ProposalText className="addressScore">
        <span style={{color: 'black', fontWeight: 'bold', fontSize: '90%'}}>{score}% RECOMMENDED</span>
        <span className="address" onMouseEnter={() => setActive(property.id)} onMouseLeave={() => setActive(-1)} style={{fontSize: '1.2em'}} onClick={focusOnProperty}>{property.address_line_1}</span>
        <span className="address" style={{fontSize: '95%', marginTop: '-10px'}}>{property.city}, {property.state} {property.zip}</span>
      </ProposalText>
      <ProposalText className="addressOverview">
      <span onClick={focusOnProperty} style={{cursor: 'pointer'}}>
        {(property.bedrooms && property.bathrooms) ? <span>{property.bedrooms} bed | {property.bathrooms} bath</span> : (property.bedrooms ? <p>{property.bedrooms} bed</p> : property.bathrooms ? <p>{property.bathrooms} bath</p> : <p></p>)}
        </span> {property.build_year ? <span>| Built in {property.build_year}</span> : <></>} <br/>
        <div style={{marginTop: '10px'}}></div>
        {property.last_sale_date && property.last_sale_amount ? <span>Last sold in {property.last_sale_date.toString().slice(0, 4)} for ${property.last_sale_amount.toLocaleString()}</span> : 
        
        property.last_sale_date ? <span>Last sold in {property.last_sale_date.toString().slice(0, 4)}</span> : <></>

        }
      </ProposalText>
    </ProposalHolder>
  );
}

SearchItem.propTypes = {
  property: PropTypes.object,
};

export default SearchItem;
