/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import DefaultInput from "common/components/Fields";
import { PrimaryButton } from "common/components/Buttons";
import { CodeHolder } from "user/components/RegistrationForm/styles";
import { InputFieldHolder } from "common/globalStyles";
import ErrorMessage from "common/components/ErrorMessage";

function EmailOTPForm({ email, isLoading, handleOTPStep }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <form onSubmit={handleSubmit(handleOTPStep)}>
      <p>
        UNLISTED doesn't require a password: we sent an email with a code to{" "}
        <a href={`mailto:${email}`} style={{ color: "#bdccc1" }}>
          {email}
        </a>
        .
        <br />
        Enter that code below:
      </p>
      <CodeHolder>
        <InputFieldHolder>
          <DefaultInput
            {...register(`token`, {
              required: "Token is required!",
              minLength: {
                value: 6,
                message: "Token must be 6 digits long!",
              },
              maxLength: {
                value: 6,
                message: "Token can only be 6 digits long!",
              },
            })}
            placeholder="Enter Six-Digit Code"
            autoComplete="off"
          />
          {errors.token && <ErrorMessage>{errors.token.message}</ErrorMessage>}
        </InputFieldHolder>
        <PrimaryButton style={{color: 'black'}} disabled={isLoading} type="submit">
          VERIFY
        </PrimaryButton>
      </CodeHolder>
    </form>
  );
}

EmailOTPForm.propTypes = {
  email: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleOTPStep: PropTypes.func.isRequired,
};

export default EmailOTPForm;
