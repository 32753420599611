import React, { useState, useEffect, lazy, useContext } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { Main, Container, Header1 } from "common/globalStyles";
import image1 from "assets/images/img-13.jpg";
import { OverlayLoader } from "common/components/DottedLoader";

import { AddressContext } from "address/context/AddressContext";
import { getRequest, postRequest } from "common/utils/axiosClient";

import { PIN_ADDRESS_SEARCH_RESULT_URL } from "common/constants/urls";
import { PropertiesPage, MyPropertiesList } from "./styles";
import { MY_LIST_API_URL, UNLIKE_PROPERTY_API_URL } from "address/constants/urls";
import { SecondaryButton } from "common/components/Buttons";
import Heart from "react-heart";

const Pagination = lazy(() => import("common/components/Pagination"));

function MyList() {
  const navigate = useNavigate();

  const { clearAddressData } = useContext(AddressContext);

  const [myList, setMyList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const unlikeProperty = async (propertyId) => {
    const response = await postRequest(UNLIKE_PROPERTY_API_URL(propertyId))
    if(response.status === 200) {
      getMyList(1)
    } else {
      toast.error("We couldn't remove that like.")
    }
  }

  const getMyList = async (currentPage) => {
    setIsLoading(true);
    const response = await getRequest(MY_LIST_API_URL, { params: { page: currentPage, page_size: 5 } });
    try {
      if (response && response.status === 200) {
        setMyList([...response.data.properties]);
        setTotalPages(Math.ceil(response.data.count / 5));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch {
      toast.error("We couldn't load your list.");
    }
  };
  const handlePageClick = (event) => {
    getMyList(event.selected + 1);
  };

  useEffect(async () => {
    getMyList(1);
    clearAddressData();
    return () => setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Main>
      <PropertiesPage>
        <Container>
          <Header1>My List</Header1>
          {isLoading && <OverlayLoader />}
          {!isLoading && myList.length === 0 && (
            <div>Like properties to add them to your list.</div>
          )}
          {!isLoading &&
            myList.length > 0 &&
            myList.map((property) => {
              return (
                <MyPropertiesList key={property.id}>
                  <div
                    className={
                      property && property.cover_photo_url
                        ? "img-holder"
                        : "img-holder empty"
                    }
                  >
                    {property && property.cover_photo_url ? (
                      <img src={property.cover_photo_url} alt="img" />
                    ) : (
                      <>
                        <img src={image1} alt="img" />
                        <p>Photo Not Available</p>
                      </>
                    )}
                  </div>
                  <div className="property-detail-block">
                    <address>
                      {property.address_line_1}{" "}
                      <span>
                        {property.city.toUpperCase()},{" "}
                        {property.state.toUpperCase()}
                      </span>
                    </address>
                    <ul className="key-details">
                      {property.likes > 1 && <> <Heart style={{width: '1em', marginRight: '0.5em'}} isActive={true}/> <span>{property.likes} people like this property.</span></>}
                    </ul>
                    <button
                      type="button"
                      onClick={() =>
                        navigate(PIN_ADDRESS_SEARCH_RESULT_URL, {
                            state: { propertyId: property.id, fromMyList: true },
                          })
                      }
                      className="btn-view"
                    >
                      View Property <i className="icon-right-arrow" />
                    </button>
                    <br/> <br/>
                    <SecondaryButton onClick={() => unlikeProperty(property.id)}>Remove from My List</SecondaryButton>
                  </div>
                </MyPropertiesList>
              );
            })}
          {myList.length > 0 && (
            <div className="pagination-wrapper">
              <Pagination
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePageClick}
                pageCount={totalPages}
                previousLabel="Previous"
              />
            </div>
          )}
        </Container>
      </PropertiesPage>
    </Main>
  );
}

export default MyList;
