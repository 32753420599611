import React, { useState, useContext, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import logo from "assets/images/logo.svg";
import user from "assets/images/user.png";
import {
  HeaderTag,
  HeaderContainer,
  HeaderLogo,
  HeaderNav,
  HeaderNavLink,
  ProfileImageHolderHeader,
} from "common/components/Header/styles";

import { UserContext } from "user/context/UserContext";

import {
  HOME_PAGE_ROUTE_URL,
  PROPOSAL_LIST_ROUTE_URL,
  HOMEOWNER_PROPERTY_ROUTE_URL,
  VIEW_PROFILE_ROUTE_URL,
  HOW_IT_WORKS_ROUTE_URL,
  LOGIN_ROUTE_URL,
  REGISTER_HOME_ROUTE_URL,
  MY_LIST_URL
} from "common/constants/urls";

function Header() {
  const location = useLocation();

  const { userValue, createImageFromNameInitials } = useContext(UserContext);
  const { loggedInUserInfo, loggedInUserInfoLoaded } = userValue;

  const imageRef = useRef(null);
  const hamNavRef = useRef(null);
  const subMenuRef = useRef(null);

  const [showSubMenu, setShowSubMenu] = useState(false);

  const onImageLoadError = () => {
    imageRef.current.onerror = null;
    imageRef.current.src = createImageFromNameInitials();
  };

  function handleSubMenuOutsideClick(event) {
    if (subMenuRef.current && !subMenuRef.current.contains(event.target)) {
      setShowSubMenu(false);
    }
  }

  useEffect(() => {
    // Closing Hamburger Navbar on Route Change
    hamNavRef.current.checked = false;
    setShowSubMenu(false);
  }, [location]);

  useEffect(() => {
    document.addEventListener("mousedown", handleSubMenuOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleSubMenuOutsideClick);
    };
  }, []);

  return (
    <HeaderTag style={window.location.pathname === '/' ? {borderBottom: 'none'} : {}}>
      <HeaderContainer>
        <HeaderLogo>
          <Link to={HOME_PAGE_ROUTE_URL}>
            <img src={logo} alt="logo" />
          </Link>
        </HeaderLogo>
        <input
          ref={hamNavRef}
          className="menu-btn"
          type="checkbox"
          id="menu-btn"
        />
        <label className="menu-icon" htmlFor="menu-btn">
          <span className="navicon" />
        </label>
        <HeaderNav className="menu">
          <ul>
            <li>
              <HeaderNavLink to={HOW_IT_WORKS_ROUTE_URL}>
                How It Works
              </HeaderNavLink>
            </li>
            <li>
              <HeaderNavLink to={REGISTER_HOME_ROUTE_URL}>
                Register My Home
              </HeaderNavLink>
            </li>
            {userValue.loggedIn && loggedInUserInfoLoaded && (
              <li ref={subMenuRef}>
                <ProfileImageHolderHeader>
                  <label>
                    <input
                      type="checkbox"
                      onClick={() => setShowSubMenu(true)}
                    />
                    <img
                      ref={imageRef}
                      src={
                        loggedInUserInfo.profile_picture_url === null
                          ? (loggedInUserInfo.first_name && loggedInUserInfo.last_name ? createImageFromNameInitials() : user)
                          : loggedInUserInfo.profile_picture_url
                      }
                      onError={onImageLoadError}
                      alt="profile img"
                    />
                  </label>
                </ProfileImageHolderHeader>
                <ul
                  className="submenu"
                  style={showSubMenu ? { display: "block" } : {}}
                >
                  <li>
                    <HeaderNavLink to={VIEW_PROFILE_ROUTE_URL}>
                      My Profile
                    </HeaderNavLink>
                  </li>
                  <li>
                    <HeaderNavLink to={MY_LIST_URL}>
                      My List
                    </HeaderNavLink>
                  </li>
                  <li>
                    <HeaderNavLink to={PROPOSAL_LIST_ROUTE_URL}>
                      My Conversations
                    </HeaderNavLink>
                  </li>
                  <li>
                    <HeaderNavLink to={HOMEOWNER_PROPERTY_ROUTE_URL}>
                      My Properties
                    </HeaderNavLink>
                  </li>
                </ul>
              </li>
            )}
            {!userValue.loggedIn && (
              <li>
                <HeaderNavLink style={{backgroundColor: 'black', color: 'white', border: "0.4em solid black", borderRadius: '9px', fontSize: '0.75em', padding: '0.3em'}} to={LOGIN_ROUTE_URL} state={{prevPage: window.location.pathname}}>Sign In</HeaderNavLink>
              </li>
            )}
          </ul>
        </HeaderNav>
      </HeaderContainer>
    </HeaderTag>
  );
}

export default Header;
