import styled from "styled-components";

export const Step0 = styled.div`
  padding: 20px 0;
  max-width: 750px;
`;

export const PhoneInputBlock = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;

  div {
    flex-grow: 1;
  }

  input {
    margin: 0;
  }

  button {
    width: 54px;
    flex-shrink: 0;
    margin: 0 0 0 20px;
  }

  span {
    bottom: -20px;
  }
`;

export const ViewProfileBlock = styled.div`
  padding: 80px 0;
  h1 {
    margin-bottom: 50px;
    font-size: 32px;
    line-height: 36px;
    @media (min-width: 768px) {
      font-size: 48px;
      line-height: 54px;
    }
  }
  .otp-btn {
    width: 100%;
    max-width: 320px;
    margin: auto;
  }
`;

export const UserProfileBlock = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
  }

  .img-block {
    width: 200px;
    flex-shrink: 0;
    text-align: center;
    margin: 0 auto;
    @media (min-width: 768px) {
      margin: 0;
    }
  }

  .img-holder {
    width: 200px;
    height: 200px;
    flex-shrink: 0;
    border-radius: 100%;
    overflow: hidden;
    margin: 0 auto 30px;
    @media (min-width: 768px) {
      margin: 0 0 30px;
    }
    img {
      display: block;
      max-width: 100%;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .name {
    font-size: 32px;
    line-height: 36px;
    display: block;
    font-family: "Playfair Display", serif;
    font-weight: 500;
    margin-bottom: 5px;
    @media (min-width: 768px) {
      font-size: 48px;
      line-height: 54px;
    }
  }

  .email,
  .tel {
    display: block;
    font-size: 20px;
    line-height: 27px;
    color: rgba(0, 0, 0, 0.44);
    margin-bottom: 5px;
  }

  .btn-upload {
    margin: auto;
    font-size: 16px;
    line-height: 20px;
    font-family: "Playfair Display", serif;
    font-weight: 500;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.44);
    text-transform: none;
    background: transparent;
    i {
      display: inline-block;
      vertical-align: top;
      padding: 0 4px 0 0;
    }
  }

  .user-info-holder {
    margin-bottom: 50px;
    @media (min-width: 1024px) {
      display: flex;
      align-items: center;
    }
  }

  .user-info-block {
    @media (min-width: 768px) {
      flex-grow: 1;
      padding-left: 50px;
      padding-top: 40px;
    }
  }

  .edit-info-block {
    max-width: 730px;
    @media (min-width: 768px) {
      flex-grow: 1;
      padding-left: 50px;
    }
    button {
      width: 45%;
      max-width: 320px;
      @media (min-width: 1024px) {
        width: 50%;
      }
    }
  }

  .input-fields-block {
    margin: 0 0 30px;
    @media (min-width: 768px) {
      display: flex;
      flex-flow: row wrap;
      margin: 0 -20px 40px;
    }

    > div {
      width: 100%;
      margin: 0 0 10px;
      max-width: inherit;
      @media (min-width: 768px) {
        width: calc(50% - 40px);
        margin: 0 20px 10px;
      }
    }
  }

  .user-info {
    @media (min-width: 768px) {
      padding-right: 50px;
      min-width: 450px;
    }
  }
  .btn-edit {
    font-size: 16px;
    line-height: 20px;
    font-family: "Playfair Display", serif;
    font-weight: 500;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.44);
    i {
      display: inline-block;
      vertical-align: top;
      padding: 0 4px 0 0;
    }
  }
`;

export const LogoutBlock = styled.div`
  max-width: 700px;
  padding: 15px 0 12px;
  text-align: center;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
  font-size: 16px;
  line-height: 24px;

  .btn-logout {
    text-decoration: none;
    color: #f4511e;
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding-left: 30px;
    background: none;
    border: none;
    cursor: pointer;
    &:before {
      position: absolute;
      font-family: "Playfair Display", serif;
      top: 2px;
      left: 0;
      transform: rotate(90deg);
    }
  }
`;

export const LoginBlock = styled.div`
  padding: 100px 0;

  h1 {
    font-size: 30px;
    line-height: 34px;
    @media (min-width: 768px) {
      font-size: 34px;
      line-height: 40px;
    }
  }

  button {
    width: 100%;
    max-width: 320px;
  }

  p {
    max-width: 600px;
  }
`;

export const ReferFriendBlock = styled.div`
  padding: 70px 0;
`;
