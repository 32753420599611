import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import user from "assets/images/user.png";

import { Main, Container, Header1 } from "common/globalStyles";
import {
  ViewProfileBlock,
  UserProfileBlock,
  LogoutBlock,
} from "user/pages/styles";

import { UserContext } from "user/context/UserContext";

import {
  HOME_PAGE_ROUTE_URL,
  EDIT_PROFILE_ROUTE_URL,
} from "common/constants/urls";

function ViewProfile() {
  const navigate = useNavigate();

  const { userValue, createImageFromNameInitials, logoutUser } =
    useContext(UserContext);
  const { loggedIn, loggedInUserInfo, loggedInUserInfoLoaded } = userValue;

  useEffect(() => {
    if (!loggedIn) {
      navigate(HOME_PAGE_ROUTE_URL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  return (
    <Main>
      <ViewProfileBlock>
        <Container>
          <Header1>My Profile</Header1>
          {loggedInUserInfoLoaded && (
            <UserProfileBlock>
              <div className="img-holder">
                <img
                  src={
                    loggedInUserInfo.profile_picture_url === null
                      ? (loggedInUserInfo.first_name && loggedInUserInfo.last_name ? createImageFromNameInitials() : user)
                      : loggedInUserInfo.profile_picture_url
                  }
                  alt="profile img"
                />
              </div>
              <div className="user-info-block">
                <div className="user-info-holder">
                  <div className="user-info">
                    {loggedInUserInfo.first_name && loggedInUserInfo.last_name === null && <strong className="name">{loggedInUserInfo.first_name}</strong>}
                    {loggedInUserInfo.first_name && loggedInUserInfo.last_name && <strong className="name">{loggedInUserInfo.first_name + " " + loggedInUserInfo.last_name}</strong>}
                    {loggedInUserInfo.first_name === null && <strong className="name">It looks like you haven't added your name.</strong>}
                    <span className="email">{loggedInUserInfo.email}</span>
                    {loggedInUserInfo.phone && (
                      <span className="tel">
                        {loggedInUserInfo.phone}
                      </span>
                    )}
                  </div>
                  <Link to={EDIT_PROFILE_ROUTE_URL} className="btn-edit">
                    <i className="icon-edit" /> Edit profile
                  </Link>
                </div>
                <LogoutBlock>
                  <button
                    onClick={() => logoutUser()}
                    type="button"
                    className="btn-logout icon-upload"
                  >
                    Logout
                  </button>
                </LogoutBlock>
              </div>
            </UserProfileBlock>
          )}
        </Container>
      </ViewProfileBlock>
    </Main>
  );
}

export default ViewProfile;
