export const SINGLE_PROPOSAL_API_URL = "/proposal/"
export const PROPOSAL_API_URL = "/proposals/";
export const MESSAGES_API_URL = "/messages/"
export const GET_PROPOSAL_PACKAGES_API_URL = "/packages/";
export const GET_SENT_PROPOSALS_API_URL = "/proposals/sent/";
export const GET_RECEIVED_PROPOSALS_API_URL = "/proposals/received/";
export const GET_PROPERTY_DATA_WITH_INVITE_CODE = "/proposals/invitation/"; //FIX: what is this?

export const INITIAL_PROPOSAL_UPDATE_API_URL = (proposalId) => {
  return `${SINGLE_PROPOSAL_API_URL}${proposalId}/initial`
}

export const GET_PROPOSAL_API_URL = (proposalId) => {
  return `${SINGLE_PROPOSAL_API_URL}${proposalId}/`;
};

export const GET_PROPOSAL_CONNECT_REALTOR_API_URL = (proposalId) => {
  return `${PROPOSAL_API_URL}${proposalId}/connect_with_realtor/`;
};

export const UPDATE_PROPOSAL_API_URL = (proposalId) => {
  return `${SINGLE_PROPOSAL_API_URL}${proposalId}/`;
};

export const ADD_PROPOSAL_VOUCER_API_URL = (proposalId) => {
  return `${PROPOSAL_API_URL}${proposalId}/add_voucher/`;
};

export const REMOVE_PROPOSAL_VOUCER_API_URL = (proposalId) => {
  return `${PROPOSAL_API_URL}${proposalId}/remove_voucher/`;
};

export const PROPOSAL_MESSAGES_API_URL = (proposalId) => {
  return `${MESSAGES_API_URL}${proposalId}/messages/`;
};

export const COMPLETE_PROPOSAL_WITH_ZERO_PAYMENT_API_URL = (proposalId) => { //FIX: what is this? payment intent...?
  return `${PROPOSAL_API_URL}${proposalId}/complete/`;
};

