import styled from "styled-components";

const ErrorMessage = styled.span`
  color: ${(props) => (props.theme.color ? props.theme.color : "red")};
  opacity: 0.6;
  position: absolute;
  left: 0;
  bottom: 7px;
  font-size: 12px;
  line-height: 16px;
`;

export default ErrorMessage;
