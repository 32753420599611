import React from "react";
import PropTypes from "prop-types";

import "./index.css"

function PropertyDetails({
  propertyType,
  baths,
  bedrooms,
  buildYear,
  lastSaleDate,
  lastSaleAmount,
  estimatedValue,
  schoolDistrict,
  latitude,
  longitude,
  state
}) {

  const greatschoolsLink = `https://www.greatschools.org/search/search.page?lat=${latitude}&locationType=coordinate&lon=${longitude}&st=public_charter&st=public&st=charter&state=${state}`

  return (
    <div className="pdetails">
      <ul>
        {propertyType && (
          <li>
            PROPERTY TYPE <strong>{propertyType === 'RESIDENTIAL_CREATED_SELLER' ? 'Residential (Uploaded by Seller)' : 'Residential'}</strong>
          </li>
        )}
        {baths > 0 && (
          <li>
            BATHROOMS <strong>{baths}</strong>
          </li>
        )}
        {bedrooms > 0 && (
          <li>
            BEDROOMS <strong>{bedrooms}</strong>
          </li>
        )}
        {buildYear > 0 && (
          <li>
            BUILT IN <strong>{buildYear}</strong>
          </li>
        )}
        {lastSaleDate && (
          <li>
            LAST SOLD IN {" "}
            <strong>{new Date(lastSaleDate).getFullYear()}</strong>
          </li>
        )}
        {lastSaleAmount > 0 && (
          <li>
            LAST SOLD FOR {" "}
            <strong>${parseFloat(lastSaleAmount).toLocaleString("en")}</strong>
          </li>
        )}
        {estimatedValue > 0 && (estimatedValue > lastSaleAmount / 2) && (
          <li>
            UNLISTED ESTIMATE {" "}
            <strong>${parseFloat(estimatedValue).toLocaleString("en")}</strong>
          </li>
        )}
        {schoolDistrict && (
          <li>
            NEAREST SCHOOL DISTRICT {" "}
            <strong>{schoolDistrict} (<a href={greatschoolsLink} target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: 'black'}}>View All</a>)</strong>
          </li>
        )
        }
      </ul>
    </div>
  );
}

PropertyDetails.propTypes = {
  addressLine: PropTypes.string,
  propertyType: PropTypes.string,
  baths: PropTypes.number,
  bedrooms: PropTypes.number,
  buildYear: PropTypes.number,
  lastSaleDate: PropTypes.string,
  lastSaleAmount: PropTypes.number,
  estimatedValue: PropTypes.number,
  schoolDistrict: PropTypes.string
};

PropertyDetails.defaultProps = {
  addressLine: "",
  propertyType: "",
  baths: 0,
  bedrooms: 0,
  buildYear: 0,
  lastSaleDate: "",
  lastSaleAmount: 0,
  estimatedValue: 0,
  schoolDistrict: ""
};

export default PropertyDetails;
