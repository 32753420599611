import { lazy } from "react";

const ProposalList = lazy(() => import("proposal/pages/ProposalList"));
const SentProposalDetails = lazy(() =>
  import("proposal/pages/SentProposalDetails")
);
const ReceivedProposalDetails = lazy(() =>
  import("proposal/pages/ReceivedProposalDetails")
);
const InviteHomeOwner = lazy(() => import("proposal/pages/InviteHomeOwner"));

const CreateProposal = lazy(() => import("proposal/pages/CreateProposal"));
const ProposalSuccess = lazy(() => import("proposal/pages/ProposalSuccess"));

export {
  ProposalList,
  SentProposalDetails,
  ReceivedProposalDetails,
  InviteHomeOwner,
  CreateProposal,
  ProposalSuccess,
};
