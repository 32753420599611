import { lazy } from "react";

import ProtectedRoute from "common/pages/ProtectedRoute";

import NotFound from "common/pages/NotFound";
import ErrorPage from "common/pages/ErrorPage";
import ClaimedPage from "./pages/ClaimedPage";

const HowItWorks = lazy(() => import("common/pages/HowItWorks"));
const Faq = lazy(() => import("common/pages/Faq"));

const PrivacyPolicy = lazy(() => import("common/pages/PrivacyPolicy"));
const PrivacyPolicyCalifornia = lazy(() =>
  import("common/pages/PrivacyPolicyCalifornia")
);
const TermsOfUse = lazy(() => import("common/pages/TermsOfUse"));

export {
  ProtectedRoute,
  NotFound,
  ErrorPage,
  HowItWorks,
  Faq,
  PrivacyPolicy,
  PrivacyPolicyCalifornia,
  TermsOfUse,
  ClaimedPage
};
