import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import analytics from "common/utils/analytics";

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

export default function GoogleAnalytics() {
  const location = useLocation();

  useEffect(() => {
    analytics.init(TRACKING_ID);
  }, []);

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    analytics.sendPageview(currentPath);
  }, [location]);

  return null;
}
