import styled from "styled-components";

export const PaymentFormHolder = styled.div`
  margin: 0;
  @media (min-width: 768px) {
    margin: 50px;
  }
`;

export const PromoBlock = styled.div`
  margin-bottom: 30px;
  @media (min-width: 768px) {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  h4 {
    margin: 0 0 20px;
    @media (min-width: 768px) {
      margin: 0 40px 0 0;
    }
  }

  input {
    width: 320px;
    margin: 0;
    background: #fff;
    border: 2px solid #000;
  }

  .promo-input {
    display: flex;
    flex-flow: row nowrap;
    position: relative;

    input {
      flex-grow: 1;
      height: 54px;
    }

    .apply-voucher-btn {
      width: 54px;
      margin: 0 0 0 5px;
      flex-shrink: 0;
      height: 54px;
    }

    .remove-voucher-btn {
      position: absolute;
      bottom: -45px;
      left: -15px;
      width: auto;
    }
  }
`;

export const RemoveVoucherBtn = styled.button`
  text-decoration: none;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 20px;
  color: #000;
  font-size: 12px;
  line-height: 16px;
  position: relative;
  padding-left: 15px;
  font-weight: 500;
  font-family: "Playfair Display", serif;
  background: transparent;
  border: 0;
  cursor: pointer;
`;

export const InputRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 0 -20px;

  > div {
    width: 100%;
    margin: 0 20px;
    @media (min-width: 768px) {
      width: 50%;
    }
  }

  &.small {
    margin: 0 -10px 30px;
    > div {
      max-width: 150px;
      margin: 0 10px;
    }
  }
`;

export const ButtonsHolder = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  max-width: 750px;
`;

export const FeeSelectBlock = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 20px;
`;

export const RadioHolder = styled.div`
  width: 100%;
  margin-bottom: 15px;
  @media (min-width: 200px) {
    width: 32%;
  }

  .radio-label {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 18px;
    line-height: 24px;

    .text-holder {
      padding: 15px 15px 15px 45px;
    }

    h3 {
      margin-bottom: 10px;
    }

    p {
      font-size: 18px;
      line-height: 24px;
      margin: 0;
    }

    a {
      color: #bdccc1;
    }

    input {
      position: relative;
      opacity: 0;
      cursor: pointer;
      &:checked {
        ~ .radio-checkmark {
          border-color: #bdccc1;
          &::after {
            display: block;
          }
        }

        ~ .text-holder {
          background: linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.96),
              rgba(255, 255, 255, 0.96)
            ),
            #000000;
        }
      }
    }
  }

  .radio-checkmark {
    position: absolute;
    top: 15px;
    left: 10px;
    height: 24px;
    width: 24px;
    background-color: #fff;
    border: 2px solid #000;
    border-radius: 100%;
    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      right: 4px;
      bottom: 4px;
      border-radius: 50%;
      background: #bdccc1;
    }
  }
`;
