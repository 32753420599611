import React, { createContext, useMemo, useReducer } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import userReducer from "common/reducers/globalReducer";
import {
  SHOW_LOADER,
  HIDE_LOADER,
  UPDATE_MULTI_FORM_STEP,
  CREATE_PROPOSAL_SUCCESS,
  CREATE_PROPOSAL_FAILURE,
  UPDATE_PROPOSAL_SUCCESS,
  UPDATE_PROPOSAL_FAILURE,
  SET_PROPOSAL_ACTION_PROCESSED,
  SET_PROPOSAL_PROCESSED_SUCCESSFUL,
  SET_PROPOSAL_VOUCHER_APPLIED,
} from "common/actions/globalActions";

import {
  updateProposal as updateProposalApi,
  createProposal,
  removeProposalVoucher,
  addProposalVoucher,
} from "proposal/api/ProposalAPI";

export const GlobalContext = createContext();

function GlobalContextProvider({ children }) {
  /* **** Initial State **** */
  const initialState = {
    isLoading: false,
    currentFormStep: 0,
    proposal: {},
    proposalDataLoaded: false,
    isproposalActionProcessed: false,
    isProposalProcessedSuccesful: false,
    isProposalVoucherApplied: false,
  };
  const [state, dispatch] = useReducer(userReducer, initialState);

  /* **** Action Creators **** */
  const showLoader = () => {
    dispatch({ type: SHOW_LOADER });
  };

  const hideLoader = () => {
    dispatch({ type: HIDE_LOADER });
  };

  const updateMultiFormStep = (step) => {
    dispatch({ type: UPDATE_MULTI_FORM_STEP, payload: step });
  };

  const setProposalActionProcessedStatus = (status) => {
    dispatch({ type: SET_PROPOSAL_ACTION_PROCESSED, payload: status });
  };

  const setProposalVoucherApplied = (status) => {
    dispatch({ type: SET_PROPOSAL_VOUCHER_APPLIED, payload: status });
  };

  const setProposalProcessedSuccessful = (status) => {
    dispatch({ type: SET_PROPOSAL_PROCESSED_SUCCESSFUL, payload: status });
  };

  const createNewProposal = async (addressId, proposalFormData) => {
    try {
      const response = await createProposal({
        property_id: addressId,
        ...proposalFormData,
        budgetAmount: proposalFormData.budgetAmount
          ? proposalFormData.budgetAmount
          : null,
      });
      if (response.status === 201) { 
        dispatch({
          type: CREATE_PROPOSAL_SUCCESS,
          payload: {
            ...response.data,
            proposalDefaultFee: response.data.prices[0],
          },
        });
        updateMultiFormStep(state.currentFormStep + 1);
      } else {
        throw new Error();
      }
    } catch {
      dispatch({
        type: CREATE_PROPOSAL_FAILURE,
      });
      toast.error(
        "We couldn't create that proposal. Try again?"
      );
    }
  };

  const updateProposal = async (proposal) => {
    try {
      const response = await updateProposalApi(state.proposal.id, proposal);
      if (response.status === 200) {
        dispatch({ type: UPDATE_PROPOSAL_SUCCESS, payload: response.data });
      } else if (response.status === 400) {
        toast.error(
          Object.keys(response.data)
            .map((k) => `${k}: ${response.data[k].join(" | ")}`)
            .join("\n")
        );
        dispatch({
          type: UPDATE_PROPOSAL_FAILURE,
        });
      } else {
        throw new Error();
      }
    } catch {
      dispatch({
        type: UPDATE_PROPOSAL_FAILURE,
      });
      toast.error(
        "We couldn't update that proposal. Try again?"
      );
    }
  };

  const setProposalVoucher = async (voucherCode) => {
    try {
      const response = await addProposalVoucher(state.proposal.id, voucherCode);
      if (response.status === 200) {
        dispatch({ type: UPDATE_PROPOSAL_SUCCESS, payload: response.data });
        setProposalVoucherApplied(true);
        toast.success("The voucher was added successfully!");
      } else if (
        response.status === 400 &&
        response.data.voucher_code[0]
      ) {
        dispatch({
          type: UPDATE_PROPOSAL_FAILURE,
        });
        setProposalVoucherApplied(false);
        toast.error(response.data.voucher_code.join(" | "));
      } else {
        throw new Error();
      }
    } catch {
      dispatch({
        type: UPDATE_PROPOSAL_FAILURE,
      });
      setProposalVoucherApplied(false);
      toast.error(
        "We couldn't add that voucher. Make sure you have the right code, and it's not expired."
      );
    }
  };

  const unsetProposalVoucher = async () => {
    try {
      const response = await removeProposalVoucher(state.proposal.id);
      if (response.status === 200) {
        dispatch({ type: UPDATE_PROPOSAL_SUCCESS, payload: response.data });
        setProposalVoucherApplied(false);
        toast.success("The voucher was removed.");
      } else {
        throw new Error();
      }
    } catch {
      dispatch({
        type: UPDATE_PROPOSAL_FAILURE,
      });
      setProposalVoucherApplied(true);
      toast.error(
        "We couldn't remove that voucher. Try again?"
      );
    }
  };

  const resetProposalData = () => {
    updateMultiFormStep(0);
    dispatch({
      type: CREATE_PROPOSAL_FAILURE,
    });
    setProposalProcessedSuccessful(true);
    setProposalActionProcessedStatus(false);
    setProposalVoucherApplied(false);
  };

  /* **** Context Values **** */
  const value = useMemo(() => ({
    state,
    showLoader,
    hideLoader,
    setProposalActionProcessedStatus,
    updateMultiFormStep,
    createNewProposal,
    updateProposal,
    setProposalVoucher,
    unsetProposalVoucher,
    setProposalProcessedSuccessful,
    resetProposalData,
  }));

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
}

GlobalContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GlobalContextProvider;
