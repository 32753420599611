import styled from "styled-components";
import { Container } from "common/globalStyles";
import { Link } from "react-router-dom";

export const HeaderTag = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid #000;
  font-family: "Playfair Display", serif;
  font-weight: 500;
  position: relative;
  background: #fff;
  z-index: 999;
  @media (min-width: 1024px) {
    padding: 30px 0;
  }

  .menu-icon {
    cursor: pointer;
    display: inline-block;
    position: relative;
    user-select: none;
    @media (min-width: 768px) {
      display: none;
    }
    .navicon {
      background: #333;
      display: block;
      height: 2px;
      position: relative;
      transition: background 0.2s ease-out;
      width: 18px;
      &::before,
      &::after {
        background: #333;
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        transition: all 0.2s ease-out;
        width: 100%;
      }
      &::before {
        top: 5px;
      }
      &::after {
        top: -5px;
      }
    }
  }
  .menu-btn {
    display: none;
    &:checked {
      ~ .menu {
        max-height: 350px;
      }
      ~ .menu-icon .navicon {
        background: transparent;
        &:before {
          transform: rotate(-45deg);
          top: 0;
        }
        &:after {
          transform: rotate(45deg);
          top: 0;
        }
      }
    }
  }
  .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
  }
`;

export const HeaderContainer = styled(Container)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderLogo = styled.div`
  width: 150px;
  @media (min-width: 768px) {
    width: 202px;
  }

  img {
    max-width: 100%;
  }
`;

export const HeaderNav = styled.div`
  top: 100%;
  position: absolute;
  left: 0;
  right: 0;
  max-height: 0;
  transition: all 0.2s ease-out;
  background: #fff;
  border-bottom: 1px solid #000;
  overflow: hidden;
  @media (min-width: 768px) {
    position: relative;
    max-height: inherit;
    border: none;
    overflow: inherit;
  }
  ul {
    margin: 0;
    list-style: none;
    font-size: 24px;
    line-height: 32px;
    padding: 20px;
    text-align: center;
    @media (min-width: 768px) {
      padding: 0;
      font-size: 20px;
      line-height: 24px;
    }
    @media (min-width: 1024px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  ul li {
    display: block;
    padding: 0 0 20px;
    position: relative;
    @media (min-width: 768px) {
      display: inline-block;
      vertical-align: middle;
      padding: 0 0 0 30px;
    }
    @media (min-width: 1024px) {
      padding: 0 0 0 50px;
    }
  }

  input {
    display: none;
  }

  input,
  ul.submenu {
    font-size: 16px;
    line-height: 20px;
    padding: 0;
    @media (min-width: 768px) {
      display: none;
      position: absolute;
      background: white;
      top: 47px;
      right: 0;
      width: 200px;
      z-index: 9999;
      border: 2px solid #bdccc1;
      box-shadow: 8px 8px 12px rgba(171, 171, 171, 0.25);
      text-align: left;
    }

    @media (min-width: 1024px) {
      top: 78px;
    }
  }

  ul.submenu li {
    padding: 5px 0;
    @media (min-width: 768px) {
      display: block;
      padding: 10px 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    }
    &:last-child {
      border: none;
    }
  }

  ul.submenu li a {
    text-decoration: none;
    color: #000;
    opacity: 0.5;
  }

  /*position the label*/
  label {
    position: relative;
    display: block;
    cursor: pointer;
    color: #bdccc1;
    font-weight: 500;
    height: 100%;
    width: 100%;
  }
`;

export const HeaderNavLink = styled(Link)`
  font-family: "Playfair Display", serif;
  text-decoration: none;
  color: black;
  font-weight: 500;
  font-size: 0.9em;
`;

export const ProfileImageHolderHeader = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  img {
    display: block;
    max-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
`;
