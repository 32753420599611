import styled from "styled-components";
import { Link } from "react-router-dom";

export const Main = styled.div`
  min-height: calc(100vh - 226px);
`;

export const Container = styled.div`
  max-width: 1240px;
  padding: 0 20px;
  margin: 0 auto;
`;

export const MainBlock = styled.div`
  padding: 70px 0;
  position: relative;

  h1 {
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 16px;
  }
  h2 {
    margin-bottom: 20px;
  }
  p {
    margin: 0 0 20px;
  }
`;


export const MainContent = styled.div`
  max-width: 1200px;
`;

export const SetupBlock = styled.div`
  padding: 50px 0;

  h1 {
    text-transform: none;
  }

  button {
    color: #bdccc1;
    width: 100%;
    max-width: 320px;
  }

  p {
    font-size: 18px;
    font-family: "Playfair Display", serif;
    line-height: 24px;
    margin: 0 0 20px;
    a {
      text-decoration: none;
      color: #bdccc1;
    }
  }

  h3 {
    color: #000;
  }
`;

export const Header1 = styled.h1`
  margin: 0 0 20px;
  font-family: "Playfair Display", serif;
  font-weight: 700;
`;

export const Header2 = styled.h2`
  color: black;
  margin: 0 0 20px;
  font-family: "Playfair Display", serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
`;

export const Header3 = styled.h3`
  margin: 0 0 20px;
  font-size: 20px;
  line-height: 25px;
  font-family: "Playfair Display", serif;
  font-weight: 500;

  &.uppercase {
    text-transform: uppercase;
  }

  span {
    font-size: 16px;
    font-weight: normal;
    display: inline-block;
    font-weight: normal;
    font-family: "Playfair Display", serif;
    margin-left: 10px;
    text-transform: none;
  }
`;

export const Header4 = styled.h4`
  color: #000;
  margin: 0 0 20px;
  font-size: 18px;
  line-height: 22px;
  font-family: "Playfair Display", serif;
  font-weight: 500;

  &.uppercase {
    text-transform: uppercase;
  }
  span {
    font-size: 16px;
    font-weight: normal;
    display: inline-block;
    font-weight: normal;
    margin-left: 10px;
    text-transform: none;
  }
`;

export const BackButton = styled.button`
  text-decoration: none;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 20px;
  color: #000;
  font-size: 12px;
  line-height: 16px;
  position: relative;
  padding-left: 15px;
  font-weight: 500;
  font-family: Futura;
  background: transparent;
  border: 0;
  cursor: pointer;
`;

export const BackLink = styled(Link)`
  text-decoration: none;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 20px;
  color: #000;
  font-size: 12px;
  line-height: 16px;
  position: relative;
  padding-left: 15px;
  font-weight: 500;
`;

export const SearchBlock = styled.div`
  margin-top: 40px;
  @media (min-width: 768px) {
    margin-top: 70px;
  }

  h2 {
    color: #000;
  }

  p {
    a {
      color: #bdccc1;
      text-decoration: none;
    }
  }
`;

export const InputFieldHolder = styled.div`
  max-width: 320px;
  position: relative;
  &.secondary-input {
    input {
      border-color: #bdccc1;
      background: #fff;
    }
    label {
      color: #bdccc1;
    }
  }

  &.dynamic-input {
    height: 95px;
  }

  &.extended-input {
    span {
      bottom: 30px;
    }
    button {
      margin-top: -10px;
    }
  }
`;

export const SubText = styled.p`
  color: black;
`;

export const IntialImageColor = "#bdccc1";

export const SliderText = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  font-family: "Playfair Display", serif;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.44);
`;
