import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
} from "common/utils/axiosClient";
import {
  PROPOSAL_API_URL,
  GET_PROPOSAL_API_URL,
  GET_PROPOSAL_PACKAGES_API_URL,
  ADD_PROPOSAL_VOUCER_API_URL,
  REMOVE_PROPOSAL_VOUCER_API_URL,
  PROPOSAL_MESSAGES_API_URL,
  GET_SENT_PROPOSALS_API_URL,
  GET_RECEIVED_PROPOSALS_API_URL,
  GET_PROPERTY_DATA_WITH_INVITE_CODE,
  GET_PROPOSAL_CONNECT_REALTOR_API_URL,
  COMPLETE_PROPOSAL_WITH_ZERO_PAYMENT_API_URL,
} from "proposal/constants/urls";

export async function createProposal(proposal) {
  try {
    const response = await postRequest(PROPOSAL_API_URL, {
      property_id: proposal.property_id,
      move_timeline_min: proposal.timelineMin,
      move_timeline_max: proposal.timelineMax,
      buyer_message: proposal.buyerMessage,
      budget_amount: proposal.budgetAmount,
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function getProposal(proposalId) {
  try {
    const response = await getRequest(GET_PROPOSAL_API_URL(proposalId));
    return response;
  } catch (error) {
    return error;
  }
}

export async function updateProposal(proposalId, proposal) {
  try {
    const response = await patchRequest(
      GET_PROPOSAL_API_URL(proposalId),
      proposal
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function connectRealtorApi(proposalId) {
  try {
    const response = await patchRequest(
      GET_PROPOSAL_CONNECT_REALTOR_API_URL(proposalId)
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function deleteProposal(proposalId) {
  try {
    const response = await deleteRequest(GET_PROPOSAL_API_URL(proposalId));
    return response;
  } catch (error) {
    return error;
  }
}

export async function getProposalPackages() {
  try {
    const response = await getRequest(GET_PROPOSAL_PACKAGES_API_URL);
    return response;
  } catch (error) {
    return error;
  }
}

export async function addProposalVoucher(proposalId, voucherCode) {
  try {
    const response = await postRequest(
      ADD_PROPOSAL_VOUCER_API_URL(proposalId),
      { voucher_code: voucherCode }
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function removeProposalVoucher(proposalId) {
  try {
    const response = await postRequest(
      REMOVE_PROPOSAL_VOUCER_API_URL(proposalId)
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function getProposalMessages(proposalId) {
  try {
    const response = await getRequest(PROPOSAL_MESSAGES_API_URL(proposalId));
    return response;
  } catch (error) {
    return error;
  }
}

export async function sendProposalMessage(proposalId, message) {
  try {
    const response = await postRequest(PROPOSAL_MESSAGES_API_URL(proposalId), {
      text: message.text,
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function getSentProposals(params) {
  try {
    const response = await getRequest(GET_SENT_PROPOSALS_API_URL, {
      params,
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function getReceivedProposals(params) {
  try {
    const response = await getRequest(GET_RECEIVED_PROPOSALS_API_URL, {
      params,
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function getPropertyDataByInviteCode(invitationCode) {
  try {
    const response = await postRequest(GET_PROPERTY_DATA_WITH_INVITE_CODE, {
      invitation_code: invitationCode,
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function completeProposalWithZeroPayment(proposalId) {
  try {
    const response = await postRequest(
      COMPLETE_PROPOSAL_WITH_ZERO_PAYMENT_API_URL(proposalId)
    );
    return response;
  } catch (error) {
    return error;
  }
}
