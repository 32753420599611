const SEARCH_ADDRESS_API_URL = "/properties/search/";
const ADD_PROPERTY_OWNER_API_URL = (propertyId) => {
  return `/property/${propertyId}/ownership/`;
};

const GET_PROPERTY_API_URL = (propertyId) => {
  return `/property/${propertyId}`
}

const GET_PROPERTIES_API_URL = "/properties";

const GET_PROPERTY_QUIZ_API_URL = (propertyId) => {
  return `${GET_PROPERTIES_API_URL}/${propertyId}/quiz/`;
};

const CREATE_PROPERTY_QUIZ_API_URL = (propertyId) => {
  return `${GET_PROPERTIES_API_URL}/${propertyId}/create_quiz/`;
};

const UPDATE_PROPERTY_QUIZ_API_URL = (propertyId) => {
  return `${GET_PROPERTIES_API_URL}/${propertyId}/quiz/`;
};

const PROPERTY_QUIZ_PHOTO_API_URL = (propertyId) => {
  return `/properties/${propertyId}/quiz/photo/`;
};

const GET_QUIZ_QUESTIONS_API_URL = "/quiz/questions/";

const OWNER_INFO_API_URL = "/owner_info/"; 

const GET_PROPERTY_RECOMMENDATIONS_URL = (propertyId) => {
  return `/properties/recommend/${propertyId}`
}

const REMOVE_OWNERSHIP_URL = (propertyId) => {
  return `/property/${propertyId}/remove`;
}

const GET_PROPERTY_LIKED_API_URL = (propertyId) => {
  return `/properties/${propertyId}/liked`
}

const GET_PROPERTY_LIKES_API_URL = (propertyId) => {
  return `/properties/${propertyId}/like_count`
}

const LIKE_PROPERTY_API_URL = (propertyId) => {
  return `/properties/${propertyId}/like`
}

const UNLIKE_PROPERTY_API_URL = (propertyId) => {
  return `/properties/${propertyId}/unlike`
}

const GET_PROPERTY_NOTIFICATION_URL = (propertyId) => {
  return `/properties/${propertyId}/notified`
}

const NOTIFY_FOR_PROPERTY_URL = (propertyId) => {
  return `/properties/${propertyId}/notify`
}

const UNNOTIFY_FOR_PROPERTY_URL = (propertyId) => {
  return `/properties/${propertyId}/unnotify`
}

const SEND_PROPERTY_UPDATE_URL = (propertyId) => {
  return `/properties/${propertyId}/send_update`
}

const MY_LIST_API_URL = "/properties/liked"

export {
  SEARCH_ADDRESS_API_URL,
  GET_PROPERTIES_API_URL,
  GET_PROPERTY_QUIZ_API_URL,
  ADD_PROPERTY_OWNER_API_URL,
  CREATE_PROPERTY_QUIZ_API_URL,
  UPDATE_PROPERTY_QUIZ_API_URL,
  PROPERTY_QUIZ_PHOTO_API_URL,
  GET_QUIZ_QUESTIONS_API_URL,
  OWNER_INFO_API_URL,
  GET_PROPERTY_API_URL,
  GET_PROPERTY_RECOMMENDATIONS_URL,
  REMOVE_OWNERSHIP_URL,
  GET_PROPERTY_LIKED_API_URL,
  GET_PROPERTY_LIKES_API_URL,
  LIKE_PROPERTY_API_URL,
  UNLIKE_PROPERTY_API_URL,
  MY_LIST_API_URL,
  GET_PROPERTY_NOTIFICATION_URL,
  NOTIFY_FOR_PROPERTY_URL,
  UNNOTIFY_FOR_PROPERTY_URL,
  SEND_PROPERTY_UPDATE_URL
};
