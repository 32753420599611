import {
  SHOW_LOADER,
  HIDE_LOADER,
  UPDATE_MULTI_FORM_STEP,
  CREATE_PROPOSAL_SUCCESS,
  CREATE_PROPOSAL_FAILURE,
  UPDATE_PROPOSAL_SUCCESS,
  UPDATE_PROPOSAL_FAILURE,
  SET_PROPOSAL_ACTION_PROCESSED,
  SET_PROPOSAL_PROCESSED_SUCCESSFUL,
  SET_PROPOSAL_VOUCHER_APPLIED,
} from "common/actions/globalActions";

const globalReducer = (state, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return { ...state, isLoading: true };
    case HIDE_LOADER:
      return { ...state, isLoading: false };
    case UPDATE_MULTI_FORM_STEP:
      return { ...state, currentFormStep: action.payload };
    case CREATE_PROPOSAL_SUCCESS:
      return {
        ...state,
        proposal: action.payload,
        proposalDataLoaded: true,
        isproposalActionProcessed: true,
      };
    case CREATE_PROPOSAL_FAILURE:
      return {
        ...state,
        proposal: {},
        proposalDataLoaded: false,
        isproposalActionProcessed: true,
      };
    case UPDATE_PROPOSAL_SUCCESS:
      return {
        ...state,
        proposal: {
          ...action.payload,
          proposalDefaultFee: state.proposal.proposalDefaultFee,
        },
        proposalDataLoaded: true,
        isproposalActionProcessed: true,
      };
    case UPDATE_PROPOSAL_FAILURE:
      return {
        ...state,
        proposalDataLoaded: false,
        isproposalActionProcessed: true,
      };
    case SET_PROPOSAL_ACTION_PROCESSED:
      return { ...state, isproposalActionProcessed: action.payload };
    case SET_PROPOSAL_PROCESSED_SUCCESSFUL:
      return { ...state, isProposalProcessedSuccesful: action.payload };
    case SET_PROPOSAL_VOUCHER_APPLIED:
      return { ...state, isProposalVoucherApplied: action.payload };
    default:
      return state;
  }
};

export default globalReducer;
