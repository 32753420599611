import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { UploadPicBlock, QuizContent } from "address/pages/styles";
import { DefaultButton } from "common/components/Buttons";
import { uploadPhoto } from "../../../../common/photo";
import { toast } from "react-toastify";
import DottedLoader from "common/components/DottedLoader";

function PropertyPicUpload({
  picUrl,
  setPicUrl,
  propertyId,
}) {
  const picRef = useRef(null);

  const [picName, setPicName] = useState("");
  const [photoLoading, setPhotoLoading] = useState(false)

  const handleFileUpload = async (event) => {
    setPhotoLoading(true)
    event.preventDefault();
    const response = await uploadPhoto(event.target.files[0], "propertycoverphotos", propertyId)
    if(response) {
      if(response === "invalid file type") {
        toast.error("That doesn't appear to be an image. Try a different one?")
      }
      setPicName(event.target.files[0].name)
      setPicUrl(response.get_url);
      toast.success("Property photo added! It may take a moment for the changes to appear.")
    } else {
      toast.error("We couldn't upload this property picture.")
    }
    setPhotoLoading(false)
  }

  const handleCancelPhoto = async() => {
    setPicUrl("");
    setPicName("");
  }

  return (
    <QuizContent>
      <UploadPicBlock>
        <strong className="title">
          Upload a photo that puts your house in its best light.
        </strong>
        <div className="upload-pic-area">
          <label>
            {!picUrl && (
              <span>
                Click to browse and <br />
                upload your files
              </span>
            )}
            <input
              onChange={(e) => handleFileUpload(e)}
              type="file"
              accept="image/png, image/jpeg"
              ref={picRef}
            />
            {picUrl && <img src={picUrl} alt="property-pic" />}
          </label>
          {picUrl && (
            <div className="pic-name-block">
              <span className="name">{picName}</span>
              <DefaultButton
                type="button"
                onClick={async () => {
                  handleCancelPhoto()
                }}
                disabled={picUrl === ""}
              >
                <i className="icon-del" />
              </DefaultButton>
            </div>
          )}
          {photoLoading && <DottedLoader/>}
        </div>
      </UploadPicBlock>
    </QuizContent>
  );
}

PropertyPicUpload.propTypes = {
  picUrl: PropTypes.string.isRequired,
  setPicUrl: PropTypes.func.isRequired,
  propertyId: PropTypes.number.isRequired,
};

export default PropertyPicUpload;
