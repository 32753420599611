import React, { useEffect, lazy, useState, useRef } from "react";
import PlacesAutocomplete from "react-places-autocomplete"
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { getRequest, postRequest } from "common/utils/axiosClient";
import {
  Main,
  Container,
  MainBlock,
  MainContent,
  Header3,
} from "common/globalStyles";
import DottedLoader from "common/components/DottedLoader";

import ErrorMessage from "common/components/ErrorMessage";

import "./search.css"

import SearchItem from "./SearchItem";

import {
  PoweredText,
  SearchInput,
  SearchInputContainer,
  SuggestionItem,
  SuggestionItemContainer,
  SuggestionItemHolder,
} from "./search_styles";

import greendoor from 'assets/images/greendoor.png'
import neighborhood from 'assets/images/neighborhood.png'
import lightbulb from 'assets/images/lightbulb.png'
import conversation from 'assets/images/conversation.png'
import whitedoor from 'assets/images/whitedoor.png'

// import { FeaturedHolder, FeaturedText } from "./featured_styles.js"; //placeholder for if we ever wish to bring the Featured Home back

import { Map, ZoomControl, Overlay } from "pigeon-maps"
import { osm } from "pigeon-maps/providers"
import SubProperty from "./SubProperty";

import { toast } from "react-toastify";

import NumberMarker from "./NumberMarker.tsx";

const HOUSE_STYLES_MAP = {
  1: '1.5 Story',
  2: 'A-frame',
  3: 'Bungalow',
  4: 'Cabin',
  5: 'Cape Cod',
  6: 'Colonial',
  7: 'Condo/apartment',
  8: 'Contemporary',
  9: 'Cottage',
  10: 'European',
  11: 'Farmhouse',
  12: 'Georgian',
  13: 'Historic',
  14: 'Mobile Home',
  15: 'Modern',
  16: 'Modular/Manufactured',
  17: 'Multi-story',
  18: 'Multi-family',
  19: 'Other',
  20: 'Ranch',
  21: 'Townhouse',
  22: 'Split Level',
  23: 'Tiny Home',
  24: 'Traditional',
  25: 'Tudor',
  26: 'Unfinished',
  27: 'Victorian'
}

const states = [ 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY' ]

const Pagination = lazy(() => import("common/components/Pagination"));

function Search() {

const [searchForm, setSearchForm] = useState({})
const [submitForm, setSubmitForm] = useState({})
const [searched, setSearched] = useState(false)
const [results, setResults] = useState([])
const [loading, setLoading] = useState(false)
const [totalPages, setTotalPages] = useState(0)
const [page, setPage] = useState(0)
const [searchedPrefill, setSearchedPrefill] = useState(false)
const [houseNumberError, setHouseNumberError] = useState(false)
const [unlistedScores, setUnlistedScores] = useState({})
const [currentPageResults, setCurrentPageResults] = useState([])
const [searchDirectly, setSearchDirectly] = useState(false)
// const [featuredHome, setFeaturedHome] = useState(null) //left for if we wish to once again implement Featured Home
const [coordinateBounds, setCoordinateBounds] = useState({})
const [savedZoom, setSavedZoom] = useState(null)
const searchInput = useRef(null)
const [showConnectWithAgent, setShowConnectWithAgent] = useState(false)


const prevSearchForm = localStorage.getItem("prevSearchForm")

const searchOptions = {
  types: ["address"],
  componentRestrictions: { country: "us" },
};

const [inputAddress, setinputAddress] = useState("");
const [suggestionSelected, setSuggestionSelected] = useState(false);
const [formattedAddress, setFormattedAddress] = useState("");
const [question, setQuestion] = useState("")
const [showNewSearch, setShowNewSearch] = useState(true)

const handleInput = (address) => {
  setinputAddress(address);
  setSuggestionSelected(false);
};

const handleSelect = (address) => {
  setSuggestionSelected(true);
  setLoading(true)
  geocodeByAddress(address).then((results) => {
    setFormattedAddress(results[0].formatted_address)
    getLatLng(results[0]).then((latLong) => {
      setSearchForm(values => ({...values, exact_lat: latLong.lat, exact_long: latLong.lng, address: results[0].formatted_address}))
      searchInput.current.blur()
      if(latLong === null) toast.error("Hmm, we couldn't find that address.")
    })
  })
  setLoading(false)
};

  const clearAddress = () => {
    setinputAddress("")
    setSuggestionSelected(false)
    setFormattedAddress("")
    setSearchForm({...searchForm, lat_min: null, long_min: null, lat_max: null, long_max: null})
  }
  
  const handleQuestionInput = (event) => {
    event.preventDefault()
    setPage(0)
    setQuestion(event.target.value)
  }

  const handleSearchForm = (event) => {
    event.preventDefault()
    setPage(0)
    const name = event.target.name
    const value = event.target.value
    setSearchForm(values => ({...values, [name]: value}))
  }

  const handleSubmitForm = (event) => {
    event.preventDefault()
    setPage(0)
    const name = event.target.name
    const value = event.target.value
    setSubmitForm(values => ({...values, [name]: value}))
  }

  const handleSearchBoxes = (event) => {
    setPage(0)
    const name = event.target.name
    const checked = event.target.checked
    setSearchForm(values => ({...values, [name]: checked}))
  }

  const handleSelectedForm = (event) => {
    setPage(0)
    setSearchForm(values => ({...values, 'style': event.target.value}))
  }

  const handleFireplaces = (event) => {
    setPage(0)
    if(event.target.value === '-1') {
      setSearchForm(values => ({...values, 'fireplaces': null}))
    } else {
    setSearchForm(values => ({...values, 'fireplaces': event.target.value}))
    }
  }

  const handleSearchRadius = (event) => {
    setPage(0)
    if(event.target.value === '-1') {
      setSearchForm(values => ({...values, 'radius': null}))
    } else {
    setSearchForm(values => ({...values, 'radius': event.target.value}))
    }
  }

  const handleStories = (event) => {
    setPage(0)
    if(event.target.value === '-1') {
      setSearchForm(values => ({...values, 'stories': null}))
    } else {
    setSearchForm(values => ({...values, 'stories': event.target.value}))
    }
  }

  const handleStateSelector = (event) => {
    setPage(0)
    if(event.target.value === 'State') {
      setSearchForm(values => ({...values, 'state': null}))
    } else {
    setSearchForm(values => ({...values, 'state': event.target.value}))
    }
  }

  const prefillSearchForm = async () => {
    const prevSearchFormObject = JSON.parse(prevSearchForm)
    if(prevSearchForm) {
      setPage(0)
      setSearchForm({...prevSearchFormObject})
      setSearchedPrefill(true)
      localStorage.removeItem("prevSearchForm")
    }
  }

  const searchProperties = async () => {
    if((searchForm.house_min || searchForm.house_max) && !searchForm.address) {
      setHouseNumberError(true)
      return
    }

    if(searchForm.build_min > searchForm.build_max) {
      const tempMin = searchForm.build_min
      setSearchForm({...searchForm, build_min: searchForm.build_max, build_max: tempMin})
    }

    setLoading(true)
    try {
    const response = await postRequest('/properties/searchv1', {
      page: page, ...searchForm
    })
    if(response.status === 200) {
      setActiveLarge(-1)
      setActive(-1)
      setLoading(false)
      setHouseNumberError(false)
      setSearched(true)
      setUnlistedScores(response.data.scores)
      setCurrentPageResults(response.data.properties.slice(page*10, (page+1)*10))
      setResults(response.data.properties)
      setTotalPages(response.data.count / 10)
    } }
    catch {
      setSearched(true)
      setLoading(false)
      setHouseNumberError(false)
      setResults([])
    }
  }

  const searchv2 = async () => {
    try {
    setLoading(true)
    const response = await getRequest('/properties/searchv2', { params : {
      question: question
    }})

    if(response.status === 200) {
      setActiveLarge(-1)
      setActive(-1)
      setLoading(false)
      setHouseNumberError(false)
      setSearched(true)
      setUnlistedScores(response.data.scores)
      setCurrentPageResults(response.data.properties.slice(page*10, (page+1)*10))
      setResults(response.data.properties)
      setTotalPages(response.data.count / 10)
    } else {
      setResults([])
      setLoading(false)
    } }
    catch {
      setSearched(true)
      setLoading(false)
      setHouseNumberError(false)
      setCurrentPageResults([])
      setResults([])
    }
  }

  const submitEmail = async () => {
    const response = await postRequest('/users/submit_email', submitForm)
    if(response.status === 200) {
      toast.success("We got your email and will be in touch shortly!")
    } else {
      toast.error("We had some trouble getting your email - try again?")
    }
    setShowConnectWithAgent(false)
  }

  const setBounds = ({center, zoom, bounds, initial}) => {
    setCoordinateBounds({ne: bounds.ne, sw: bounds.sw})
    setSavedZoom(zoom)
  }

  const getAllPropertiesInBounds = async () => {
    if(coordinateBounds.ne[1] - coordinateBounds.sw[1] > 0.07 || coordinateBounds.ne[0] - coordinateBounds.sw[0] > 0.07) {
      toast.error("You need to zoom in on the map a bit more before you can pull every property.")
      return
    }
    const response = await getRequest('/properties/properties_within_bounds', { params: { lb_lat: coordinateBounds.sw[0], lb_long: coordinateBounds.sw[1], ub_lat: coordinateBounds.ne[0], ub_long: coordinateBounds.ne[1], search_form: searchForm } })
    if(response.status === 200) {
      setActiveLarge(-1)
      setActive(-1)
      setLoading(false)
      setHouseNumberError(false)
      setSearched(true)
      setUnlistedScores(response.data.scores)
      setCurrentPageResults(response.data.properties.slice(page*10, (page+1)*10))
      setResults(response.data.properties)
      setTotalPages(response.data.count / 10)
    }
  }

  // const getFeaturedHome = async () => {
  //   try {
  //     const response = await getRequest('/featured_homes')
  //     if(response.status === 200) {
  //       setFeaturedHome(response.data.featured_home)
  //     } 
  //   } catch {
  //     // toast.error("We're having some trouble loading today's featured home. Check back later?")
  //   }
  // }

  const [width, setWidth] = useState(window.innerWidth)

  window.addEventListener('resize', function(event){
      setWidth(window.innerWidth)
  })

  const scrollUp = () => {
    document.getElementById('resultscrollarea').scrollIntoView()
  }

  const handlePageClick = (event) => {
    setPage(event.selected)
  };

  useEffect(() => {
    if(results.length !== 0) {
      setCurrentPageResults(results.slice(page*10, (page+1)*10))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    prefillSearchForm()
    // getFeaturedHome()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(searchedPrefill === true) {
    searchProperties()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedPrefill])

  const changeSearch = () => {
    setSearchForm({...searchForm, exact_lat: null, exact_long: null, address: null, radius: null, zip: null, state: null, city: null})
    setFormattedAddress("")
    setSuggestionSelected(false)
    setSearchDirectly(!searchDirectly)
  }

  useEffect(() => {
    if(results.length > 0) {
      setTimeout(() => {
        scrollUp()
      }, 200)
    }
  }, [results])

  const [active, setActive] = useState(-1)
  const [activeLarge, setActiveLarge] = useState(-1)

  return (
    <>
    {
      
      false ? <div>

<Header3 style={{fontSize: '2.1em', color: 'black', lineHeight: '1em', textAlign: 'center'}}>We'll be right back</Header3>
<Header3 style={{color: 'black', marginBottom: '30px', fontSize: '1.2em', textAlign: 'center'}}>UNLISTED is currently undergoing scheduled maintenance. Be sure to check back soon and discover properties you love.</Header3>

      </div>

      :

      <Main className="dashboard">
      <MainBlock>
        <Container>
          <MainContent>
            <div style={{textAlign: 'center'}}>
            <Header3 style={{fontSize: '2.1em', color: 'black', lineHeight: '1em'}}>For the first time ever, easily <br/> explore off-market homes</Header3>
              <Header3 style={{color: 'black', marginBottom: '30px', fontSize: '1.2em'}}>96% of homeowners would sell their home under the right {width > 720 && <br/>} conditions if asked. Unlisted lets you connect with the owners of {width > 720 && <br/>} off-market homes to learn if they're interested in a private sale.</Header3>

              {width > 400 ? 
              <>
              <button style={showNewSearch ? {borderRadius: '0px', width: '43%', backgroundColor: '#bdccc1', color: 'black', marginRight:'0px'} : {borderRadius: '0px', width: '43%', backgroundColor: 'white', border: '2px solid #bdccc1', color: 'black', marginRight: '0px'}} disabled={loading} onClick={() => setShowNewSearch(true)}>ChatGPT search <p style={{display: 'inline', backgroundColor: 'black', color: 'white', border: '3px solid black', borderRadius: '6px'}}>beta</p></button>
              <button style={showNewSearch ? {borderRadius: '0px', width: '43%', backgroundColor: 'white', border: '2px solid #bdccc1', color: 'black', marginRight: '0px'} : {borderRadius: '0px', width: '43%', backgroundColor: '#bdccc1', border: '2px solid #bdccc1', color: 'black', marginRight: '0px'}} disabled={loading} onClick={() => setShowNewSearch(false)}>Traditional search</button>
              </>
            
            :

            <>
              <button style={showNewSearch ? {borderRadius: '0px', width: '100%', backgroundColor: '#bdccc1', color: 'black'} : {borderRadius: '0px', width: '100%', backgroundColor: 'white', border: '2px solid #bdccc1', color: 'black'}} disabled={loading} onClick={() => setShowNewSearch(true)}>ChatGPT search <p style={{display: 'inline', backgroundColor: 'black', color: 'white', border: '3px solid black', borderRadius: '6px'}}>beta</p></button>
              <br/>
              <button style={showNewSearch ? {borderRadius: '0px', width: '100%', backgroundColor: 'white', border: '2px solid #bdccc1', color: 'black'} : {borderRadius: '0px', width: '100%', backgroundColor: '#bdccc1', border: '2px solid #bdccc1', color: 'black'}} disabled={loading} onClick={() => setShowNewSearch(false)}>Traditional search</button>
            </>
            
            }
      
            </div>

          {showNewSearch ? <div className="fullsearchholder" style={{textAlign: 'center'}}>
                <textarea style={{width: '86%', height: '5em', fontFamily: 'Playfair Display', border: '2px solid black', resize: 'none'}} className="longinput searchtextarea" type="text" name="question" value={question} placeholder="Show me houses in Upper Arlington, Ohio near Tremont Elementary School with a max price of 500,000." onChange={handleQuestionInput}/>

                {loading && <>
                
                <br/>
                <p>OK, we're looking that up for you. It can take some time to parse everything, so hold tight!</p>
                <DottedLoader/>
                <br/>
                </>}
                  <br/>
                <button style={width > 797 ? {width: '30%', height: '3em'} : {width: '70%'}} disabled={loading} onClick={searchv2}>Find my next home</button>

                {/* {featuredHome && !searched && <div>
                <FeaturedHolder>
                        {featuredHome.photo_url && <img src={featuredHome.photo_url}/>}
                      <FeaturedText>
                        <p style={{backgroundColor: 'black', color: 'white', border: '3px solid black', borderRadius: '6px', fontSize: '1.03em', padding: '3px'}}>New on UNLISTED</p>
                        <span className="address" style={{fontSize: '1.8em'}} onClick={() => setActiveLarge(featuredHome.property_id)}>{featuredHome.headline}</span>
                        <br/> <br/>
                        <span style={{fontSize: '15px'}}>{featuredHome.description}</span>
                        <br/> <br/>
                        {width >= 560 && <SubText onClick={() => navigate(LOGIN_ROUTE_URL)} style={{maxWidth: '25em', flexGrow: 1}}>Sign in to feature your home on Unlisted</SubText>}
                        {width < 560 && <SubText onClick={() => navigate(LOGIN_ROUTE_URL)} style={{maxWidth: '25em', flexGrow: 1}}>Sign in to feature <br/> your home on Unlisted</SubText>}
                      </FeaturedText>
                  </FeaturedHolder>

                </div>} */}
          </div> : 
          
          
          <div className="fullsearchholder">
            <br/>
            <div className="searcharea" style={width > 797 ? {marginLeft: '15%'} : {}}>
              {!searchDirectly ? <PlacesAutocomplete value={inputAddress} onChange={handleInput} onSelect={handleSelect} searchOptions={searchOptions}>
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <SuggestionItemHolder>
                            
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                            
                            <SearchInputContainer style={{height: '90%', width: '100%', display: 'flex', flexDirection: 'row', border: "2px solid #bdccc1", borderBottom: "2px solid #bdccc1"}} className="icon-search"> 
                            <SearchInput ref={searchInput}
                              {...getInputProps({
                                placeholder: formattedAddress ? "Close to " + formattedAddress : "Enter the address of a home, school, or location you want to live near",
                                onBlur: () => setinputAddress(""),
                              })}
                            />
                            {suggestionSelected && <button style={{width: '60px', marginTop: '5px'}} onClick={clearAddress}>X</button>}
                            </SearchInputContainer>
                            {width > 900 && <span style={{marginLeft: '55px', height: '5%', marginTop: '-15px'}}>
                              <label for="rad">Search Radius</label> <br/>
                              <select id="rad" onChange={handleSearchRadius}>
                                <option value={-1}></option>
                                <option value={1}>1 Block</option>
                                <option value={2}>2 Blocks</option>
                                <option value={3}>3 Blocks</option>
                                <option value={4}>4 Blocks</option>
                                <option value={8}>1 Mile</option>
                                <option value={5}>5 Miles</option>
                                <option value={10}>10 Miles</option>
                                <option value={25}>25 Miles</option>
                                <option value={100}>100 Miles</option>
                              </select>  
                            </span>}
                              </div>

                          <SuggestionItemContainer>
                            {inputAddress.length > 4 ? (
                              suggestions.map((suggestion, index) => {
                                return (
                                  <SuggestionItem
                                    {...getSuggestionItemProps(suggestion)}
                                    key={index}
                                  >
                                    <span>{suggestion.description}</span>
                                  </SuggestionItem>
                                );
                              })
                            ) : (
                              <span>
                                {inputAddress &&
                                  inputAddress.length > 4 &&
                                  !suggestionSelected && (
                                    <ErrorMessage>
                                      We couldn't find that house.
                                    </ErrorMessage>
                                  )}
                              </span>
                            )}
                          </SuggestionItemContainer>
                          <PoweredText>Powered by Google Maps API</PoweredText>
                          {loading && <DottedLoader />}
                        </SuggestionItemHolder>
                      )}
                    </PlacesAutocomplete>
                    
                    :

                    <div className="group">
                       <input className="longinput" type="text" name="address" value={searchForm.address} placeholder="Address" onChange={handleSearchForm}/>
                        <input className="longinput" type="text" name="city" value={searchForm.city} placeholder="City" onChange={handleSearchForm}/>
                        <select onChange={handleStateSelector}>
                            <option value={0}>State</option>
                            {
                              states.map((item) => 
                              <option value={item}>{item}</option>)
                            }
                          </select>          
                        <input className="medinput" inputProps={{ inputMode: 'numeric' }} type="number" name="zip" value={searchForm.zip} placeholder="Zip" onChange={handleSearchForm}/>
                    </div>
                    
                    }

                    {width <= 900 && !searchDirectly && <span>
                  <label for="rad">Search Radius</label> <br/>
                  <select id="rad" onChange={handleSearchRadius}>
                    <option value={-1}></option>
                    <option value={1}>1 Block</option>
                    <option value={2}>2 Blocks</option>
                    <option value={3}>3 Blocks</option>
                    <option value={4}>4 Blocks</option>
                    <option value={8}>1 Mile</option>
                    <option value={5}>5 Miles</option>
                    <option value={10}>10 Miles</option>
                    <option value={25}>25 Miles</option>
                    <option value={100}>100 Miles</option>
                  </select>  
                </span>}

                {!searchDirectly && <p style={width > 797 ? {cursor: 'pointer', fontSize: '14px', marginTop: '-38px'} : {color: '#bdccc1', cursor: 'pointer', fontSize: '14px'}} onClick={changeSearch}>Search by city, state, ZIP instead</p>}
                {searchDirectly && <p style={{cursor: 'pointer', fontSize: '14px', marginTop: '-8px'}} onClick={changeSearch}>Search near a location instead</p>}

                    <div className="group">
      
                <span style={{}}>
                  <label for="bds">Beds+</label>  <br/>
                  <input id="bds" className="longinput" inputProps={{ inputMode: 'numeric' }} type="number" name="beds" value={searchForm.beds} onChange={handleSearchForm} />
                </span>
                <span>
                  <label for="bts">Baths+</label>  <br/>
                  <input id="bts" className="longinput" inputProps={{ inputMode: 'numeric' }} type="number" name="baths" value={searchForm.baths} onChange={handleSearchForm}/>
                </span>
                <span style={{}}>
                  <label for="pmin">Price min</label>  <br/>
                  <input id="pmin" className="longinput" inputProps={{ inputMode: 'numeric' }} type="number" name="price_min" value={searchForm.price_min} onChange={handleSearchForm}/>
                </span>
                <span style={{}}>
                  <label for="pmax">Price max</label>  <br/>
                  <input id="pmax" className="longinput" inputProps={{ inputMode: 'numeric' }} type="number" name="price_max" value={searchForm.price_max} onChange={handleSearchForm}/>
                </span>
                <span>
                  <label for="bmin">Year built min</label>          
                  <input id="bmin" className="longinput" inputProps={{ inputMode: 'numeric' }} type="number" name="build_min" value={searchForm.build_min} onChange={handleSearchForm} />
                </span>
                <span>
                  <label for="bmax">Year built max</label>          
                  <input id="bmax" className="longinput" inputProps={{ inputMode: 'numeric' }} type="number" name="build_max" value={searchForm.build_max} onChange={handleSearchForm}/>
                </span>
              </div>
              
                <div className="group">


                <div style={{display: 'block'}}>
                  <label for="mina">Sq. foot min</label>  
                  <input id="mina" className="longinput" inputProps={{ inputMode: 'numeric' }} type="number" name="area_min" value={searchForm.area_min} onChange={handleSearchForm}/>
                </div>
                <div style={{display: 'block'}}>
                  <label for="maxa">Sq. foot max</label>  
                  <input id="maxa" className="longinput" inputProps={{ inputMode: 'numeric' }} type="number" name="area_max" value={searchForm.area_max} onChange={handleSearchForm}/>
                </div>


                <div style={{display: 'block'}}>
                  <label for="flrs">No. of floors</label>  <br/>
                  <select id="flrs" onChange={handleStories}>
                        <option value={-1}></option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                    </select>    
                </div>

                <div style={{display: 'block'}}>
                  <label for="fps">No. fireplaces</label>  <br/>
                  <select id="fps" onChange={handleFireplaces}>
                      <option value={-1}></option>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                  </select>        
                </div>

                <div style={{display: 'block'}}>
                  <label for="stl">Architectural style</label> <br/>
                  <select id="stl" onChange={handleSelectedForm}>
                  <option value={0}></option>
                  {
                    Object.entries(HOUSE_STYLES_MAP).sort((a, b) => {
                      if(a[1] < b[1]) {
                        return -1
                      }
                      if(a[1] > b[1]) {
                        return 1
                      }
                      return 0
                    }).map((item) => 
                    <option key={item[0]} value={item[0]}>{item[1]}</option>)
                  }
                </select>    
                </div>

                </div>

              <div>
              {<Header3>Bonus Features</Header3>}

              <>
              <div className="checkbox-list">
                  <div className="checkbox-item">
                  <input className="checkbox-input" id="checkbox1" type="checkbox" name="balcony" value={searchForm.balcony} onChange={handleSearchBoxes}/>
                  <label className="checkbox-label" for="checkbox1">Balcony</label>
                  </div>

                  <div className="checkbox-item">
                  <input className="checkbox-input" id="checkbox2" type="checkbox" name="breezeway" value={searchForm.breezeway} onChange={handleSearchBoxes}/>
                  <label className="checkbox-label" for="checkbox2">Breezeway</label>
                  </div>

                  <div className="checkbox-item">
                  <input className="checkbox-input" id="checkbox3" type="checkbox" name="boat_access" value={searchForm.boat_access} onChange={handleSearchBoxes}/>
                  <label className="checkbox-label" for="checkbox3">Boat Access</label>
                  </div>

                  <div className="checkbox-item">
                  <input className="checkbox-input" id="checkbox4" type="checkbox" name="cabin" value={searchForm.cabin} onChange={handleSearchBoxes}/>
                  <label className="checkbox-label" for="checkbox4">Cabin</label>
                  </div>

                  <div className="checkbox-item">
                  <input className="checkbox-input" id="checkbox5" type="checkbox" name="elevator" value={searchForm.elevator} onChange={handleSearchBoxes}/>
                  <label className="checkbox-label" for="checkbox5">Elevator</label>
                  </div>

                  <div className="checkbox-item">
                  <input className="checkbox-input" id="checkbox6" type="checkbox" name="game_room" value={searchForm.game_room} onChange={handleSearchBoxes}/>
                  <label className="checkbox-label" for="checkbox6">Game Room</label>
                  </div>

                  <div className="checkbox-item">
                  <input className="checkbox-input" id="checkbox7" type="checkbox" name="garage" value={searchForm.garage} onChange={handleSearchBoxes} />
                  <label className="checkbox-label" for="checkbox7">Garage</label>
                  </div>

                  <div className="checkbox-item">
                  <input className="checkbox-input" id="checkbox8" type="checkbox" name="guest_house" value={searchForm.guest_house} onChange={handleSearchBoxes}/>
                  <label className="checkbox-label" for="checkbox8">Guest House</label>
                  </div>

                  <div className="checkbox-item">
                  <input className="checkbox-input" id="checkbox9" type="checkbox" name="handicap_accessible" value={searchForm.handicap_accessible} onChange={handleSearchBoxes}/>
                  <label className="checkbox-label" for="checkbox9">Universal Access</label>
                  </div>

                  <div className="checkbox-item">
                  <input className="checkbox-input" id="checkbox10" type="checkbox" name="office" value={searchForm.office} onChange={handleSearchBoxes}/>
                  <label className="checkbox-label" for="checkbox10">Office</label>
                  </div>

                  <div className="checkbox-item">
                  <input className="checkbox-input" id="checkbox11" type="checkbox" name="pond" value={searchForm.pond} onChange={handleSearchBoxes}/>
                  <label className="checkbox-label" for="checkbox11">Pond</label>
                  </div>

                  <div className="checkbox-item">
                  <input className="checkbox-input" id="checkbox12" type="checkbox" name="sauna" value={searchForm.sauna} onChange={handleSearchBoxes}/>
                  <label className="checkbox-label" for="checkbox12">Sauna</label>
                  </div>

                  <div className="checkbox-item">
                  <input className="checkbox-input" id="checkbox13" type="checkbox" name="tennis_court" value={searchForm.tennis_court} onChange={handleSearchBoxes}/>
                  <label className="checkbox-label" for="checkbox13">Tennis Court</label>
                  </div>

                  <div className="checkbox-item">
                  <input className="checkbox-input" id="checkbox14" type="checkbox" name="wine_cellar" value={searchForm.wine_cellar} onChange={handleSearchBoxes}/>
                  <label className="checkbox-label" for="checkbox14">Wine Cellar</label>
                  </div>

                  <div className="checkbox-item">
                  <input className="checkbox-input" id="checkbox15" type="checkbox" name="pool" value={searchForm.pool} onChange={handleSearchBoxes}/>
                  <label className="checkbox-label" for="checkbox15">Pool</label>
                  </div>
              </div>
              <button style={{width: '14em'}} disabled={loading} onClick={searchProperties}>Find my next home</button>

            <br/>
            {loading && <div><h2>Looking...</h2> &nbsp; <DottedLoader/> </div>}
            {houseNumberError && <h2>( ! ) You need to enter a street in the Address field to search by House Number.</h2>}
              </>
              
              </div>
            </div>
          </div>}


            <br/>
            <div id="resultscrollarea" className={width > 1200 ? "resultarea" : "mobileresultarea"}>
            {!loading && searched && results.length !== 0 && <h2>We found the best properties for you.</h2>}
            {searched && !loading && results.length === 0 ? <h2>We couldn't find any properties. Try again?</h2> : 
            <div>

            {results.length !== 0 && activeLarge === -1 && <Map onBoundsChanged={setBounds} provider={osm} height={400} width={width > 1200 ? 1200 : width * 0.9} 
            center={    [(Math.min(...results.map(x => x.latitude)) + Math.max(...results.map(x => x.latitude))) / 2, (Math.min(...results.map(x => x.longitude)) + Math.max(...results.map(x => x.longitude))) / 2]
          } 
            defaultCenter={[results[0].latitude, results[0].longitude]} defaultZoom={savedZoom || 9}>
              {
                results.map(result => (
                  <NumberMarker score={unlistedScores[result.id]} scoreColor='#bdccc1'  onMouseOver={() => setActive(result.id)} onMouseOut={() => setActive(-1)} key={result.id} width={50} anchor={[result.latitude, result.longitude]} color='#bdccc1'
                  onClick={() => setActiveLarge(result.id)}/>
                ))
              }
              {
                results.map(result => (
                  <Overlay style={result.id === active ? {display: 'block', backgroundColor: 'white', borderRadius: '5px', padding: '5px'} : {display: 'none'}} key={result.id} width={100} anchor={[result.latitude, result.longitude]}>
                    <Header3 style={{color: 'black', fontSize: '0.9em', fontWeight: 'bold'}}>{unlistedScores[result.id]}% RECOMMENDED</Header3>
                    <Header3 style={{color: 'black', fontSize: '1.2em', marginTop: '-20px'}}>{result.address_line_1}</Header3> 
                    {
                      
                      result.estimated_value_one ? <Header3 style={{fontSize: '0.9em', color: 'black', marginTop: '-20px', marginBottom: '-5px'}}>{result.bedrooms} bed  {result.bathrooms} bath  ${result.estimated_value_one.toLocaleString()}</Header3>
                      : <Header3 style={{fontSize: '0.9em', color: 'black', marginTop: '-20px', marginBottom: '-5px'}}>{result.bedrooms} bed  {result.bathrooms} bath </Header3>
                    }
                  </Overlay>
                ))
              }
              
            <ZoomControl buttonStyle={{height: 50, width: 80, borderRadius: 3}}/>
            </Map>}
            {results.length !== 0 && (activeLarge === -1 ? <button style={{width: '20em'}} onClick={getAllPropertiesInBounds}>Show me everything within this map</button> : <button style={{width: '20em'}} onClick={() => setActiveLarge(-1)}>Show large map</button>)}


            {activeLarge !== -1 && <SubProperty unlistedScores={unlistedScores} propertyId={activeLarge} results={results} active={active} setActive={setActive} setActiveLarge={setActiveLarge}/>}

            {!loading && currentPageResults.map(result => (
                <>
                {result.address_line_1 && <SearchItem scrollUp={scrollUp} setActive={setActive} setActiveLarge={() => setActiveLarge(result.id)} key={result.id} property={result} score={unlistedScores[result.id]}/>}
                </>
            ))}
            </div>
            }


          
          {!searched ? <>
            {width > 1240 ? <img src={whitedoor} style={{marginLeft: '68%', marginBottom: '-58.5%', width: '32%'}} alt="cream door with brass handles"/> : <></>}
          <div style={{backgroundColor: 'rgba(51, 51, 51, 0.05)', width: '100%', height: '50%', marginTop: '5%'}}>
            <br/> <br/>
          <Header3 style={width > 1240 ? {marginLeft: '7%', fontSize: '2.1em', color: 'black', lineHeight: '1.5em', width: '35%'} : {marginLeft: '7%', fontSize: '2.1em', color: 'black', lineHeight: '1.5em', width: '80%'}}>Only 5% of homes are listed for sale. Unlisted gives you access to the other 95%.</Header3>
          <Header3 style={width > 1240 ? {marginLeft: '7%', color: 'black', marginBottom: '30px', fontSize: '1.2em', lineHeight: '1.4em', width: '35%'} : {marginLeft: '7%', color: 'black', marginBottom: '30px', fontSize: '1.2em', lineHeight: '1.4em', width: '80%'}}>You could buy a listed home - but research shows most people are unsatisfied with the listed options in their area. And it's no wonder; properties listed for
          sale represent only 5% of all homes. <br/> <br/>
          What if your dream home isn't for sale? What if there are no listed options that meet your criteria? Unlisted expands your search to all homes, both listed and unlisted, so you can find the best home for you.
          </Header3>

          <br/> <br/> <br/>

          </div>

          <br/> <br/>

          <div style={{width: '100%', height: '50%', marginTop: '5%', textAlign: 'center'}}>
          <Header3 style={{fontSize: '2.1em', color: 'black', lineHeight: '1.5em'}}>Three reasons buyers <br/> explore off-market homes</Header3>

            <span style={width > 720 ? {display: 'flex', flexDirection: 'row', justifyContent: 'space-between'} : {}}>
              <div style={width > 720 ? {maxWidth: '30%'} : {}}>
                <img src={conversation} alt="people chatting"/>
                <Header3 style={{fontSize: '1.4em', color: 'black', lineHeight: '1.5em'}}>More control</Header3>
                <Header3 style={{color: 'black', marginBottom: '30px', fontSize: '1.2em', lineHeight: '1.4em'}}>
                  Relying on the MLS means waiting for homeowners to list. With Unlisted, you have the power to start the conversation yourself.
                </Header3>
              </div>

              <div style={width > 720 ? {maxWidth: '30%'} : {}}>
                <img src={neighborhood} alt="a neighborhood"/>
                <Header3 style={{fontSize: '1.4em', color: 'black', lineHeight: '1.5em'}}>More options</Header3>
                <Header3 style={{color: 'black', marginBottom: '30px', fontSize: '1.2em', lineHeight: '1.4em'}}>
                  Why limit your search to just those listed for sale? Search all 121,000,000 homes in the U.S., not just the 5% that are listed at any given time.
                </Header3>
              </div>

              <div style={width > 720 ? {maxWidth: '30%'} : {}}>
                <img src={lightbulb} alt="lightbulb"/>
                <Header3 style={{fontSize: '1.4em', color: 'black', lineHeight: '1.5em'}}>More information</Header3>
                <Header3 style={{color: 'black', marginBottom: '30px', fontSize: '1.2em', lineHeight: '1.4em'}}>
                  What if you buy a home today, and your dream home lists tomorrow? Unlisted helps you learn homeowner plans so you can make an informed decision.
                </Header3>
              </div>

            </span>

          </div>

          <br/> <br/>

          {width > 1240 ? <img src={greendoor} style={{marginRight: '68%', width: '36%', marginBottom: '-47%'}} alt="cream door with brass handles"/> : <></>}
          <div style={{backgroundColor: '#333333', width: '100%', height: '50%', marginTop: '5%'}}>
            <br/> <br/> <br/>
          <Header3 style={width > 1240 ? {marginLeft: '50%', fontSize: '2.1em', color: 'white', lineHeight: '1.5em', width: '40%', marginRight: '7%'} : {marginLeft: '10%', fontSize: '2.1em', color: 'white', lineHeight: '1.5em', width: '80%', marginRight: '7%'}}>Work with an agent to start your off-market home search</Header3>
          <Header3 style={width > 1240 ? {marginLeft: '50%', width: '40%', color: 'white', marginBottom: '30px', fontSize: '1.2em', marginRight: '7%'} : {marginLeft: '10%', width: '80%', color: 'white', marginBottom: '30px', fontSize: '1.2em', marginRight: '7%'}}>
            Get matched with an agent in your area who specializes in off-market properties and private home sales.
          </Header3>

          {
            showConnectWithAgent ? <>
            <br/>
            <input id="bds" className="longinput" style={width > 1240 ? {marginLeft: '50%', width: '40%'} : {marginLeft: '10%', width: '50%'}} type="text" name="email" placeholder="enter your email" value={submitForm.email} onChange={handleSubmitForm} />
            
            </> : <></>

          }

          {showConnectWithAgent ? <button style={width > 1240 ? {marginLeft: '50%', width: '12em', border: '5px solid #BDCCC1', borderRadius: '5px'} : {marginLeft: '10%', width: '12em', border: '5px solid #BDCCC1', borderRadius: '5px'}} onClick={submitEmail}>Submit</button> : 
          <button style={width > 1240 ? {marginLeft: '50%', width: '12em', border: '5px solid #BDCCC1', borderRadius: '5px'} : {marginLeft: '10%', width: '16em', border: '5px solid #BDCCC1', borderRadius: '5px'}} onClick={() => setShowConnectWithAgent(true)}>Connect with an agent</button>}


            <br/> <br/> <br/> <br/> <br/>

          </div>

          {width < 800 ? <img src={greendoor} style={{width: '100%', marginBottom: '-10%'}} alt="cream door with brass handles"/> : <></>}
          
          </> : <></>}

          {width > 1240 ? <> <br/> <br/> </> : <></>}

          {results.length > 0 && (
            <Pagination
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePageClick}
              pageCount={totalPages}
              previousLabel="Previous"
            />
          )}
            {searched && <Header3>Want more results? Consider expanding your search.</Header3>}
            </div>
          </MainContent>
        </Container>
      </MainBlock>
    </Main>}
    </>
  );
}

export default Search;