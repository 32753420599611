import React, { useEffect, useContext, lazy, Suspense, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import brassdoor from "assets/images/brass-design.png"

import {
  Main,
  Container,
  MainBlock,
  MainContent,
  Header1,
  Header2,
  SearchBlock,
  SubText,
} from "common/globalStyles";
import DottedLoader from "common/components/DottedLoader";

import { GlobalContext } from "common/context/GlobalContext";
import { AddressContext } from "address/context/AddressContext";

import {
  PIN_ADDRESS_MAP_ROUTE_URL,
  INVITE_HOME_OWNER_ROUTE_URL,
  SEARCH_ROUTE_URL,
} from "common/constants/urls";

const AddressSearchField = lazy(() =>
  import("address/components/AddressSearchField")
);

function Home() {
  const navigate = useNavigate();

  const { state, resetProposalData } = useContext(GlobalContext);

  const { clearAddressData } = useContext(AddressContext);

  const navigateToMap = () => {
    navigate(PIN_ADDRESS_MAP_ROUTE_URL, {
      state: { isLandedFromOwnerSearchPage: false },
    });
  };

  const [width, setWidth] = useState(window.innerWidth)
  window.addEventListener('resize', function(event){
    setWidth(window.innerWidth)
  })

  useEffect(() => {
    resetProposalData();
    clearAddressData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Main>
      <MainBlock>
        <Container>
          <MainContent>
            <Header1 style={width >= 1200 ? {fontSize: '4em', lineHeight: '5rem', letterSpacing: '0.1rem', marginRight: '8em'} : {fontSize: '3em', letterSpacing: '0.1rem'}}>Off-market is no longer off-limits</Header1>
            <p style={width > 1200 ? {marginRight: '40em'} : {}}>
              Browse off-market homes, contact their owners, and spark neighborly conversations 
              that could land you in the house of your dreams.
            </p>

            {width > 1200 && <img src={brassdoor} style={{position: 'relative', maxWidth: '40%', height: 'auto', float: 'right', marginLeft: '1em', marginTop: '-15em', marginBottom: '2em'}}/>}

            <SearchBlock>
              <Header2>Let’s get started!</Header2>
              <p>To get started, enter the address of a home you love, or <Link to={SEARCH_ROUTE_URL}>search for one.</Link></p>
              <Suspense fallback={<DottedLoader />}>
                <AddressSearchField navigateNext={navigateToMap}>
                  <SubText>
                    <b>
                    <Link to={INVITE_HOME_OWNER_ROUTE_URL}>
                      Received an invite code? Start here!
                    </Link>
                    </b>
                  </SubText>
                </AddressSearchField>
                <SubText>Agents, we're here for you too. <b><a href="https://agents.unlistedhomes.com">UNLISTED for Agents</a></b> lets you effortlessly uncover hidden inventory and give your clients access to off-market properties.</SubText>
              </Suspense>
              {state.isLoading && <DottedLoader />}
            </SearchBlock>
          </MainContent>
        </Container>
        </MainBlock>
    </Main>
  );
}

export default Home;
