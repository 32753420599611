import React from "react";
import styled, { keyframes } from "styled-components";

function DottedLoader() {
  return (
    <Snippet data-title=".dot-flashing">
      <div className="stage">
        <DotFlash>&nbsp;</DotFlash>
      </div>
    </Snippet>
  );
}

export function OverlayLoader() {
  return (
    <OverlayHolder>
      <Snippet data-title=".dot-flashing">
        <div className="stage">
          <DotFlash>&nbsp;</DotFlash>
        </div>
      </Snippet>
    </OverlayHolder>
  );
}

export default DottedLoader;

const OverlayHolder = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 99999999;
`;

const Snippet = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
`;

const dotFlashing = keyframes`
  0% {
    background-color: #c9a42b;
  }

  100% {
    background-color: #000;
  }
`;

const DotFlash = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #c9a42b;
  color: #c9a42b;
  animation: ${dotFlashing} 0.7s infinite linear alternate;
  animation-delay: 0.3s;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #c9a42b;
    color: #c9a42b;
    animation: ${dotFlashing} 0.7s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #c9a42b;
    color: #c9a42b;
    animation: ${dotFlashing} 0.7s infinite alternate;
    animation-delay: 0.6s;
  }
`;
