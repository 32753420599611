/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import { InputFieldHolder } from "common/globalStyles";
import { PrimaryButton } from "common/components/Buttons";
import DefaultInput from "common/components/Fields";
import ErrorMessage from "common/components/ErrorMessage";

function EmailForm({ isLoading, handleEmailStep }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <form onSubmit={handleSubmit(handleEmailStep)}>
      <InputFieldHolder>
        <DefaultInput style={{border: '2px solid #bdccc1', borderRadius: '5px', backgroundColor: 'white'}}
          {...register(`email`, {
            required: "Email is required!",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Please enter a valid email!",
            },
          })}
          placeholder="Enter your email address"
        />
        {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
      </InputFieldHolder>
      <PrimaryButton style = {{borderRadius: '10px', color: 'black'}} disabled={isLoading} type="submit">
        Verify Email
      </PrimaryButton>
    </form>
  );
}

EmailForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  handleEmailStep: PropTypes.func.isRequired,
};

export default EmailForm;
