import styled from "styled-components";

export const ProposalHolder = styled.div`
  max-width: 811px;
  margin-bottom: 20px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.96),
      rgba(255, 255, 255, 0.96)
    ),
    #000000;
  padding: 20px 30px;
  border-left: 8px solid #bdccc1;
  text-align: center;

  @media (min-width: 768px) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    padding: 20px 40px;
  }

  &.secondary-proposal {
    border-color: rgba(0, 0, 0, 0.44);
  }

  p {
    margin: 0 0 10px;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
    line-height: 18px;
  }

  .address {
    display: block;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .proposal-price {
    display: block;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  .time {
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.44);
    display: block;
  }

  .btn-view {
    text-decoration: none;
    font-weight: normal;
    font-family: "Playfair Display", serif;
    font-size: 16px;
    line-height: 20px;
    border: none;
    background: none;
    cursor: pointer;
    i {
      display: inline-block;
      vertical-align: top;
      padding: 2px 4px 0 0;
    }
  }
`;
export const ProposalText = styled.div`
  margin-bottom: 20px;
  text-align: left;
  @media (min-width: 768px) {
    max-width: 70%;
    padding-right: 30px;
    margin: 0;
  }
`;
