import styled from "styled-components";

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;
  &.display-block {
    display: block;
  }

  &.display-none {
    display: none;
  }
`;

export const ModalMain = styled.section`
  position: fixed;
  background: white;
  max-width: 800px;
  width: 100%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 50px;

  h2 {
    color: #000;
  }

  p {
    font-size: 18px;
    line-height: 24px;
    color: #707070;
    &.num {
      color: #000;
    }
  }

  .input-modal {
    margin: 40px auto 0;
  }

  .btn-close {
    border: none;
    background: none;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: auto;
  }
`;
