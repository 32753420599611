import styled from "styled-components";
import { Link } from "react-router-dom";

export const SearchResults = styled.div`
  padding: 50px 0;

  h1 {
    margin-bottom: 50px;
    text-transform: none;
  }

  button {
    width: 100%;
  }
`;

export const QuizBlock = styled.div`
  padding: 50px 0;
  min-height: calc(100vh - 226px);
  position: relative;
  @media (min-width: 1024px) {
    padding: 50px 0 150px;
  }

  .container {
    max-width: 900px;
    min-height: calc(100vh - 345px);
    position: relative;
    padding-bottom: 100px;
    @media (min-width: 1024px) {
      position: static;
      padding-bottom: 0;
    }
    @media (min-width: 1200px) {
      max-width: 1000px;
    }
  }

  button {
    width: 100%;
  }

  p {
    font-size: 18px;
    font-family: "Playfair Display", serif;
    font-weight: 500;
  }

  .property-questions {
    .btns-holder {
      max-width: 700px;
      margin: 0 auto;
      text-align: left;

      button {
        margin: 0 16px 0 0;

        &.submit-btn {
          @media (min-width: 1024px) {
            width: 318px;
            padding: 15px 20px;
          }
        }
      }
    }
  }

  .quiz-loader {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 130px;

    @media (min-width: 1024px) {
      bottom: 215px;
    }

    &.first-quiz-page {
      bottom: 50px;

      @media (min-width: 1024px) {
        bottom: 100px;
      }
    }
  }

  .form-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    > div {
      width: 100%;
    }
  }
`;

export const TwoCols = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-flow: row wrap;
  }
`;

export const BtnsHolder = styled.div`
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 50px;
  text-align: center;
  @media (min-width: 1024px) {
    bottom: 130px;
  }

  button {
    width: 150px;
    display: inline-block;
    margin: 0 12px;
    padding: 13px 20px;
    text-transform: uppercase;

    i {
      padding: 0 5px;
    }

    &.btnnxt {
      padding: 15px 0px;
    }
  }
`;

export const MapBlock = styled.div`
  max-width: 420px;
  @media (min-width: 768px) {
    width: 45%;
    max-width: inherit;
  }
  button {
    width: 100%;
  }
`;

export const MapHolder = styled.div`
  margin-bottom: 50px;
`;

export const LocationBlock = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-family: "Playfair Display", serif;
  align-items: center;
`;

export const LocationChangeLink = styled(Link)`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.5);
`;

export const LocationName = styled.strong`
  font-family: "Playfair Display", serif;
  display: block;
  max-width: 80%;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
`;

export const GoogleMapContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
`;

export const HomeDetails = styled.div`
  @media (min-width: 768px) {
    width: 55%;
    padding: 30px 0 0 50px;
  }

  @media (min-width: 1024px) {
    width: 55%;
    padding: 30px 0 0 100px;
  }

  ul {
    margin: 0 0 30px 10px;
    padding: 0;
    list-style: none;
    font-size: 22px;
    line-height: 30px;
  }

  ul li {
    position: relative;
    padding: 0 0 10px 15px;
  }

  ul li:before {
    position: absolute;
    content: "";
    top: 12px;
    left: 0;
    height: 6px;
    width: 6px;
    border-radius: 100%;
    background: #000;
  }
`;

export const ErrorMessageHolder = styled.div`
  min-height: 240px;
  display: flex;
  align-items: center;

  span {
    color: #707070;
    font-size: 20px;
    line-height: 26px;
    position: relative;
  }
`;

export const InputBtnHolder = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 200px;
  .input-holder {
    flex-grow: 1;
    padding: 0 8px 0 0;
  }
`;

export const ConfirmOwnershipModal = styled.div`
  .confirm-btn {
    width: 100%;
    max-width: 320px;
    margin: 20px auto;
  }
`;

export const HomeInfoBlock = styled.div`
  max-width: 350px;

  .title {
    display: block;
    margin-bottom: 20px;
  }

  textarea {
    border: 2px solid rgba(0, 0, 0, 1);
    padding: 15px 10px 14px;
    margin-bottom: 25px;
    outline: none;
    border-radius: 0;
    width: 100%;
    font-family: "Playfair Display", serif;
    resize: none;
    height: 100px;
  }

  dl {
    margin: 0 0 30px;
    overflow: hidden;

    dt {
      float: left;
      margin: 0 0 10px;
      clear: both;
      font-family: "Playfair Display", serif;
      font-size: 14px;
    }

    dd {
      color: #bdccc1;
      float: right;
      margin: 0 0 10px;
    }

    input {
      float: right;
      margin: 0 0 10px;
      width: 70px;
      height: 25px;
      border: 2px solid #000;
      outline: none;
      padding: 2px 5px;

      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
    }
  }
`;

export const UploadPicBlock = styled.div`
  padding: 15px 0;
  max-width: 450px;
  margin: 0 auto;

  .title {
    display: block;
    margin-bottom: 20px;
  }

  label {
    padding: 10px;
    max-width: 450px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 2px dashed #bdccc1;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 30px;

    span {
      opacity: 0.5;
      max-width: 50%;
      margin: 0 auto;
      display: block;
      text-align: center;
    }

    img {
      max-width: 450px;
      height: 300px;
      object-fit: cover;
    }
  }

  input[type="file"] {
    display: none;
  }

  button {
    width: 150px;
    padding: 0;
    @media (min-width: 768px) {
      width: 300px;
    }
  }

  .pic-name-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 450px;

    button {
      color: #bdccc1;
      margin: 0;
      width: auto;
      background: transparent;
      text-decoration: none;
      font-size: 20px;
      line-height: 24px;
    }
  }
`;

export const QuizContent = styled.div`
  margin-top: 20px;
  max-width: 700px;
  margin: 0 auto 40px;

  .property-value-field {
    max-width: 350px;
  }

  .questions-text-area {
    margin-top: 15px;
    margin-bottom: 50px;
  }

  .choices {
    margin: 15px 0px;
  }

  .questions-text {
    font-family: "Playfair Display", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    max-width: 560px;
    margin-bottom: 20px;
    opacity: 0.44;
  }

  h3 {
    font-family: "Playfair Display", serif;
    font-weight: normal;
  }
  &.property-value {
    text-align: center;

    p {
      margin-bottom: 40px;
    }
  }
`;

export const PropertiesPage = styled.div`
  padding: 50px 0;
  @media (min-width: 1024px) {
    padding: 80px 0;
  }

  h1 {
    margin-bottom: 40px;
  }

  .pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
`;
export const MyPropertiesList = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 30px 0;
  &:last-child {
    border: none;
  }

  @media (min-width: 1024px) {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row-reverse;
    padding: 50px 0;
  }

  address {
    font-style: normal;
    font-size: 34px;
    line-height: 38px;
    margin: 0 0 20px;
    font-family: "Playfair Display", serif;

    span {
      display: block;
      font-size: 24px;
      line-height: 48px;
    }
  }

  p {
    margin: 0 0 30px;
  }

  .property-detail-block {
    font-size: 21px;
    line-height: 29px;
    @media (min-width: 1024px) {
      width: 58%;
      padding-right: 100px;
    }
  }

  .img-holder {
    margin-bottom: 30px;
    position: relative;
    @media (min-width: 1024px) {
      width: 42%;
      margin-bottom: 0;
    }

    &.empty {
      background: rgb(246 245 245);

      img {
        opacity: 0;
      }

      p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
      }
    }

    img {
      display: block;
      max-width: 100%;
      @media (min-width: 1024px) {
        margin: 0 auto;
      }
    }
  }

  .key-details {
    margin: 0 0 30px;
    padding: 0;
    list-style: none;
    font-size: 20px;
    line-height: 26px;
    color: #bdccc1;
    font-family: "Playfair Display", serif;
    overflow: hidden;

    li {
      display: inline-block;
      vertical-align: top;
      padding: 0 40px 0 0;

      + li {
        position: relative;
        &:before {
          position: absolute;
          content: "";
          top: 2px;
          left: -20px;
          height: 20px;
          width: 2px;
          background: #bdccc1;
        }
      }
    }
  }

  .btn-view {
    padding: 0;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    border: 0;
    background: transparent;
    cursor: pointer;

    i {
      margin: 3px 0 0 8px;
    }
  }
`;
