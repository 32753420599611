import styled from "styled-components";

export const ErrorPageBlock = styled.div`
  padding: 70px 0;
  font-size: 18px;
  line-height: 24px;

  .error-container {
    text-align: center;
    @media (min-width: 768px) {
      display: flex;
      flex-flow: row wrap;
      text-align: left;
      flex-direction: row-reverse;
    }
  }

  h1 {
    font-size: 32px;
    line-height: 36px;
    margin: 0 0 20px;
    @media (min-width: 768px) {
      font-size: 48px;
      line-height: 54px;
      margin: 0 0 40px;
    }
  }

  p {
    margin: 0 0 50px;
  }

  .text-block {
    @media (min-width: 768px) {
      width: 50%;
      padding-top: 70px;
      padding-right: 50px;
    }
  }

  .img-holder {
    margin-bottom: 30px;
    @media (min-width: 768px) {
      width: 50%;
      margin: 0;
    }

    img {
      max-width: 70%;
      @media (min-width: 768px) {
        max-width: 100%;
      }
    }
  }
  button {
    width: 320px;
    margin: 0 auto;
    @media (min-width: 768px) {
      margin: 0;
    }
  }
`;

export const GuidelinesBlock = styled.div`
  padding: 70px 0;

  .works-block {
    margin-bottom: 100px;
  }

  h1 {
    font-size: 34px;
    line-height: 40px;
    margin: 0 0 60px;
    text-transform: none;
    @media (min-width: 768px) {
      font-size: 48px;
      line-height: 54px;
    }
  }
`;

export const ImgTextBlock = styled.div`
  margin-bottom: 50px;
  font-size: 21px;
  line-height: 29px;
  @media (min-width: 768px) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }

  .text-block {
    position: relative;
    margin-bottom: 20px;
    @media (min-width: 768px) {
      width: 50%;
      padding-left: 80px;
      margin: 0;
    }

    @media (min-width: 1024px) {
      padding-right: 40px;
    }

    h2 {
      margin-left: 80px;
      margin-bottom: 50px;
      font-size: 28px;
      line-height: 32px;
      @media (min-width: 768px) {
        font-size: 32px;
        line-height: 36px;
        margin-left: 0;
        margin-bottom: 20px;
      }
    }
  }

  .num {
    position: absolute;
    left: 0;
    top: 0;
    color: #bdccc1;
    opacity: 0.3;
    font-size: 70px;
    line-height: 76px;
    font-family: "Playfair Display", serif;
    @media (min-width: 768px) {
      font-size: 90px;
      line-height: 96px;
    }
  }

  .img-holder {
    @media (min-width: 768px) {
      width: 45%;
    }

    img {
      max-width: 100%;
    }
  }

  a {
    text-decoration: none;
    color: #bdccc1;
    font-family: "Playfair Display", serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    i {
      display: inline-block;
      padding-right: 5px;
    }
  }
`;

export const FaqPage = styled.div`
  padding: 70px 0;
  font-size: 18px;
  line-height: 25px;

  h1 {
    font-size: 32px;
    line-height: 36px;
    margin: 0 0 40px;
    text-transform: none;
    @media (min-width: 768px) {
      font-size: 48px;
      line-height: 54px;
      margin: 0 0 70px;
    }
  }
`;

export const FaqHolder = styled.div`
  max-width: 850px;
  margin-bottom: 30px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.96),
      rgba(255, 255, 255, 0.96)
    ),
    #000000;

  &.active {
    border: 1px solid #bdccc1;

    .opener {
      color: #bdccc1;
      &:before {
        transform: rotate(-90deg);
      }
    }
    .slide {
      opacity: 1;
      height: auto;
      visibility: visible;
    }
  }

  .slide {
    opacity: 0;
    height: 0;
    visibility: hidden;
    transition: 0.1s ease-in;
  }
  .opener {
    display: block;
    font-weight: 500;
    font-family: "Playfair Display", serif;
    font-size: 18px;
    line-height: 22px;
    text-decoration: none;
    color: #000;
    padding: 13px 50px 13px 20px;
    position: relative;
    cursor: pointer;
    @media (min-width: 768px) {
      font-size: 24px;
      line-height: 32px;
      padding: 13px 70px 13px 40px;
    }

    &:before {
      position: absolute;
      top: 20px;
      right: 40px;
      font-family: "Playfair Display", serif;
      transform: rotate(90deg);
      font-size: 16px;
      line-height: 20px;
    }
  }

  .slide-holder {
    padding: 10px 30px 20px 20px;
    @media (min-width: 768px) {
      padding: 10px 50px 20px 40px;
    }
  }

  p {
    margin: 0;
  }
`;

export const PrivayPolicyBlock = styled.div`
  padding: 70px 0;
  font-size: 16px;
  line-height: 22px;

  h1 {
    font-size: 32px;
    line-height: 36px;
    @media (min-width: 768px) {
      font-size: 48px;
      line-height: 64px;
    }
  }

  h2 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  a {
    color: #bdccc1;
  }

  p {
    margin: 0 0 20px;
    &.uppercase {
      text-transform: uppercase;
    }
  }

  .title {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 21px;
    font-family: "Playfair Display", serif;
    font-weight: 500;
  }

  .text-holder {
    padding-left: 20px;
  }

  .effective-date {
    display: block;
    color: #bdccc1;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 40px;
  }

  p {
    margin-bottom: 20px;
  }

  .table-holder {
    overflow-x: auto;
    margin-bottom: 30px;
  }

  .table {
    border-collapse: collapse;
    width: 100%;
    min-width: 700px;
    margin-bottom: 10px;
    td,
    th {
      border: 1px solid #666666;
      padding: 10px 20px;
      &:last-child {
        text-align: center;
      }
    }
  }
`;
