export const SEND_USER_OTP_EMAL_API_URL = "/otp/generate/";
export const VERIFY_USER_OTP_API_URL = "/otp/verify/";
export const VERIFY_PHONE_OTP_API_URL = "/phone/otp/verify/";
export const SEND_USER_OTP_SMS_API_URL = "/phone/otp/generate"

export const GET_LOGGED_IN_USER_INFO_API_URL = "/users/me/";
export const RETRIEVE_USER_PROFILE_API_URL = (userId) => {
  return `/users/${userId}/profile/`;
};
export const UPDATE_USER_PROFILE_API_URL = `/users/me`;

export const ADD_REFEREE_API_URL = "/users/referral/add/";

export const LOGOUT_API_URL = "/logout/";
