import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { OverlayLoader } from "common/components/DottedLoader";

import GoogleAnalytics from "common/components/Analytics";

import {
  HOME_PAGE_ROUTE_URL,
  REGISTER_HOME_ROUTE_URL,
  REGISTER_USER_ROUTE_URL,
  RETURN_FROM_EMAIL_ROUTE_URL,
  PIN_ADDRESS_MAP_ROUTE_URL,
  PROPERTY_QUIZ_ROUTE_URL,
  HOMEOWNER_PROPERTY_ROUTE_URL,
  PROPOSAL_LIST_ROUTE_URL,
  CREATE_PROPOSAL_ROUTE_URL,
  SENT_PROPOSAL_DETAIL_ROUTE_URL,
  RECEIVED_PROPOSAL_DETAIL_ROUTE_URL,
  REFER_FRIEND_ROUTE_URL,
  VIEW_PROFILE_ROUTE_URL,
  EDIT_PROFILE_ROUTE_URL,
  NOT_FOUND_ROUTE_URL,
  HOW_IT_WORKS_ROUTE_URL,
  ERROR_ROUTE_URL,
  FAQ_ROUTE_URL,
  INVITE_HOME_OWNER_ROUTE_URL,
  USER_VERIFICATION_ROUTE_URL,
  PROPOSAL_SUCCESS_ROUTE_URL,
  PRIVACY_POLICY_ROUTE_URL,
  PRIVACY_POLICY_CALIFORNIA_ROUTE_URL,
  TERMS_OF_USE_ROUTE_URL,
  LOGIN_ROUTE_URL,
  SEARCH_ROUTE_URL,
  PIN_ADDRESS_SEARCH_RESULT_URL,
  MY_LIST_URL,
  CLAIMED_ERROR_ROUTE_URL,
  SHARE_CAMPAIGN_URL
} from "common/constants/urls";

import RouteConfig from "common/components/RouteConfig";

import Header from "common/components/Header";
import Footer from "common/components/Footer";

import { HomeOwnerHome, Search, Shared } from "home/route";
import {
  CreateAccount,
  ReturnFromEmail,
  ViewProfile,
  EditProfile,
  VerifyUser,
  ReferAFriend,
  LoginUser,
} from "user/route";
import {
  ProposalList,
  SentProposalDetails,
  ReceivedProposalDetails,
  InviteHomeOwner,
  CreateProposal,
  ProposalSuccess,
} from "proposal/route";
import {
  ProtectedRoute,
  NotFound,
  ErrorPage,
  ClaimedPage,
  HowItWorks,
  Faq,
  TermsOfUse,
  PrivacyPolicy,
  PrivacyPolicyCalifornia,
} from "common/route";

import { PropertyQuiz, HomeownerProperties } from "address/route";
import PinAddressFromSearch from "address/pages/PinAddressFromSearch";
import MyList from "address/pages/MyList";

const PinAddress = lazy(() => import("address/pages/PinAddress"));

function AppRouter() {
  return (
    <BrowserRouter>
      <GoogleAnalytics />
      <RouteConfig />
      <Header />
      <Suspense fallback={<OverlayLoader />}>
        <Routes>
          <Route exact path={HOME_PAGE_ROUTE_URL} element={<Search />} />
          <Route path={SHARE_CAMPAIGN_URL} element={<Shared/>} />
          <Route
            exact
            path={REGISTER_HOME_ROUTE_URL}
            element={<HomeOwnerHome />}
          />
          <Route path={PIN_ADDRESS_MAP_ROUTE_URL} element={<PinAddress />} />
          <Route path={PIN_ADDRESS_SEARCH_RESULT_URL} element={<PinAddressFromSearch />} />
          <Route
            path={PROPERTY_QUIZ_ROUTE_URL}
            element={<PropertyQuiz />}
          />
          <Route path={SEARCH_ROUTE_URL} element={<Search/>} />
          <Route
            path={HOMEOWNER_PROPERTY_ROUTE_URL}
            element={<HomeownerProperties />}
          />
          <Route path={REGISTER_USER_ROUTE_URL} element={<CreateAccount />} />
          <Route path={RETURN_FROM_EMAIL_ROUTE_URL} element={<ReturnFromEmail/>} />
          <Route
            path={PROPOSAL_LIST_ROUTE_URL}
            element={
              <ProtectedRoute>
                <ProposalList />
              </ProtectedRoute>
            }
          />
          <Route
            path={MY_LIST_URL}
            element={
              <ProtectedRoute>
                <MyList />
              </ProtectedRoute>
            }
          />
          <Route
            path={CREATE_PROPOSAL_ROUTE_URL}
            element={
              <ProtectedRoute>
                <CreateProposal />
              </ProtectedRoute>
            }
          />
          <Route
            path={SENT_PROPOSAL_DETAIL_ROUTE_URL}
            element={
              <ProtectedRoute>
                <SentProposalDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path={RECEIVED_PROPOSAL_DETAIL_ROUTE_URL}
            element={
              <ProtectedRoute>
                <ReceivedProposalDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path={VIEW_PROFILE_ROUTE_URL}
            element={
              <ProtectedRoute>
                <ViewProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path={EDIT_PROFILE_ROUTE_URL}
            element={
              <ProtectedRoute>
                <EditProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path={REFER_FRIEND_ROUTE_URL}
            element={
              <ProtectedRoute>
                <ReferAFriend />
              </ProtectedRoute>
            }
          />
          <Route
            path={PROPOSAL_SUCCESS_ROUTE_URL}
            element={
              <ProtectedRoute>
                <ProposalSuccess />
              </ProtectedRoute>
            }
          />
          <Route
            path={INVITE_HOME_OWNER_ROUTE_URL}
            element={<InviteHomeOwner />}
          />
          <Route path={USER_VERIFICATION_ROUTE_URL} element={<VerifyUser />} />
          <Route path={LOGIN_ROUTE_URL} element={<LoginUser />} />
          <Route path={HOW_IT_WORKS_ROUTE_URL} element={<HowItWorks />} />
          <Route path={PRIVACY_POLICY_ROUTE_URL} element={<PrivacyPolicy />} />
          <Route
            path={PRIVACY_POLICY_CALIFORNIA_ROUTE_URL}
            element={<PrivacyPolicyCalifornia />}
          />
          <Route path={TERMS_OF_USE_ROUTE_URL} element={<TermsOfUse />} />
          <Route path={FAQ_ROUTE_URL} element={<Faq />} />
          <Route path={ERROR_ROUTE_URL} element={<ErrorPage />} />
          <Route path={CLAIMED_ERROR_ROUTE_URL} element={<ClaimedPage />} />
          <Route path={NOT_FOUND_ROUTE_URL} element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
      <Footer />
    </BrowserRouter>
  );
}

export default AppRouter;
