import React from "react";

import { Main, Container, Header1 } from "common/globalStyles";
import { ErrorPageBlock } from "common/pages/styles";
import image5 from "assets/images/img-5.svg";

function ClaimedPage() {
  return (
    <Main>
      <ErrorPageBlock>
        <Container className="error-container">
          <div className="img-holder">
            <img src={image5} alt="img" />
          </div>
          <div className="text-block">
            <Header1>This house is hot, hot, hot!</Header1>
            <p>Someone already claimed this home :( <br/> If it's yours, you can always contact our customer support team at hello@unlistedinc.com.</p>
            <p>This error might have also occurred because you are trying to claim too many properties in a short amount of time. If that's the case, and those aren't your properties - shame, shame, shame!</p>
            <br/> <br/>
            <a href="https://unlistedhomes.com" style={{textDecoration: 'none', color: '#bdccc1'}}>Check out some other awesome houses</a>
          </div>
        </Container>
      </ErrorPageBlock>
    </Main>
  );
}

export default ClaimedPage;
