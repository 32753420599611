import { lazy } from "react";

import ViewProfile from "user/pages/ViewProfile";

const CreateAccount = lazy(() => import("user/pages/CreateAccount"));
const ReturnFromEmail = lazy(() => import("user/pages/ReturnFromEmail"))
const ReferAFriend = lazy(() => import("user/pages/ReferAFriend"));
const VerifyUser = lazy(() => import("user/pages/VerifyUser"));
const LoginUser = lazy(() => import("user/pages/LoginUser"));
const EditProfile = lazy(() => import("user/pages/EditProfile"));

export {
  CreateAccount,
  ReturnFromEmail,
  ViewProfile,
  EditProfile,
  VerifyUser,
  ReferAFriend,
  LoginUser,
};
