import React, { useState, useEffect, lazy, useContext } from "react";
import { toast } from "react-toastify";

import { Main, Container, Header1 } from "common/globalStyles";
import image1 from "assets/images/img-13.jpg";
import { OverlayLoader } from "common/components/DottedLoader";
import { AddressContext } from "address/context/AddressContext";

import { getHomeownerProperties } from "address/api/AddressAPI";
import { PropertiesPage, MyPropertiesList } from "./styles";
import { patchRequest, postRequest } from "common/utils/axiosClient";
import { REMOVE_OWNERSHIP_URL, SEND_PROPERTY_UPDATE_URL } from "address/constants/urls";
import { PrimaryButton, SecondaryButton } from "common/components/Buttons";
import Heart from "react-heart";

import './homeownerproperties.css'

const Pagination = lazy(() => import("common/components/Pagination"));

function HomeownerProperties() {
  const { clearAddressData } = useContext(AddressContext);

  const [homeownerProperties, setHomeownerProperties] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('')
  const [showUpdate, setShowUpdate] = useState(false)
  const [showUpdateScreen, setShowUpdateScreen] = useState(-1)
  const [propertyUpdateForm, setPropertyUpdateForm] = useState({})
  const [streetViewProperty, setStreetViewProperty] = useState({id: -1})

  const choices = {
    1: "This is my forever home",
    2: "I might be ready in a few years",
    3: "I'm not sure",
    4: "I'd love to sell my home soon"
  }

  const removeOwnership = async (id) => {
    const response = await patchRequest(REMOVE_OWNERSHIP_URL(id));
    if(response.status === 200) {
      toast.success("Ownership removed successfully.")
      fetchProperties(1)
    } else {
      toast.error("We couldn't remove that property. Are you sure you own it?")
    }
  }

  const sendUpdate = async (id) => {
    const response = await postRequest(SEND_PROPERTY_UPDATE_URL(id), {
      message: message
    });
    if(response.status === 200) {
      toast.success("Update sent successfully.")
      setShowUpdate(false)
      setMessage('')
    } else if(response.status === 201) {
      toast.error("You already sent an update today.")
    } else {
      toast.error("We couldn't send an update for that property. Are you sure you own it?")
    }
  }

  const fetchProperties = async (currentPage) => {
    setIsLoading(true);
    const response = await getHomeownerProperties({ page: currentPage, page_size: 5 });

    try {
      if (response && response.status === 200) {
        setHomeownerProperties([...response.data.properties]);
        setTotalPages(Math.ceil(response.data.count / 5));
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error();
      }
    } catch {
      toast.error("We couldn't load your properties.");
    }
  };
  const handlePageClick = (event) => {
    fetchProperties(event.selected + 1);
  };

  const updateProperty = async (propertyId) => {
    const response = await patchRequest(`/owner_info/${propertyId}`, propertyUpdateForm);
    if(response.status === 200) {
      toast.success("Updated your home successfully.")
      fetchProperties(1)
    } else {
      toast.error("We couldn't update that property. Are you sure you own it?")
    }    
    setShowUpdateScreen(-1)
    setPropertyUpdateForm({})
  }

  const createStreetView = async () => {
    if(streetViewProperty.id === -1) return;

    const loc = { lat: parseFloat(streetViewProperty.latitude), lng: parseFloat(streetViewProperty.longitude) }
    const google = window.google

    var point = new google.maps.LatLng(parseFloat(streetViewProperty.latitude), parseFloat(streetViewProperty.longitude))
    var streetViewService = new google.maps.StreetViewService()


    var panorama = new google.maps.StreetViewPanorama(document.getElementById("streetview"), { position: loc })  
  
    streetViewService.getPanoramaByLocation(point, 100, function (streetViewPanoramaData, status) {
      if(status === google.maps.StreetViewStatus.OK) {
        var oldPoint = point
        point = streetViewPanoramaData.location.latLng

        var heading = google.maps.geometry.spherical.computeHeading(point, oldPoint)
        panorama.setPosition(point)
        panorama.setPov({ heading: heading, zoom: 1, pitch: 0 })

      } else {
        toast.error("Whoops - no street view available for this home.")
      }
    })
  }

  const handlePropertyUpdateForm = (event) => {
    event.preventDefault()
    const name = event.target.name
    const value = event.target.value
    setPropertyUpdateForm(values => ({...values, [name]: value}))
  }

  useEffect(async () => {
    fetchProperties(1);
    clearAddressData();
    return () => setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(streetViewProperty) createStreetView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streetViewProperty])

  return (
    <Main>
      <PropertiesPage>
        <Container>
          <Header1>My Properties</Header1>
          {isLoading && <OverlayLoader />}
          {!isLoading && homeownerProperties.length === 0 && (
            <div>You haven't registered any property yet. Why not get started?</div>
          )}
          {!isLoading &&
            homeownerProperties.length > 0 &&
            homeownerProperties.map((property) => {
              return (
                <MyPropertiesList key={property.id}>
                  <div
                    className={
                      property && property.cover_photo_url
                        ? "img-holder"
                        : "img-holder empty"
                    }
                  >
                    <div id={streetViewProperty.id === property.id ? "streetview" : "notstreetview"} className="streetview" style={streetViewProperty.id === property.id ? {marginTop: '15px', marginBottom: '2px', maxWidth: '90%', height: '22em'} : {visibility: 'hidden', marginTop: '15px', marginBottom: '2px', maxWidth: '90%', height: '0em'}}></div>
                    {
                      
                     streetViewProperty.id === property.id ?

                      <>
                      </>

                      :

                      <>
                      {property && property.cover_photo_url ? (
                        <img src={property.cover_photo_url} alt="img" />
                      ) : (
                        <>
                          <img src={image1} alt="img" />
                          <p>Photo not provided by homeowner</p>
                        </>
                      )}
                      </>
                    }
                    {streetViewProperty.id !== property.id ? <button style={showUpdateScreen === property.id ? {visibility: 'hidden'} : {backgroundColor: '#bdccc1', color: 'black', border: '1px solid #bdccc1', borderRadius: '1px solid #bdccc1'}} onClick={() => setStreetViewProperty(property)}>Switch to Street View</button> : <button style={showUpdateScreen === property.id ? {visibility: 'hidden'} : {backgroundColor: '#bdccc1', border: '1px solid #bdccc1', borderRadius: '1px solid #bdccc1'}} onClick={() => setStreetViewProperty({id: -1})}>Switch to Homeowner Photo</button>}
                  </div>
                  {showUpdateScreen === property.id ? 
                  
                  <div className="property-detail-block">
                      <address>
                      {property.address_line_1}{" "}
                      <span>
                        {property.city.toUpperCase()},{" "}
                        {property.state.toUpperCase()}
                      </span>
                    </address>                      <label for="hbeds">Beds</label>  <br/>
                      <input id="hbeds" className="shortinput" placeholder={property.owner_given_bedrooms || property.bedrooms} type="number" name="beds" value={propertyUpdateForm.beds} onChange={handlePropertyUpdateForm}/>  <br/> 
                      <label for="hbaths">Baths</label> <br/>
                      <input id="hbaths" className="shortinput" placeholder={property.owner_given_bathrooms || property.bathrooms} type="number" name="baths" value={propertyUpdateForm.baths} onChange={handlePropertyUpdateForm}/> <br/>
                      <label for="hlsd">Last sale date (year)</label>  <br/>
                      <input id="hlsd" className="shortinput" placeholder={property.owner_given_last_sale_year || (property.last_sale_date ? property.last_sale_date.toString().substring(0, 4) : null)} type="number" name="last_sale_year" value={propertyUpdateForm.last_sale_year} onChange={handlePropertyUpdateForm}/> <br/>
                      <label for="hlsa">My selling price</label>  <br/>
                      <input id="hlsa" className="shortinput" placeholder={property.owner_expected_value || property.estimated_value_one} type="number" name="owner_expected_value" value={propertyUpdateForm.owner_expected_value} onChange={handlePropertyUpdateForm}/> <br/>
                      <label for="hba">Square footage</label>  <br/>
                      <input id="hba" className="shortinput" placeholder={property.owner_given_area || property.building_area} type="number" name="gross_area" value={propertyUpdateForm.gross_area} onChange={handlePropertyUpdateForm}/> <br/>
                      <label for="osc">Selling choice (current: {choices[property.owner_selling_choice] || 'none'})</label>  <br/> 
                      <select className="shortinput" name="owner_selling_choice" id="osc" value={propertyUpdateForm.owner_selling_choice} onChange={handlePropertyUpdateForm}>
                        <option value={property.owner_selling_choice}>Select an option</option>
                        <option value={1}>I'm in my forever home</option>
                        <option value={2}>I might be ready in a few years</option>
                        <option value={3}>I'm not sure</option>
                        <option value={4}>I'd love to sell my home soon</option>
                      </select> <br/>
                      <label for="tbox">Is there anything else you would <br/> like to share about your home?</label>  <br/>
                      <textarea style={{fontFamily: 'inherit'}} id="tbox" placeholder={property.owner_blurb} className="medinput" name="owner_blurb" value={propertyUpdateForm.owner_blurb} onChange={handlePropertyUpdateForm}/> <br/>
                      <PrimaryButton style={{color: 'black', borderRadius: '10px'}} onClick={() => updateProperty(property.id)}>Save Changes</PrimaryButton>
                      <SecondaryButton onClick={() => setShowUpdateScreen(-1)}>Cancel</SecondaryButton>        
                    </div>
                  
                  :
                  
                  
                  <div className="property-detail-block">
                    <address>
                      {property.address_line_1}{" "}
                      <span>
                        {property.city.toUpperCase()},{" "}
                        {property.state.toUpperCase()}
                      </span>
                    </address>
                      {(property.owner_given_last_sale_year || property.last_sale_date) && <p>Last sold in {property.last_sale_date ? property.last_sale_date.toString().substring(0, 4) : property.owner_given_last_sale_year}</p>}
                      {(property.owner_given_bedrooms || property.bedrooms) && <p>{property.bedrooms || property.owner_given_bedrooms} Bedrooms</p>}
                      {(property.owner_given_bathrooms || property.bathrooms) && <p>{property.bathrooms || property.owner_given_bathrooms} Bathrooms</p>}
                    <ul className="key-details">
                      {property.property_type && (
                        <li>{property.property_type}</li>
                      )}
                      {property.build_year > 1000 && property.build_year && <li>Built in {property.build_year}</li>}
                      {property.likes > 1 && <> <Heart style={{width: '1em', marginRight: '0.5em'}} isActive={true}/> <span>{property.likes} people like this property.</span></>}
                    </ul>
                    <button
                      type="button"
                      onClick={() => setShowUpdateScreen(property.id)}
                      className="btn-view"
                    >
                      Edit Property <i className="icon-right-arrow" />
                    </button>
                    <br/> <br/>
                    {!showUpdate && <PrimaryButton style={{borderRadius: '10px'}} onClick={() => setShowUpdate(true)}>Update Followers</PrimaryButton>}
                    {showUpdate && <SecondaryButton onClick={() => setShowUpdate(false)}>Cancel Update</SecondaryButton>}
                    {showUpdate && <div>
                        <textarea style={{padding: '5px', fontFamily: 'sans-serif', fontSize: '0.8em'}} placeholder="Write a message to your followers!" onChange={(event) => setMessage(event.target.value)}value={message} type="text" rows="10" cols="50"/>
                        <br/>
                        <PrimaryButton style={{borderRadius: '10px'}} onClick={() => sendUpdate(property.id)}>Send Update</PrimaryButton>
                      </div>} 
                    {!showUpdate && <SecondaryButton style ={{fontSize: '0.6em', marginTop: '-5.7em', marginLeft: '15em'}} onClick={() => removeOwnership(property.id)}>Remove</SecondaryButton>}
                  </div>}
                </MyPropertiesList>
              );
            })}
          {homeownerProperties.length > 0 && (
            <div className="pagination-wrapper">
              <Pagination
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePageClick}
                pageCount={totalPages}
                previousLabel="Previous"
              />
            </div>
          )}
        </Container>
      </PropertiesPage>
    </Main>
  );
}

export default HomeownerProperties;
