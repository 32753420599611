export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

export const UPDATE_MULTI_FORM_STEP = "UPDATE_MULTI_FORM_STEP";

export const CREATE_PROPOSAL_SUCCESS = "CREATE_PROPOSAL_SUCCESS";
export const CREATE_PROPOSAL_FAILURE = "CREATE_PROPOSAL_FAILURE";

export const UPDATE_PROPOSAL_SUCCESS = "UPDATE_PROPOSAL_SUCCESS";
export const UPDATE_PROPOSAL_FAILURE = "UPDATE_PROPOSAL_FAILURE";

export const SET_PROPOSAL_ACTION_PROCESSED = "SET_PROPOSAL_ACTION_PROCESSED";
export const SET_PROPOSAL_PROCESSED_SUCCESSFUL =
  "SET_PROPOSAL_PROCESSED_SUCCESSFUL";

export const SET_PROPOSAL_VOUCHER_APPLIED = "SET_PROPOSAL_VOUCHER_APPLIED";
