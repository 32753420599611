import React, { createContext, useEffect, useMemo, useReducer } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import { IntialImageColor } from "common/globalStyles";

import userReducer from "user/reducers/userReducer";
import {
  LOGIN_USER,
  LOGOUT_USER,
  SET_IS_HOMEOWNER,
  SET_LOGGED_IN_USER_INFO_SUCCESS,
  SET_LOGGED_IN_USER_INFO_FAILURE,
  SET_USER_PROFILE_PIC_SUCCESS,
} from "user/actions/userActions";

import {
  getLoggedInUserInfo,
  retrieveUserProfile,
  updateUserProfile,
  logOut,
} from "user/api/UserAPI";

import { addPropertyOwner } from "address/api/AddressAPI";

import { createImageFromInitials } from "common/utils/userPicUtils";

export const UserContext = createContext();

const checkTokenInLocalStorage = () => {
  return !!localStorage.getItem("token");
};

function UserContextProvider({ children }) {
  const initialState = {
    loggedInUserInfo: [],
    loggedInUserInfoLoaded: false,
    loggedIn: checkTokenInLocalStorage(),
    isHomeOwner: false,
  };
  const [state, dispatch] = useReducer(userReducer, initialState);

  const loginUser = (user) => {
    localStorage.setItem("token", JSON.stringify(user.userAuthToken));
    dispatch({ type: LOGIN_USER });
  };

  const logoutUser = async () => {
    try {
      const response = await logOut();
      if (response.status === 200) {
        toast.success("User logged out successfully");
      }
      localStorage.removeItem("token");
      dispatch({ type: LOGOUT_USER });
    } catch {
      localStorage.removeItem("token");
      dispatch({ type: LOGOUT_USER });
    }
  };

  const setIsHomeOwner = () => {
    dispatch({ type: SET_IS_HOMEOWNER });
  };

  const setLoggedInUserInfo = async () => {
    try {
      const loggedInUserInfoResp = await getLoggedInUserInfo();
      if (loggedInUserInfoResp.status === 200) {
        const { id } = loggedInUserInfoResp.data;
        const retrievedUserProfileResp = await retrieveUserProfile(id);
        if (
          retrievedUserProfileResp &&
          retrievedUserProfileResp.status === 200
        ) {
          dispatch({
            type: SET_LOGGED_IN_USER_INFO_SUCCESS,
            payload: {
              display_name: loggedInUserInfoResp.data.display_name,
              ...retrievedUserProfileResp.data,
            },
          });
        } else if (
          retrievedUserProfileResp.isAxiosError &&
          retrievedUserProfileResp.message
        ) {
          dispatch({
            type: SET_LOGGED_IN_USER_INFO_FAILURE,
          });
          toast.error(retrievedUserProfileResp.message);
        } else {
          throw new Error();
        }
      } else if (loggedInUserInfoResp.response.status === 401) {
        localStorage.removeItem("token");
        dispatch({ type: LOGOUT_USER });
      } else if (
        loggedInUserInfoResp.isAxiosError &&
        loggedInUserInfoResp.message
      ) {
        dispatch({
          type: SET_LOGGED_IN_USER_INFO_FAILURE,
        });
      } else {
        throw new Error();
      }
    } catch {
      toast.error("We couldn't log in. Try again?");
    }
  };

  const updateLoggedInUserInfo = async (user, callerId = "") => {
    try {
      const updatedUserInfoResp = await updateUserProfile(user);
      if (updatedUserInfoResp && updatedUserInfoResp.status === 200) {
        const loggedInUserInfoResp = await getLoggedInUserInfo();
        if (loggedInUserInfoResp.status === 200) {
          dispatch({
            type: SET_LOGGED_IN_USER_INFO_SUCCESS,
            payload: {
              display_name: loggedInUserInfoResp.data.display_name,
              ...updatedUserInfoResp.data,
            },
          });
          if (callerId === "edit-profile") {
            toast.success("User profile name updated successfully!");
          }
        } else if (
          loggedInUserInfoResp.isAxiosError &&
          loggedInUserInfoResp.message
        ) {
          dispatch({
            type: SET_LOGGED_IN_USER_INFO_FAILURE,
          });
          toast.error(loggedInUserInfoResp.message);
        } else {
          throw new Error();
        }
      } else if (
        updatedUserInfoResp.isAxiosError &&
        updatedUserInfoResp.message
      ) {
        dispatch({
          type: SET_LOGGED_IN_USER_INFO_FAILURE,
        });
        toast.error(updatedUserInfoResp.message);
      } else {
        throw new Error();
      }
    } catch {
      toast.error("We couldn't update your profile.");
    }
  };

  const setUserPhoto = async (photo_url) => {
    dispatch({
      type: SET_USER_PROFILE_PIC_SUCCESS,
      payload: photo_url,
    });
  }

  const setPropertyOwnership = async (propertyId) => {
    try {
      const response = await addPropertyOwner(propertyId);
      if (response.status === 201) {
        toast.success(response.data);
        setIsHomeOwner();
        return true;
      }
      if (response.status === 200) {
        toast.success(response.data);
        setIsHomeOwner();
        return true;
      }
      if (response.status === 400 && response.data.property) {
        toast.error(response.data.property);
        return false;
      }
      return false;
    } catch {
      toast.error("We couldn't update ownership for that property.");
      return false;
    }
  };

  const createImageFromNameInitials = () => {
    return createImageFromInitials(
      500,
      state.loggedInUserInfo.first_name + " " + state.loggedInUserInfo.last_name,
      IntialImageColor
    );
  };

  useEffect(() => {
    if (state.loggedIn) {
      setLoggedInUserInfo();
    }
  }, [state.loggedIn]);

  const userValue = useMemo(() => ({
    userValue: state,
    loginUser,
    logoutUser,
    updateLoggedInUserInfo,
    setUserPhoto,
    createImageFromNameInitials,
    setPropertyOwnership,
  }));

  return (
    <UserContext.Provider value={userValue}>{children}</UserContext.Provider>
  );
}

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserContextProvider;
