import React, { createContext, useMemo, useReducer } from "react";
import PropTypes from "prop-types";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { toast } from "react-toastify";

import addressReducer from "address/reducers/addressReducer";
import {
  SET_ADDRESS_DATA_SUCCESS,
  SET_ADDRESS_DATA_FAILURE,
  SET_PROPERTY_DATA_SUCCESS,
  SET_PROPERTY_DATA_FAILURE,
  REQUIRE_UNIT,
  NOT_REQUIRE_UNIT,
  SET_HAS_ERROR,
} from "address/actions/addressActions";

import { searchAddress, getPropertyById } from "address/api/AddressAPI";

export const AddressContext = createContext();

function AddressContextProvider({ children }) {
  const initialState = {
    addressData: {},
    addressDataLoaded: false,
    propertyData: {},
    propertyDataLoaded: false,
    isUnitRequired: false,
    hasError: false,
  };

  const [state, dispatch] = useReducer(addressReducer, initialState);

  const addressCompObj = {};
  // Utility Function to parse address components provided by Google Places API
  const parseGoogleAddressComponents = (addressComponents) => {
    addressComponents.forEach((component) => {
      if (
        component.types.includes("locality") ||
        component.types.includes("administrative_area_level_2")
      ) {
        addressCompObj.city = component.long_name;
      } else if (component.types.includes("street_number")) {
        addressCompObj.street = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        addressCompObj.state = component.short_name;
      } else if (component.types.includes("route")) {
        addressCompObj.route = component.short_name;
      } else {
        addressCompObj[[component.types[0]]] = component.long_name;
      }
    });
  };

  const requireUnit = () => {
    dispatch({ type: REQUIRE_UNIT });
  };

  const notRequireUnit = () => {
    dispatch({ type: NOT_REQUIRE_UNIT });
  };

  const setHasError = (status) => {
    dispatch({ type: SET_HAS_ERROR, payload: status });
  };

  const clearAddressData = () => {
    dispatch({ type: SET_PROPERTY_DATA_FAILURE });
    dispatch({ type: SET_ADDRESS_DATA_FAILURE });
    dispatch({ type: NOT_REQUIRE_UNIT });
    setHasError(false);
  };

  const setPropertyData = async (address) => {
    try {
      const response = await searchAddress(address);
      if (response.status === 200) {
        dispatch({ type: SET_PROPERTY_DATA_SUCCESS, payload: response.data });
        notRequireUnit();
      } else if (response.status === 400) {
        if (response.data.unit_value) {
          dispatch({ type: SET_PROPERTY_DATA_FAILURE, payload: response.data });
          requireUnit();
        } else {
          setHasError(true);
          toast.error(
            "This doesn't look like a valid address. Try again?"
          );
        }
      } else {
        throw new Error();
      }
    } catch {
      dispatch({ type: SET_PROPERTY_DATA_FAILURE });
      setHasError(true);
      toast.error(
        "We couldn't get the property data. Make sure you click on an address!"
      );
    }
  };

  const populateExistingPropertyData = (data) => {
    dispatch({ type: SET_PROPERTY_DATA_SUCCESS, payload: data });
  }

  const setPropertyDataByAddressId = async (addressId) => {
    try {
      const response = await getPropertyById(addressId);
      if (response.status === 200) {
        dispatch({ type: SET_PROPERTY_DATA_SUCCESS, payload: response.data });
        dispatch({
          type: SET_ADDRESS_DATA_SUCCESS,
          payload: {
            addressLat: parseFloat(response.data.latitude),
            addressLng: parseFloat(response.data.longitude),
            addressName: response.data.address_line_1,
          },
        });
        notRequireUnit();
      } else {
        throw new Error();
      }
    } catch {
      clearAddressData();
      toast.error(
        "Sorry, we couldn't set the property data. Try again?"
      );
    }
  };

  const setAddressData = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        parseGoogleAddressComponents(results[0].address_components);
        return getLatLng(results[0]);
      })
      .then(async (latLng) => {
        dispatch({
          type: SET_ADDRESS_DATA_SUCCESS,
          payload: {
            ...addressCompObj,
            addressLat: latLng.lat,
            addressLng: latLng.lng,
            addressName: address,
          },
        });
        setPropertyData({
          ...addressCompObj,
          addressLat: latLng.lat,
          addressLng: latLng.lng,
          addressName: address,
        });
      })
      .catch(() => {
        dispatch({ type: SET_ADDRESS_DATA_FAILURE });
        setHasError(true);
        toast.error("We couldn't get the address data. Try again?");
      });
  };

  const addressValue = useMemo(() => ({
    addressValue: state,
    setAddressData,
    setPropertyData,
    setPropertyDataByAddressId,
    populateExistingPropertyData,
    requireUnit,
    notRequireUnit,
    clearAddressData,
    setHasError,
  }));

  return (
    <AddressContext.Provider value={addressValue}>
      {children}
    </AddressContext.Provider>
  );
}

AddressContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AddressContextProvider;
