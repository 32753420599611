/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Header1 } from "common/globalStyles";

import { Container, BackButton } from "common/globalStyles";
import PropertyPicUpload from "address/pages/PropertyQuiz/steps/PropertyPicUpload";

import { HOMEOWNER_PROPERTY_ROUTE_URL } from "common/constants/urls";
import {
  ChatBlock,
  ChatContainer,
} from "proposal/components/ProposalChat/styles";
import { postRequest } from "common/utils/axiosClient";
import DottedLoader from "common/components/DottedLoader";
import { RadioHolder } from "payment/components/PaymentForm/styles";
import "./propertyquiz.css"

function PropertyQuiz() {
  const urlParams = useParams();
  const [picUrl, setPicUrl] = useState(null)
  const { addressId } = urlParams;
  const [showPropertyUploadPopup, setShowPropertyUploadPopup] = useState(false)
  const [messages, setMessages] = useState([])
  const chatScrollView = useRef(null);
  const scrollRef = useRef();
  const [step, setStep] = useState(0)
  const [selected, setSelected] = useState([[0, 0, 0 ,0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0]])
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const postToQuiz = async (currentOption, optionIndexOne = 0, optionIndexTwo = 0) => {

    for(var i = 0; i < 4; i++) { // prevent duplicate answers
      if(selected[optionIndexOne][i] === 1 && currentOption !== 5) return;
    }

    setSelected(prevSelected => {
      const newSelected = [...prevSelected]
      newSelected[optionIndexOne][optionIndexTwo] = 1
      return newSelected
    })

    if(step >= 3 && currentOption === undefined) return

    if(step === 3 && currentOption === 1) {
      navigate(HOMEOWNER_PROPERTY_ROUTE_URL)
      return
    }

    if(step === 3 && currentOption === 2) {
      return
    }

    if(step === 2 && currentOption === 1) {
      setShowPropertyUploadPopup(true) // we will then POST after they actually click upload
      setTimeout(() => {
        chatScrollView.current?.scrollIntoView({ behavior: "smooth", block: 'nearest' });
      }, 200)
      return
    }

    if(currentOption === 5) {
      currentOption = 1
      setShowPropertyUploadPopup(false)
    }

      try {
        const response = await postRequest('/interactive/new_property_quiz', { step: step+1, property_id: addressId, option_selected: currentOption })
        if (response && response.status === 200) {
          chatScrollView.current?.scrollIntoView({behavior: "smooth", block: 'nearest'});
          setStep(step => step + 1)
          setMessages((current) => current.concat([...response.data.contents.messages]))
        
        } else {
          throw new Error();
        }
      } catch (error) {
        toast.error("We couldn't send the message. Try again?");
      }
  };

  useEffect(() => {
    chatScrollView.current?.scrollIntoView({ behavior: "smooth", block: 'nearest' });
}, [messages]);

useEffect(() => {
  postToQuiz()
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

  return (
    <Container>
      <br/>
    <BackButton
            onClick={() =>
                {navigate('/register-home')}
            }
            className="btn-back"
            style={{ width: "auto" }}
          >
            Go Back
      </BackButton>      
      <Header1 style={{}}>Register your home with Unlisted</Header1>

     <ChatBlock>
    <ChatContainer id="chat-list" style={{height: '700px'}}>
      <div className="chat-messages">
      {messages.map((message, full_index) => (
        // eslint-disable-next-line react/no-array-index-key
        

        <div ref={scrollRef} key={full_index}>

        {message.leading ? 
          <div className={'message received'}>
            <div className="content">
              {message.text}
            </div>
          </div>

          :

          <div className={'message sent'}>
            <div className="content registration">
               <>
                {message.questions.map(
                  
                (question, index) => 
                <>
                {/* <button className="chatselectorbutton" style={selected[full_index][question.code-1] === 1 ? {backgroundColor: '#ece8e1'} : {}} key={index} onClick={() => {
                  setLoading(true)
                    postToQuiz(question.code, full_index, question.code-1)
                    setLoading(false)
                }}>{question.text}
                </button> */}
                {question.single ? 
                
                <button style={{fontFamily: 'Playfair Display', cursor: 'pointer', color: 'black', backgroundColor: 'white', border: 'none', width: '100%', fontSize: '100%', marginBottom: '-200px', marginTop: '10px'}} onClick={() => {
                  setLoading(true)
                  postToQuiz(question.code, full_index, question.code-1)
                  setLoading(false)
                }}>
                  {question.text}
                  </button>

                :

                <RadioHolder className="propertyregistrationradio">
                  <label className="radio-label">
                    <input disabled={selected[full_index].reduce((a,b) => a+b) > 0} type="radio" onClick={() => {
                  setLoading(true)
                    postToQuiz(question.code, full_index, question.code-1)
                    setLoading(false)
                }}/>
                    <span className="radio-checkmark"/>
                    <div className="radiotext">
                      {question.text}
                    </div>
                  </label>
                </RadioHolder>}
                <br/> <br/>
                </>
                )}
                </> 
            </div>
          </div>
          }
        </div>
      ))}
          {showPropertyUploadPopup && step === 2 && 
            <>
            <div className={'message sent'}>
            <div className="content registration">
              <PropertyPicUpload
                picUrl={picUrl}
                setPicUrl={setPicUrl}
                propertyId={parseInt(addressId)}
              />
              <button className="chatselectorbutton" style={{cursor: 'pointer', backgroundColor: '#bdccc1', border: '1px solid #bdccc1', borderRadius: '6px'}} onClick={() => {
                setLoading(true)
                  postToQuiz(5)
                  setLoading(false)
                }}>Continue</button>
              </div>
            </div>
            </>
            }
      </div>
      <div ref={chatScrollView} />
    </ChatContainer>
  </ChatBlock> 
    {loading && <DottedLoader/>}
    </Container>
  );
}

export default PropertyQuiz;
