import styled from "styled-components";

export const ChatBlock = styled.div`
  margin-top: 50px;

  h1 {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 30px;
  }

  .time {
    display: block;
    text-align: center;
    margin-bottom: 20px;
    padding-bottom: 6px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.44);
    font-size: 12px;
    line-height: 16px;
    color: #54595d;
  }
`;

export const ChatInput = styled.div`
  padding: 20px;
  textarea {
    border: 2px solid #bdccc1;
    border-radius: 2px;
    padding: 10px;
    width: 100%;
    resize: none;
    outline: none;
    font-family: "Playfair Display", serif;
    margin-bottom: 30px;
  }

  button {
    width: 172px;
  }
`;

export const TextAreaHolder = styled.div`
  position: relative;
  margin: 0 0 25px;

  textarea {
    margin: 0;
  }

  span {
    position: absolute;
    right: 0;
    bottom: -15px;
    font-size: 12px;
    line-height: 16px;
    color: black;
  }
`;

export const ChatContainer = styled.div`
margin: 0;
padding: 20px;
list-style: none;

height: 500px;
overflow: scroll;
position: relative;
bottom: 0;
inline-size: 100%;
overflow-wrap: break-word;

.chat-messages {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0.2rem;
    &-thumb {
      background-color: #ffffff39;
      width: 0.3rem;
      border-radius: 1rem;
    }
  }

  .message {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    display: flex;
    align-items: center;
    .content {
      max-width: 60%;
      overflow-wrap: break-word;
      padding: 1rem;
      font-size: 1.1rem;
      border-radius: 1rem;
      color: black;
      @media (max-width: 768px) {
        max-width: 90%;
      }
    }
  }
  .sent {
    justify-content: flex-end;
    .content {
      background-color: #bdccc1;
    }
  }
  .received {
    justify-content: flex-start;
    .content {
      background-color: #dbd8d3;
    }
  }
  .metadata {
    font-size: 0.8rem;
  }
  .name {
    margin-top: 1.5rem;
    display: block;

    .chat-time {
      display: inline-block;
      margin-left: 15px;
      font-weight: normal;
      font-size: 12px;
      color: #72777d;
    }
  }
}
`;