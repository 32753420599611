import {
  getRequest,
  postRequest,
  patchRequest,
} from "common/utils/axiosClient";
import {
  SEND_USER_OTP_EMAL_API_URL,
  VERIFY_USER_OTP_API_URL,
  SEND_USER_OTP_SMS_API_URL,
  VERIFY_PHONE_OTP_API_URL,
  GET_LOGGED_IN_USER_INFO_API_URL,
  UPDATE_USER_PROFILE_API_URL,
  ADD_REFEREE_API_URL,
  LOGOUT_API_URL,
} from "user/constants/urls";

export async function sendUserOTPEmail(user, params = {}) {
  try {
    const response = await postRequest(
      SEND_USER_OTP_EMAL_API_URL,
      {
        email: user.email,
      },
      {
        params,
      }
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function verifyUserOTP(user) {
  try {
    const response = await postRequest(VERIFY_USER_OTP_API_URL, {
      email: user.email,
      token: user.token,
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function sendUserOTPSms(phone) {
  try {
    const response = await postRequest(SEND_USER_OTP_SMS_API_URL, {
      phone_number: phone,
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function verifyPhoneOTP(user) {
  try {
    const response = await postRequest(VERIFY_PHONE_OTP_API_URL, {
      phone_code: user.token,
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function getLoggedInUserInfo() {
  try {
    const response = await getRequest(GET_LOGGED_IN_USER_INFO_API_URL);
    return response;
  } catch (error) {
    return error;
  }
}

export async function retrieveUserProfile(userId) {
  try {
    //const response = await getRequest(RETRIEVE_USER_PROFILE_API_URL(userId)); //FIX: can probably remove this.
    const response = await getRequest(GET_LOGGED_IN_USER_INFO_API_URL)
    return response;
  } catch (error) {
    return error;
  }
}

export async function updateUserProfile(user) {
  try {
    const response = await patchRequest(
      UPDATE_USER_PROFILE_API_URL,
      user
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function addUserReferral(referees) {
  try {
    const response = await postRequest(ADD_REFEREE_API_URL, referees);
    return response;
  } catch (error) {
    return error;
  }
}

export async function logOut() {
  try {
    const response = await postRequest(LOGOUT_API_URL);
    return response;
  } catch (error) {
    return error;
  }
}
