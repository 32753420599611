import React from "react";
import PropTypes from "prop-types";

import { Modal, ModalMain } from "common/components/Modal/style";

function ModalWrapper({ handleClose, show, children }) {
  const showHideClassName = show ? "display-block" : "display-none";

  return (
    <Modal className={showHideClassName}>
      <ModalMain>
        <button type="button" className="btn-close" onClick={handleClose}>
          X
        </button>
        {children}
      </ModalMain>
    </Modal>
  );
}

ModalWrapper.propTypes = {
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default ModalWrapper;
