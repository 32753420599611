import styled from "styled-components";

export const DefaultButton = styled.button`
  display: block;
  border-radius: 0;
  padding: 14px;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 25px;
  transition: 0.2s linear background;
  border: none;
  font-family: "Playfair Display", serif;
  font-weight: 500;
`;

export const PrimaryButton = styled(DefaultButton)`
  background: #bdccc1;
  font-family: "Playfair Display";
  color: black;
  &[disabled] {
    cursor: default;
    background: #b8b8b8;
  }
`;

export const SecondaryButton = styled(DefaultButton)`
  font-weight: bold;
  background: #fff;
  border: 2px solid #000;
  padding: 12px;
  color: #000;
`;

export const TertiaryButton = styled(DefaultButton)`
  color: #000;
  background: transparent;
  border: 2px solid;
  &[disabled] {
    cursor: default;
    color: #c5c5c5;
  }
`;
