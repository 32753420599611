import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import "./PinAddress.css";
import axios from "axios";
import { PrimaryButton, SecondaryButton } from "common/components/Buttons";
import { SubText, SetupBlock } from "common/globalStyles";

import ModalWrapper from "common/components/Modal";
import RegistrationForm from "user/components/RegistrationForm";

import {
  Main,
  Container,
  Header1,
  Header3,
  BackButton,
} from "common/globalStyles";
import {
  SearchResults,
  TwoCols,
  MapBlock,
  MapHolder,
  HomeDetails,
  ErrorMessageHolder,
  ConfirmOwnershipModal
} from "address/pages/styles";

import PropertyDetails from "address/components/PropertyDetails";
import ErrorMessage from "common/components/ErrorMessage";
import DottedLoader from "common/components/DottedLoader";

import { UserContext } from "user/context/UserContext";
import { AddressContext } from "address/context/AddressContext";
import { getPropertyById } from "address/api/AddressAPI";

import { ProposalHolder, ProposalText } from "proposal/components/ProposalItem/style";

import {
  REGISTER_USER_ROUTE_URL,
  SEARCH_ROUTE_URL,
  MY_LIST_URL
} from "common/constants/urls";

import { GET_PROPERTY_RECOMMENDATIONS_URL, GET_PROPERTY_LIKED_API_URL, GET_PROPERTY_LIKES_API_URL, UNLIKE_PROPERTY_API_URL, LIKE_PROPERTY_API_URL, GET_PROPERTY_NOTIFICATION_URL, NOTIFY_FOR_PROPERTY_URL, UNNOTIFY_FOR_PROPERTY_URL } from "address/constants/urls";

import { getRequest, postRequest } from "common/utils/axiosClient";
import { toast } from "react-toastify";
import Heart from "react-heart";

function PinAddressFromSearch() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const urlParams = useParams();
  const { propertyIdFromParams } = urlParams;

  const { propertyIdFromState, fromMyList } = state || {};
  const [propertyId, setPropertyId] = useState(null)

  const [zillowLink, setZillowLink] = useState(null)
  const [realtorLink, setRealtorLink] = useState(null)
  const [propertyData, setPropertyData] = useState({})
  const [propertyDataLoaded, setPropertyDataLoaded] = useState(false)
  const [recommendedProperties, setRecommendedProperties] = useState(false)
  const [error, setError] = useState(false)
  const [likes, setLikes] = useState(0)
  const [liked, setLiked] = useState(false)
  const [notified, setNotified] = useState(false)
  const [showStreetView, setShowStreetView] = useState(true)

  const { userValue } = useContext(UserContext);
  const { populateExistingPropertyData } = useContext(AddressContext)
  const userInfo = userValue.loggedInUserInfo;

  const [showRegistration, setShowRegistration] = useState(false);
  const [confirmPropertyModal, setConfirmPropertyModal] = useState(false);
  const [propertyOwnershipError, setPropertyOwnershipError] = useState(false);
  const [loadingQuiz, setLoadingQuiz] = useState(false)
  const [streetViewError, setStreetViewError] = useState(false)

  const [backupLat, setBackupLat] = useState(null)
  const [backupLng, setBackupLng] = useState(null)
  const [isForSale, setIsForSale] = useState(false)

  const getPropertyData = async (propertyId) => {
    const response = await getPropertyById(propertyId);
    if(response.status === 200) {
        setPropertyData(response.data)
        populateExistingPropertyData(response.data)
        getRecommendedProperties(propertyId)
    } else {
        setError(true)
    }
    setPropertyDataLoaded(true)
  }

  const getPropertyLikeInformation = async (propertyId) => {
    const response = await getRequest(GET_PROPERTY_LIKED_API_URL(propertyId))
    if(response.status === 200) {
      setLiked(response.data.liked)
    }

    const likedResponse = await getRequest(GET_PROPERTY_LIKES_API_URL(propertyId))
    if(likedResponse.status === 200) {
      setLikes(likedResponse.data.count)
    }
  }

  const likeProperty = async (propertyId) => {
    if(!userInfo || !userInfo.id) {
      toast.error("You need to be logged in to like a property. Why not log in or create an account?")
      return
    }
    const response = await postRequest(LIKE_PROPERTY_API_URL(propertyId))
    if(response.status === 200) {
      setLiked(true)
    } else {
      toast.error("We couldn't like that property.")
    }
  }

  const unlikeProperty = async (propertyId) => {
    if(!userInfo || !userInfo.id) {
      toast.error("You need to be logged in to unlike a property. Why not log in or create an account?")
      return
    }
    const response = await postRequest(UNLIKE_PROPERTY_API_URL(propertyId))
    if(response.status === 200) {
      setLiked(false)
    } else {
      toast.error("We couldn't remove that like.")
    }
  }
  
  const getPropertyNotificationInformation = async (propertyId) => {
    const response = await getRequest(GET_PROPERTY_NOTIFICATION_URL(propertyId))
    if(response.status === 200) {
      setNotified(response.data.notified)
    }
  }

  const notifyForProperty = async(propertyId) => {
    if(!userInfo || !userInfo.id) {
      toast.error("You need to be logged in to follow a property. Why not log in or create an account?")
      return
    }
    const response = await postRequest(NOTIFY_FOR_PROPERTY_URL(propertyId))
    if(response.status === 200) {
      setNotified(true)
    } else {
      toast.error("We couldn't follow this property.")
    }
  }

  const unnotifyForProperty = async (propertyId) => {
    if(!userInfo || !userInfo.id) {
      toast.error("You need to be logged in to unfollow a property. Why not log in or create an account?")
      return
    }
    const response = await postRequest(UNNOTIFY_FOR_PROPERTY_URL(propertyId))
    if(response.status === 200) {
      setNotified(false)
    } else {
      toast.error("We couldn't unfollow this property.")
    }
  }

  const getZillowLink = async () => {
    const addressLineOne = propertyData.address_line_1.split(" ")
    const addressZip = propertyData.zip
    var zillowBuiltLink = ''
    if(propertyData.unit_value !== '' && propertyData.unit_value !== null) {
      zillowBuiltLink = `https://zillowstatic.com/autocomplete/v3/suggestions?q=${addressLineOne[0]}%20${addressLineOne[1]}%20${addressLineOne[2]}%20UNIT%20${propertyData.unit_value}%20${addressZip}`
    } else {
     zillowBuiltLink = `https://zillowstatic.com/autocomplete/v3/suggestions?q=${addressLineOne[0]}%20${addressLineOne[1]}%20${addressLineOne[2]}%20${addressZip}`
    }
    const response = await axios.get(zillowBuiltLink)
    if(response.status === 200 && response.data.results[0]) {
      let zillowId = response.data.results[0].metaData.zpid
      setBackupLat(response.data.results[0].metaData.lat)
      setBackupLng(response.data.results[0].metaData.lng)
      setIsForSale(response.data.results[0].metaData.addressType === 'forsale_address')
      setZillowLink(`https://zillow.com/homes/${zillowId}_zpid`)
    }
  }

  const getRealtorLink = async () => {
    const addressLineOne = propertyData.address_line_1.split(" ")
    var realtorBuiltLink = ''
    if(propertyData.unit_value !== '' && propertyData.unit_value !== null) {
      realtorBuiltLink = `https://parser-external.geo.moveaws.com/suggest?input=${addressLineOne[0]}%20${addressLineOne[1]}%20${addressLineOne[2]}%20%UNIT%20${propertyData.unit_value}%20${propertyData.zip}&client_id=rdc-home&limit=1`
    } else {
      realtorBuiltLink = `https://parser-external.geo.moveaws.com/suggest?input=${addressLineOne[0]}%20${addressLineOne[1]}%20${addressLineOne[2]}%20${propertyData.zip}&client_id=rdc-home&limit=1`
    }
    const response = await axios.get(realtorBuiltLink)
    if(response.status === 200 && response.data.autocomplete[0]) {
      let realtorId = response.data.autocomplete[0].mpr_id
      setRealtorLink(`https://realtor.com/realestateandhomes-detail/M${realtorId}`)
    }
  }

  const google = window.google;
  const geocodeAddress = async () => {

      const fullAddress = `${propertyData.address_line_1} ${propertyData.city} ${propertyData.state} ${propertyData.zip}`;
      const attom_location = { lat: parseFloat(propertyData.latitude), lng: parseFloat(propertyData.longitude) };
      const geocoder = new google.maps.Geocoder();
      const directionsService = new google.maps.DirectionsService();

      //use geocoder to get propertyLatLng, which will hopefully be the center of the structure
      geocoder.geocode({
          'address': fullAddress,
      }, async function(results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
              let propertyLatLng = results[0].geometry.location;

              // find a Streetview location on the road to be used a our myLatLng (panorama POV)
              //we need to use this directionsService so we don't end up with a view from an alley or side street
              var request = {
                  origin: fullAddress,
                  destination: fullAddress,
                  travelMode: google.maps.DirectionsTravelMode.DRIVING
              };
              directionsService.route(request, async function (response, status) {
                  if (status === google.maps.DirectionsStatus.OK) {
                      var myLatLng = response.routes[0].legs[0].start_location;
                      await generatePanorama(myLatLng, propertyLatLng, google)
                  } else {
                      console.error("Directions service not successful for the following reason:" + status);
                      await generatePanorama(null, propertyLatLng, google)
                  }
              });
          } else {
              console.error("Geocode was not successful for the following reason:", status);
              if (backupLat && backupLng) {
                  const backup_location = { lat: backupLat, lng: backupLng };
                  await generatePanorama(backup_location, backup_location);
              } else {
                  await generatePanorama(attom_location, attom_location);
              }
          }
      });
  };

  const generatePanorama = async (myLatLng, propertyLatLng) => {
      const streetViewService = new google.maps.StreetViewService();
      const panorama = new google.maps.StreetViewPanorama(document.getElementById("streetview"));

      streetViewService.getPanorama({
          location: myLatLng || propertyLatLng,
          radius: 50,
          source: google.maps.StreetViewSource.OUTDOOR
      }, async function(data, status) {
          if (status === google.maps.StreetViewStatus.OK) {
              panorama.setPano(data.location.pano);
              var heading = google.maps.geometry.spherical.computeHeading(myLatLng ||  data.location.latLng, propertyLatLng);
              panorama.setPov({
                  heading: heading,
                  pitch: 0,
                  zoom: 1
              });
              panorama.setVisible(true);
          } else {
              console.error("Street View was not successful for the following reason:", status);
              // This is where we should fall back to aerial view
              displayAerialView(propertyLatLng);
          }
      });
  };

  const displayAerialView = (location) => {
      const mapOptions = {
          center: location,
          zoom: 20,
          mapTypeId: google.maps.MapTypeId.SATELLITE
      };
      const map = new google.maps.Map(document.getElementById("streetview"), mapOptions);
  };


  const getRecommendedProperties = async (propertyId) => {
    try {
      const response = await getRequest(GET_PROPERTY_RECOMMENDATIONS_URL(propertyId))
      if(response.status === 200 && response.data.length > 0) {
        setRecommendedProperties(response.data)
      } 
    } catch {
    }
  }

  const navigateToMap = (id) => {
    getPropertyData(id)
    if(userInfo && userInfo.id) {
      getPropertyLikeInformation(id)
      getPropertyNotificationInformation(id)
    }
  };

  const handleContinueBtnClick = () => {
    navigate(REGISTER_USER_ROUTE_URL, {state: {isFromSearch: true, propertyId: propertyData.id, propertyAddress: propertyData.address_line_1}});
  };

  const [width, setWidth] = useState(window.innerWidth)
  window.addEventListener('resize', function(event){
    setWidth(window.innerWidth)
  })

  useEffect(() => {

    if(propertyIdFromParams) {
      setPropertyId(propertyIdFromParams)
      getPropertyData(propertyIdFromParams)
    } else {
      setPropertyId(propertyIdFromState)
      getPropertyData(propertyIdFromState)
    }

    if(userInfo && userInfo.id) {
      getPropertyLikeInformation(propertyId)
      getPropertyNotificationInformation(propertyId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(propertyData.address_line_1) {
    getZillowLink()
    getRealtorLink()
    setStreetViewError(false)
    geocodeAddress()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyData])

  return !propertyDataLoaded ? (
    <DottedLoader/>
  ) : (
    <Main>
      <SearchResults>
        <Container>
          <BackButton
            onClick={() =>
                {fromMyList ? navigate(MY_LIST_URL) : navigate(SEARCH_ROUTE_URL)}
            }
            className="btn-back"
            style={{ width: "auto" }}
          >
            Go Back
          </BackButton>
          {error && <Header1 style={{fontSize: '4em', lineHeight: '5rem', letterSpacing: '0.2rem'}}>Oops. There was an error. Are you sure you have the right link?</Header1>}
          <TwoCols>
            <MapBlock>
              <MapHolder>
            {!showStreetView && <img style={{marginTop: '15px', maxWidth: '90%', maxHeight: '22em'}} src={propertyData.cover_photo_url} alt="the front of the house you searched for"/>}
              
              {!!!streetViewError && <div id="streetview" className="streetview" style={showStreetView ? {marginTop: '15px', marginBottom: '2px', maxWidth: '90%', height: '22em'} : {visibility: 'hidden', marginTop: '15px', marginBottom: '2px', maxWidth: '90%', height: '0em'}}></div>}
              
              {streetViewError && <div style={{backgroundColor: '#F5F5F5', marginTop: '15px', marginBottom: '2px', maxWidth: '90%', height: '22em'}}>
                <h1 style={{display: 'inline-block', textAlign: 'center', padding: '10px', marginTop: '10%'}}>Google Street View isn't available for this home.</h1>
                {/* <h2 style={{textAlign: 'center', padding: '10px'}}>You can view a satellite image by clicking <i>Switch to Satellite View</i> beneath the map to the left.</h2> */}
                </div>}


            </MapHolder>
              <div style={{marginBottom: '-2.5em', marginLeft: '0em'}}>
              {likes > 7 && <p>{likes} likes</p>}
              {!liked? <Heart style={{width: '2rem', marginTop: '-2em'}} isActive={false} onClick={() => likeProperty(propertyData.id)}/> : <Heart style={{width: '2rem', marginTop: '-2em'}} isActive={true} onClick={() => unlikeProperty(propertyData.id)}/>}
              </div>
              {notified ? <SecondaryButton style={{marginLeft: '4em', width: '22em'}} onClick={() => unnotifyForProperty(propertyData.id)}>Unfollow this Property</SecondaryButton> : <SecondaryButton style={{marginLeft: '4em', width: '20em'}} onClick={() => notifyForProperty(propertyData.id)}>Follow this Property</SecondaryButton>}
            </MapBlock>
            <HomeDetails style={width > 800 ? {marginTop: '-2.5em'} : {marginTop: '-1em'}}>
              {propertyDataLoaded && (
                <>
                  <Header1 style={{color: 'black', fontSize: '1.8em', marginBottom: '0em'}}>{propertyData.address_line_1}, {propertyData.city}, {propertyData.state}, {propertyData.zip}</Header1>
                  {isForSale ? <p style={{display: 'inline-block', color: 'green'}}>&nbsp; For Sale &nbsp; </p> : <></>}
                  {zillowLink && <SubText style={{display: 'inline-block', marginRight: '1em'}}><a key={propertyData.id} href={zillowLink} target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: 'black'}}>View on Zillow</a></SubText>}
                  {realtorLink && <SubText style={{display: 'inline-block'}}><a key={propertyData.id} href={realtorLink} target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: 'black'}}>View on Realtor.com</a></SubText>}
                  {propertyData &&
                  (propertyData.property_type ||
                    propertyData.bathrooms ||
                    propertyData.bedrooms ||
                    propertyData.build_year ||
                    propertyData.last_sale_date ||
                    propertyData.last_sale_amount) ? (
                    <PropertyDetails
                      addressLine={propertyData.address_line_1}
                      propertyType={propertyData.property_type}
                      baths={propertyData.bathrooms}
                      bedrooms={propertyData.bedrooms}
                      buildYear={propertyData.build_year}
                      lastSaleDate={propertyData.last_sale_date}
                      lastSaleAmount={propertyData.last_sale_amount}
                      estimatedValue={propertyData.estimated_value}
                      schoolDistrict={propertyData.school_district}
                      latitude={propertyData.latitude}
                      longitude={propertyData.longitude}
                      state={propertyData.state}
                    />
                  ) : (
                    <ErrorMessageHolder>
                      <ErrorMessage>We don't have public record data for this property - but don't fret, you can still send a care package to this address. If you own this property, click the box below to update its profile.</ErrorMessage>
                    </ErrorMessageHolder>
                  )}
                {
                  propertyDataLoaded && propertyData && propertyData.owner_corrected_information && (
                    <div>
                  <p>The property owner has some updated information for you: </p>
                  <ul>
                  {propertyData.owner_given_bedrooms && <li>{propertyData.owner_given_bedrooms} bedrooms</li>}
                  {propertyData.owner_given_bathrooms && <li>{propertyData.owner_given_bathrooms} bathrooms</li>}
                  {propertyData.owner_given_last_sale_date && <li>Last sold in {propertyData.owner_given_last_sale_date}</li>}
                  {propertyData.owner_given_move_timeline_min && propertyData.owner_given_move_timeline_max && <p>Looking to move in {propertyData.owner_given_move_timeline_min} to {propertyData.owner_given_move_timeline_max} years</p>}
                    </ul>
                    </div>
                  )
                }
                </>
              )}
              <button
                    className="pinaddressbutton"
                    onClick={() => handleContinueBtnClick()}
                  >
                    Contact Homeowner
              </button>            
              {userInfo && userInfo.id === propertyData.owner_id ? <Header3>You own this property.</Header3> : 
              
              propertyData.owner_id === null ? <Header3 onClick={() => setConfirmPropertyModal(true)} style={{textDecoration: 'none', cursor: 'pointer'}}><i>Is this your property? Click here.</i></Header3>
                : <p>Someone's already registered this property. If it's yours, you can reach out to us.</p>
              }
              
              <p style={{marginTop: '-1em', fontStyle: 'italic'}}>Property information provided is readily available via public records.</p>
            </HomeDetails>
          </TwoCols>
          {loadingQuiz && <>
            <Header3>We're registering your home...</Header3>
            <DottedLoader/>
          </>}
          {recommendedProperties && <Header3>We found some similar properties for you.</Header3>}
          <>
                {recommendedProperties && recommendedProperties.map((property => 
                  (
                  <ProposalHolder>
                  <ProposalText style={{width: '100%'}}>
                  <span className="address" onClick={() => navigateToMap(property[0])}>{property[1]} </span>
                  <span>{property[2]}, {property[3]}, {property[4]}</span>
                  <strong className="proposal-price">
                  {(property[5] && property[6]) ? <span>{property[5]} bedrooms, {property[6]} bathrooms</span> : (property[5] ? <p>{property[5]} bedrooms</p> : property[6] ? <p>{property[6]} bathrooms</p> : <p></p>)}
                  </strong>
                  </ProposalText>
                         <button
                    className="pinaddressbutton"
                    onClick={() => navigateToMap(property[0])}>
                      See Property Profile
                  </button>
                  </ProposalHolder>
                  )))
                }
          </>
          {confirmPropertyModal && (
            <ModalWrapper
              show={confirmPropertyModal}
              handleClose={() => {
                setConfirmPropertyModal(false)
              }}
            >
              <ConfirmOwnershipModal>
                Confirm you are the owner of this property?
                <PrimaryButton
                style = {{color: 'black'}}
                  className="confirm-btn"
                  onClick={() => {
                    setLoadingQuiz(true)
                    setConfirmPropertyModal(false)
                    setShowRegistration(true)
                  }}
                >
                  Confirm
                </PrimaryButton>
              </ConfirmOwnershipModal>
            </ModalWrapper>
          )}
          {showRegistration && (
            <SetupBlock>
              <RegistrationForm
                redirectId={propertyData.id}
                propertyId={propertyData.id}
                redirectTo="property-quiz"
                errorHandler={() => {
                  setPropertyOwnershipError(true)
                }}
              />
            </SetupBlock>
          )}
          {propertyOwnershipError && <p>Hmm. We couldn't register that property at the moment.</p>}
        </Container>
      </SearchResults>
    </Main>
  );
}

export default PinAddressFromSearch;
