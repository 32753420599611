import React, { useEffect, useContext, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";

import {
  Main,
  Container,
  MainBlock,
  MainContent,
  Header2,
  SearchBlock,
} from "common/globalStyles";
import { Header1 } from "common/globalStyles";
import DottedLoader from "common/components/DottedLoader";

import { GlobalContext } from "common/context/GlobalContext";
import { AddressContext } from "address/context/AddressContext";

import { PIN_ADDRESS_MAP_ROUTE_URL } from "common/constants/urls";

const AddressSearchField = lazy(() =>
  import("address/components/AddressSearchField")
);

function HomeOwnerHome() {
  const navigate = useNavigate();

  const { state } = useContext(GlobalContext);

  const { clearAddressData } = useContext(AddressContext);

  const navigateToMap = () => {
    navigate(PIN_ADDRESS_MAP_ROUTE_URL, {
      state: { isLandedFromOwnerSearchPage: true },
    });
  };

  useEffect(() => {
    clearAddressData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Main>
      <MainBlock>
        <Container>
          <MainContent>
            <Header1>
              Register My Home
            </Header1>
            <p>
              Welcome to the UNLISTED Property Profile where you can register
              your property for free. Show off its best assets and attract the
              attention you deserve now or in the future. Received an invitation to chat? <a style={{color: 'black'}} href="https://unlistedhomes.com/invitations">Click here.</a>
            </p>
            <SearchBlock>
              <Header2>Let’s start with the basics.</Header2>
              <p>What’s the address of the home you own?</p>
              <Suspense fallback={<DottedLoader />}>
                <AddressSearchField navigateNext={navigateToMap} />
              </Suspense>
              {state.isLoading && <DottedLoader />}
            </SearchBlock>
          </MainContent>
        </Container>
      </MainBlock>
    </Main>
  );
}

export default HomeOwnerHome;
